const BLIP_IDENTIFIER = '@blip.ai';

export const decodeAttendantBlipEmail = (email: string) => {
    const TAKE_IDENTIFIER = '@take.net';

    return (decodeURIComponent(email).replace(BLIP_IDENTIFIER, '').replace(TAKE_IDENTIFIER, ''));
};

export const encodeAttendantBlipEmail = (email: string) => {
    return (encodeURIComponent(email) + BLIP_IDENTIFIER);
};

export const decodeAfterToDeleteAttendantBlipEmail = (email: string) => {
    return decodeAttendantBlipEmail(decodeURIComponent(email));
};

export const encodeToDeleteAttendantBlipEmail = (email: string) => {
    return encodeAttendantBlipEmail(encodeURIComponent(email));
};

export const emailWithEmailSeparator = (email: string) => {
    return email.replaceAll('@', '').replaceAll('.', '');
};