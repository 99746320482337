import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CONTRACT_ERROR, CONTRACT_STEPPER } from 'constants/MetaActivation';
import { cnpjMask, isCnpjValid, isEmailValid, isUrlValid } from 'services/validator';
import { IContractInfoField, IContractInfoState } from './interface';

const defaultFormValue = { value: '', isValid: true, isEmpty: true, errorMessage: CONTRACT_ERROR.emptyField };
const InitialState: IContractInfoState = {
  cnpj: { ...defaultFormValue, errorMessage: CONTRACT_ERROR.invalidCnpj },
  companyName: { ...defaultFormValue, errorMessage: CONTRACT_ERROR.emptyField },
  billingEmail: { ...defaultFormValue, errorMessage: CONTRACT_ERROR.invalidEmail },
  agentEmail: { ...defaultFormValue, errorMessage: CONTRACT_ERROR.invalidEmail },
  agentName: { ...defaultFormValue, errorMessage: CONTRACT_ERROR.emptyField },
  agentPosition: { ...defaultFormValue, errorMessage: CONTRACT_ERROR.emptyField },
  companyWebsite: { ...defaultFormValue, errorMessage: CONTRACT_ERROR.invalidWebsite },
  policyAgreement: false,
  step: CONTRACT_STEPPER.companyData,
  signedContract: false,
};

const contractInfoSlice = createSlice({
  name: 'contract info',
  initialState: InitialState,
  reducers: {
    updateStateCnpj(state, { payload }: PayloadAction<IContractInfoField>) {
      const isValid = isCnpjValid(payload.value);
      payload.value = cnpjMask(payload.value);
      const errorMessage = payload.isEmpty ? CONTRACT_ERROR.emptyField : CONTRACT_ERROR.invalidCnpj;
      state.cnpj = { ...state.cnpj, ...payload, isValid, errorMessage, isEmpty: false };
    },
    updateStatePolicyAgreement(state, { payload }: PayloadAction<boolean>) {
      state.policyAgreement = payload;
    },
    updateStateCompanyName(state, { payload }: PayloadAction<IContractInfoField>) {
      state.companyName = { ...state.companyName, ...payload, isEmpty: false };
    },
    updateStateBillingEmail(state, { payload }: PayloadAction<IContractInfoField>) {
      const isValid = isEmailValid(payload.value);
      const errorMessage = payload.isEmpty ? CONTRACT_ERROR.emptyField : CONTRACT_ERROR.invalidEmail;
      state.billingEmail = { ...state.billingEmail, ...payload, isValid, errorMessage, isEmpty: false };
    },
    updateStateAgentEmail(state, { payload }: PayloadAction<IContractInfoField>) {
      const isValid = isEmailValid(payload.value);
      const errorMessage = payload.isEmpty ? CONTRACT_ERROR.emptyField : CONTRACT_ERROR.invalidEmail;
      state.agentEmail = { ...state.agentEmail, ...payload, isValid, errorMessage, isEmpty: false };
    },
    updateStateAgentName(state, { payload }: PayloadAction<IContractInfoField>) {
      state.agentName = { ...state.agentName, ...payload, isEmpty: false };
    },
    updateStateAgentPosition(state, { payload }: PayloadAction<IContractInfoField>) {
      const isValidAndNotEmpty = payload.value !== 'Cargo atual' || '' || undefined ? true : false;
      state.agentPosition = {
        ...state.agentPosition,
        ...payload,
        isValid: isValidAndNotEmpty,
        isEmpty: !isValidAndNotEmpty,
      };
    },
    updateStateCompanyWebsite(state, { payload }: PayloadAction<IContractInfoField>) {
      const isValid = isUrlValid(payload.value);
      const errorMessage = payload.isEmpty ? CONTRACT_ERROR.emptyField : CONTRACT_ERROR.invalidWebsite;
      state.companyWebsite = { ...state.companyWebsite, ...payload, isValid, errorMessage, isEmpty: false };
    },
    updateStateStep(state, { payload }: PayloadAction<number>) {
      state.step = payload;
    },
    updateSignedContract(state, { payload }: PayloadAction<boolean>) {
      state.signedContract = payload;
    },
  },
});

export default contractInfoSlice.reducer;

export const {
  updateStateCnpj,
  updateStateCompanyName,
  updateStateCompanyWebsite,
  updateStateBillingEmail,
  updateStateAgentName,
  updateStateAgentEmail,
  updateStateAgentPosition,
  updateStatePolicyAgreement,
  updateStateStep,
  updateSignedContract,
} = contractInfoSlice.actions;

export const useContractInfo = (state: { contractInfo: IContractInfoState }) => state.contractInfo;
