import { BdsGrid, BdsIcon, BdsPaper, BdsTooltip, BdsTypo } from 'blip-ds/dist/blip-ds-react';

interface IMetricCard {
  value?: number | string;
  title: string;
  tooltipText: string;
}
export const MetricCard = ({ value, title, tooltipText }: IMetricCard) => {
  return (
    <BdsPaper width="9.275rem" height="8.25rem">
      <BdsGrid direction="column" gap="2" justifyContent="center" alignItems="center" padding="2" height="100%">
        <BdsTypo variant="fs-20" bold="bold" margin={false}>
          {value}
        </BdsTypo>
        <BdsGrid gap="1" justifyContent="center" alignItems="center" style={{ textAlign: 'center' }}>
          <BdsTypo variant="fs-12">{title}</BdsTypo>
          <BdsTooltip className="tooltip" position="right-top" tooltip-text={tooltipText}>
            <BdsIcon name="info" size="xxx-small" />
          </BdsTooltip>
        </BdsGrid>
      </BdsGrid>
    </BdsPaper>
  );
};
