import { PlanTypeEnum } from 'objects/types/BlipGoApi';

const getPlanName = (planType: PlanTypeEnum | undefined) => {
  if (planType === PlanTypeEnum.Basic) {
    return 'Basic';
  } else if (planType === PlanTypeEnum.Pro) {
    return 'Pro';
  } else {
    return 'Free';
  }
};

export { getPlanName };
