import { LARGE_MOBILE_SCREEN } from 'constants/MediaQueryResolutions';
import styled from 'styled-components';

export const AgentInfoContainer = styled.form`
  display: grid;
  gap: 16px;
  width:400px;

  @media screen and (max-width: ${LARGE_MOBILE_SCREEN}) {
    width: 100%;
  }
`;

export const NavigationBtnContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
`;
