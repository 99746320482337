import React from 'react';
import { BdsGrid } from 'blip-ds/dist/blip-ds-react';
import { formatLastMessageDate, formatTimestamp, getMessageContent } from 'utils/ChatHistoryFormater';
import ChatBubble from '../ChatBubble';
import { MessageItemDto } from 'objects/types/MessagesResponse';

interface MessageItemProps {
  message: MessageItemDto;
  showDateSeparator: boolean;
}

const MessageItem: React.FC<MessageItemProps> = ({ message, showDateSeparator }) => {
  return (
    <>
      {showDateSeparator && <div className="date-separator">{formatLastMessageDate(message.date)}</div>}
      <BdsGrid className={`message-wrapper ${message.direction}`}>
        <ChatBubble
          contentHTML={getMessageContent(message)}
          sender={message.direction === 'sent' ? 'sent' : 'received'}
          type={message.type || 'text/plain'}
        />
        {message.type !== 'application/vnd.iris.ticket+json' && (
          <div className="timestamp">{formatTimestamp(message.date)}</div>
        )}
      </BdsGrid>
    </>
  );
};

export default MessageItem;
