import { BdsButtonIcon } from 'blip-ds/dist/blip-ds-react';
import styled, { css } from 'styled-components';

interface MainContainerProps {
  $lastInput: boolean;
}

export const MainContainer = styled.div<MainContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0 20px 0px;
  border-bottom: 1px solid #dce8ef;
  width: 100%;
  ${props =>
    props.$lastInput &&
    css`
      border: none;
      width: 100%;
    `}

  flex-wrap: wrap;
`;

export const TurnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Icon = styled(BdsButtonIcon)`
  margin-left: 10px;
`;
