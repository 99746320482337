import ContactStatusHubspot from 'constants/ContactStatusHubspot';
import ContactPropertyHubspot from 'objects/types/ContactPropertyHubspot';
import { IUpdateContactPropertiesHubspot } from 'redux/slices/HubspotSlice/interface';

export const mountUpdateContactHubspotPayload = (contactStatus: string, token: string) => {
    const updateContactProperty = {
        property: ContactStatusHubspot.Status,
        value: contactStatus
    } as ContactPropertyHubspot;

    return {
        token: token,
        properties: [updateContactProperty]
    } as IUpdateContactPropertiesHubspot;
};