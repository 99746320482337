import { BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import InputText from 'components/InputText';
import { ValidateHour } from 'utils/Hour';
import { MainContainer } from './styles';

type SelectProps = {
  disabled: boolean;
  setAttendance: (obj: string) => void;
  text: string;
  end: number;
  value: string;
  dataTestId?: string;
};

export const SelectHour = ({ disabled, setAttendance, text, end, value, dataTestId }: SelectProps) => {
  const handleChange = (ev: Event) => {
    const input = ev.target as HTMLBdsInputElement;
    const inputValue = input.value?.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})/);
    if (inputValue !== null && inputValue !== undefined) {
      input.value = !inputValue[2] ? inputValue[1] : `${inputValue[1]}:${inputValue[2]}`;
    }
    setAttendance(input.value as string);
    ev.stopImmediatePropagation();
  };

  return (
    <MainContainer end={end}>
      <BdsTypo className="typo" variant="fs-14" data-testid="typoDayWeek">
        {text}
      </BdsTypo>

      <InputText
        value={value}
        onChange={handleChange}
        disabled={disabled}
        required={true}
        dataTestId={dataTestId}
        danger={value !== '' && !ValidateHour(value)}
      />
    </MainContainer>
  );
};
