import { Analytics, GraphApi, Logger } from 'infra/adapters';
import {
  FacebookPage,
  FacebookPagesResult,
  InstagramActivationErrors,
  InstagramBusinessAccountDetails,
  User,
} from 'objects/types/Facebook';
import { Waba } from 'objects/types/WhatsappActivation';
import { Facebook as FB } from 'services/FacebookSDK';
import { getWabaIdsAndAppName, getWabaPhoneNumbersById } from 'services/GraphApiService';

const useGraphApi = () => {
  const className = 'useGraphApi';

  const initFacebookSdk = async (facebookAppId?: string) => {
    const appId = facebookAppId || process.env.REACT_APP_FACEBOOK_APP_ID;
    await FB.init({
      appId: appId,
      cookie: true,
      xfbml: true,
      version: 'v16.0',
    });
  };

  const launchWhatsAppSignup = async (callback: (arg0: string) => any) => {
    const methodName = 'launchWhatsAppSignup';
    FB.login(
      function (response: any) {
        if (response.authResponse) {
          Analytics.Track(Analytics.events.META_EMBEDDED_FINISHED);
          Logger.Info('user successfully authenticated on Meta', { methodName, className });
          const accessToken = response.authResponse.accessToken;
          callback && callback(accessToken);
        }
      },
      {
        auth_type: 'reauthorize',
        scope: 'business_management,whatsapp_business_management',
        extras: {
          feature: 'whatsapp_embedded_signup',
          setup: {},
        },
      },
    );
  };

  const getWabas = async (inputToken: string): Promise<{ wabas: Waba[]; applicationName: string }> => {
    const wabas: Waba[] = [];
    const methodName = 'getWabas';
    try {
      const response = await getWabaIdsAndAppName(inputToken);
      for (const wabaId of response.wabaIds) {
        const wabaPhoneNumbers = await getWabaPhoneNumbersById(wabaId);
        wabas.push({
          id: wabaId,
          phoneNumbers: wabaPhoneNumbers,
        });
      }
      Logger.Info(`successfully obtaining wabas: ${wabas}`, { methodName, className });
      return Promise.resolve({ wabas: wabas, applicationName: response.applicationName });
    } catch (error) {
      Logger.Error(`error getting wabas: ${error}`, { methodName, className });
      return Promise.reject(new Error(error as string));
    }
  };

  const launchInstagramSignup = async (callback: (arg0: string) => any) => {
    await FB.login(
      function (response: any) {
        if (response.authResponse) {
          const accessToken = response.authResponse.accessToken;
          callback && callback(accessToken);
        } else {
          Analytics.Track(Analytics.events.INSTAGRAM_ACTIVATION_POP_UP_CLOSED_BY_USER);
        }
      },
      {
        auth_type: 'reauthorize',
        scope:
          'read_insights, catalog_management, pages_show_list, ads_management, ads_read, business_management, pages_messaging, instagram_basic, instagram_manage_messages, pages_read_engagement, pages_manage_metadata, public_profile',
      },
    );
  };

  const getUser = async (token: string): Promise<User> => {
    try {
      const api = new GraphApi('getUser');
      const url = `/me?access_token=${token}`;
      const user = await api.Get<User>(url);
      return user.data;
    } catch (error) {
      const err = error as Error;
      return Promise.reject(err.message);
    }
  };

  const logoutFacebook = async (userId: string, token: string) => {
    const api = new GraphApi('logoutFacebook');
    const url = `/${userId}/permissions?access_token=${token}`;
    await api.Delete(url);
    await FB.logout();
  };

  const getFacebookPages = async (userID: string, token: string): Promise<FacebookPage[]> => {
    try {
      const accountParam = 'id,name,access_token,connected_instagram_account{username},instagram_business_account';
      const url = `/${userID}/accounts?access_token=${token}&fields=${accountParam}`;
      const api = new GraphApi('getFacebookPages');
      const pages = await api.Get<FacebookPagesResult>(url);
      return pages.data.data;
    } catch (error) {
      const err = error as Error;
      return Promise.reject(err.message);
    }
  };

  const validateInstagramPageAndReturnDetails = async (userId: string, pageId: string, pageAccessToken: string) => {
    const pageList = await getFacebookPages(userId, pageAccessToken);
    const selectedPage = pageList.find(page => page.id === pageId);
    if (!selectedPage) {
      return Promise.reject(InstagramActivationErrors.InvalidInstagramAccount);
    }

    if (!selectedPage.connected_instagram_account || !selectedPage.instagram_business_account) {
      return Promise.reject(InstagramActivationErrors.NoBindedPagesFound);
    }

    const instagramDetails = {
      instagramBusinessAccountId: selectedPage.instagram_business_account.id,
      userName: selectedPage.connected_instagram_account.username,
      pageAccessToken: selectedPage.access_token,
      pageId: selectedPage.id,
    } as InstagramBusinessAccountDetails;

    return instagramDetails;
  };

  return {
    initFacebookSdk,
    launchWhatsAppSignup,
    getWabas,
    launchInstagramSignup,
    getUser,
    getFacebookPages,
    logoutFacebook,
    validateInstagramPageAndReturnDetails,
  };
};

export default useGraphApi;
