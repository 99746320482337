import { BdsChipTag, BdsGrid, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { ANALYTICS } from 'constants/Analytics';
import {
  APPCUES_ATTENDANCE_QUEUES_CONFIGURATIONS_ROUTE,
  APPCUES_ATTENDANTS_CONFIGURATIONS_ROUTE,
  APPCUES_MENU_AUTOMATIC_RESPONSES_CONFIGURATIONS_ROUTE,
} from 'constants/AppCuesRoutes';
import ChannelsName from 'constants/ChannelsName';
import { Modules } from 'constants/Modules';
import SubPages from 'constants/SubPages';
import { Analytics } from 'infra/adapters';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import YouTube from 'react-youtube';
import { useChannelsModule } from 'redux/slices/ChannelsModuleSlice/channelsModuleSlice';
import IChannelsModuleState from 'redux/slices/ChannelsModuleSlice/interface';
import { addActiveModule, addActiveSubPage, updateIsSubMenuOpen } from 'redux/slices/ProjectSlice/projectSlice';
import { RootState } from 'redux/store';
import { Link, TipsContainer } from './styles';

const CustomizeChatbot: React.FC = () => {
  const INDEX_PAGE_AUTOMATIC_RESPONSES = 1;
  const INDEX_PAGE_ATTENDANTS_CONFIGURATIONS = 2;
  const INDEX_PAGE_TEAM_ATTENDANTS_CONFIGURATIONS = 3;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { channels } = useSelector<RootState, IChannelsModuleState>(useChannelsModule);

  const updateActiveModuleAndSubPage = (subPageIndex: number) => {
    const subPage = SubPages.configurations[subPageIndex];
    dispatch(updateIsSubMenuOpen(true));
    dispatch(addActiveModule(Modules.configurations));
    dispatch(addActiveSubPage(subPage));
  };

  const openBlipChatTab = () => {
    const blipChatChannel = channels.find(channel => channel.title === ChannelsName.Blipchat);
    window.open(blipChatChannel?.linkToTest, '_blank');
  };

  const trackPlaybackStateChange = (state: number) => {
    const playerStates: { [key: string]: string } = {
      '-1': 'unstarted',
      '0': 'ended',
      '1': 'playing',
      '2': 'paused',
      '3': 'buffering',
      '5': 'videoCued',
    };
    Analytics.Track(ANALYTICS.YOUTUBE_VIDEO_STATE_CHANGE, { interaction: playerStates[state.toString()] });
  };

  return (
    <BdsGrid id="channel-activated" direction="column" padding="3" gap="2">
      <BdsChipTag icon="checkball" color="success">
        Seu canal está ativado!
      </BdsChipTag>
      <BdsTypo variant="fs-32" bold="extra-bold" lineHeight="simple">
        Agora é só customizar seu Chatbot
      </BdsTypo>
      <BdsGrid gap="2">
        <TipsContainer>
          <BdsGrid direction="column" gap="3" padding="3">
            <BdsTypo variant="fs-24" tag="h3" bold="bold" margin={false}>
              Atalhos para customizar seu Chatbot.
            </BdsTypo>
            <BdsTypo>Clique nos itens abaixo para ver como você pode customizar seu chatbot:</BdsTypo>
            <div>
              <BdsTypo variant="fs-20" bold="semi-bold">
                1.&nbsp;
                <Link
                  onClick={() => {
                    Analytics.Track(Analytics.events.STEPS_CHEKC_LIST, {
                      stepName: 'Convide atendentes',
                    });
                    updateActiveModuleAndSubPage(INDEX_PAGE_ATTENDANTS_CONFIGURATIONS);
                    navigate(APPCUES_ATTENDANTS_CONFIGURATIONS_ROUTE);
                  }}
                  data-testid="attendants-link"
                >
                  Convide atendentes
                </Link>
              </BdsTypo>

              <BdsTypo variant="fs-20" bold="semi-bold">
                2.&nbsp;
                <Link
                  onClick={() => {
                    Analytics.Track(Analytics.events.STEPS_CHEKC_LIST, {
                      stepName: 'Crie equipes de atendimento',
                    });
                    updateActiveModuleAndSubPage(INDEX_PAGE_TEAM_ATTENDANTS_CONFIGURATIONS);
                    navigate(APPCUES_ATTENDANCE_QUEUES_CONFIGURATIONS_ROUTE);
                  }}
                  data-testid="queues-link"
                >
                  Crie equipes de atendimento
                </Link>
              </BdsTypo>

              <BdsTypo variant="fs-20" bold="semi-bold">
                3.&nbsp;
                <Link
                  onClick={() => {
                    Analytics.Track(Analytics.events.STEPS_CHEKC_LIST, {
                      stepName: 'Personalize as respostas automáticas',
                    });
                    updateActiveModuleAndSubPage(INDEX_PAGE_AUTOMATIC_RESPONSES);
                    navigate(APPCUES_MENU_AUTOMATIC_RESPONSES_CONFIGURATIONS_ROUTE);
                  }}
                  data-testid="faq-link"
                >
                  Personalize as respostas automáticas
                </Link>
              </BdsTypo>

              <BdsTypo variant="fs-20" bold="semi-bold">
                4.&nbsp;
                <Link
                  onClick={() => {
                    Analytics.Track(Analytics.events.STEPS_CHEKC_LIST, {
                      stepName: 'Teste o seu bot',
                    });
                    openBlipChatTab();
                  }}
                  data-testid="test-bot-link"
                >
                  Teste o seu bot
                </Link>
              </BdsTypo>

              <BdsTypo variant="fs-20" bold="semi-bold">
                5.&nbsp;
                <Link
                  href="https://play.google.com/store/apps/details?id=com.desk&hl=pt_BR&gl=US"
                  target="_balnk"
                  onClick={() => {
                    Analytics.Track(Analytics.events.STEPS_CHEKC_LIST, {
                      stepName: 'Atenda no celular',
                    });
                  }}
                  data-testid="mobile-app-link"
                >
                  Atenda no celular
                </Link>
              </BdsTypo>
            </div>
          </BdsGrid>
        </TipsContainer>
        <BdsPaper style={{ borderRadius: '16px' }}>
          <BdsGrid padding="3" direction="column" height="100%">
            <BdsTypo variant="fs-24" bold="semi-bold">
              Precisa de ajuda? Veja o vídeo e aprenda a customizar seu chatbot em poucos minutos:
            </BdsTypo>
            <YouTube
              videoId="_y9gstsQxI0"
              title="Implantando o Blip Go! em 2 minutos"
              onStateChange={e => {
                trackPlaybackStateChange(e.target.getPlayerState() ?? -1);
              }}
              style={{ height: '100%' }}
              opts={{
                width: '100%',
                height: '100%',
                playerVars: {
                  autoplay: 0,
                },
              }}
              data-testid="playVideoYoutube"
            />
          </BdsGrid>
        </BdsPaper>
      </BdsGrid>
    </BdsGrid>
  );
};

export default CustomizeChatbot;
