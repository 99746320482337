import { BdsInput, BdsLoadingPage, BdsSelect, BdsSelectOption, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import Button from 'components/Button';
import Grid from 'components/Grid/Grid';
import { GetAccountSettings } from 'configurations/Account';
import ExternalUrls from 'constants/ExternalUrls';
import { CHANNELS_ROUTE } from 'constants/Routes';
import { HUBSPOT_ERROR_CODE } from 'constants/WhatsAppActivationErrorCodes';
import useGraphApi from 'hooks/useGraphApi';
import { Logger } from 'infra/adapters';
import Analytics from 'infra/adapters/SegmentAdapter';
import { PhoneNumber, WhatsAppInfo } from 'objects/types/WhatsappActivation';
import { useAuth } from 'oidc-react';
import { WhatsappActivationFooter } from 'pages/ExperienceCluster/subPages/WhatsappActivationFooter/WhatsappActivationFooter';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useInstallation } from 'redux/slices/InstallationSlice/installationSlice';
import { updateWhatsAppActive } from 'redux/slices/ProjectSlice/projectSlice';
import { AppDispatch } from 'redux/store';
import { GetChannelsActivate, activateNumberWhatsapp } from 'services/PackService';
import { createToastError, createToastSuccess } from 'services/Toats';
import { isWhatsAppChannelActive } from 'utils/ActivateChannel';
import { formatLogMessage } from 'utils/LogMessage';
import { MetaInputContainer } from './styles';

type Props = {
  metaUserToken: any;
};

const className = 'WabaActivation';

export const WabaActivation = ({ metaUserToken }: Props) => {
  const methodName = 'WabaActivation';
  const pinMinLength = 6;
  const auth = useAuth();
  const navigate = useNavigate();
  const onlyNumberRegex = /^\d+$/;
  const dispatch = useDispatch<AppDispatch>();
  const token = GetAccountSettings(auth).token;

  const { router } = useSelector(useInstallation);

  const { getWabas } = useGraphApi();
  const [pin, setPin] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [isChannelActive, setIsChannelActive] = useState(false);
  const [whatsAppInfo, setWhatsAppInfo] = useState<WhatsAppInfo>();
  const [connectBtnLoading, setConnectBtnLoading] = useState(false);

  const handlePinInput = (event: any) => {
    const { value } = event.target as HTMLInputElement;
    if (onlyNumberRegex.test(value) && value.length <= pinMinLength) {
      setPin(value);
    }
  };

  const handleSelectedNumber = (phoneNumber: PhoneNumber) => {
    setWhatsAppInfo((prev: any) => ({
      ...prev,
      selectedNumber: phoneNumber,
    }));
  };

  const fetchUserInformations = async (token: string) => {
    const methodName = 'fetchUserInformations';
    if (token) {
      const { wabas, applicationName } = await getWabas(token);
      const phoneNumbers = wabas.map(waba => {
        const phoneInfo = waba.phoneNumbers.reduce((firstPhone: any, secondPhone: any) =>
          firstPhone.concat(secondPhone),
        );
        return {
          ...phoneInfo,
          wabaId: waba.id,
        };
      });
      Logger.Info(`waba info fetched successfully on Meta: ${phoneNumbers}`, { methodName, className });
      const uniquePhoneNumbers = phoneNumbers
        .filter((value: PhoneNumber, index: number, array: PhoneNumber[]) => array.indexOf(value) === index)
        .sort();

      setWhatsAppInfo({
        userPhoneNumbers: uniquePhoneNumbers,
        applicationName: applicationName,
        wabas: wabas,
      });
    } else {
      Logger.Error('token empty or undefined', { methodName, className });
    }
  };

  const activateWhatsApp = async () => {
    const methodName = 'activateWhatsApp';
    if (isWabaInfoValid()) {
      setConnectBtnLoading(true);
      const res = await activateNumberWhatsapp(
        router.shortName,
        whatsAppInfo?.selectedNumber?.id as string,
        whatsAppInfo?.selectedNumber?.wabaId as string,
        pin,
        token,
      );

      if (res?.isValid) {
        Analytics.Track(Analytics.events.META_CONNECT_API_CLICK, {
          status: 'Sucesso',
          phoneNumber: whatsAppInfo?.selectedNumber?.displayPhoneNumber,
        });
        setIsChannelActive(true);
        await handleWhatasppActivate();
        createToastSuccess('Sucesso!', 'O canal foi ativado :-)');
        navigate(CHANNELS_ROUTE);
      } else {
        const errorPayload = {
          phoneNumber: whatsAppInfo?.selectedNumber?.displayPhoneNumber,
          status: 'Falha',
          error: '',
        };
        if (res?.Error?.errorCode === HUBSPOT_ERROR_CODE) {
          errorPayload.error = 'Erro causado pelo ticket do Hubspot';
        } else {
          errorPayload.error = 'Erro causado pela Api do WhatsApp';
        }
        Analytics.Track(Analytics.events.META_CONNECT_API_CLICK, errorPayload);
        setConnectBtnLoading(false);

        createToastError('Erro na ativação do WhatsApp', errorPayload?.error);
        Logger.Error(`Error activating WhatsApp. Error: ${errorPayload}`, {
          methodName,
          className,
        });
      }
    } else {
      createToastError('Erro na ativação do WhatsApp', 'Verifique se os campos estão preenchidos corretamente.');
      Logger.Error('Error activating WhatsApp, fields may be filled out incorrectly.', {
        methodName,
        className,
      });
    }
  };

  const handleWhatasppActivate = async () => {
    const methodName = 'handleWhatasppActivate';

    try {
      setIsLoading(true);
      const response = await GetChannelsActivate(router.shortName, token);
      dispatch(updateWhatsAppActive(isWhatsAppChannelActive(response)));
      setIsLoading(false);
    } catch (ex) {
      setIsLoading(false);
      createToastError('Falha ao verificar whatsapp  ativo', 'Por favor, tente novamente mais tarde.');
      Logger.Error(formatLogMessage(ex), {
        methodName,
        className,
      });
    }
  };

  const sendReportTracking = () => {
    Analytics.Track(Analytics.events.META_REPORT_PROBLEM, { screenName: 'Conexão com whatsapp api' });
    window.open(
      `${ExternalUrls.whatsappActivationChat}Gostaria de reportar um *problema* no *cadastramento na Meta*`,
      '_blank',
    );
  };

  const isWabaInfoValid = () => {
    const isPinValid = pin?.length === 6;
    const isPhoneSelected = whatsAppInfo?.selectedNumber?.id !== '';
    return isPhoneSelected && isPinValid;
  };

  useEffect(() => {
    fetchUserInformations(metaUserToken);
    Logger.Info('waba Activation page loaded', { methodName, className });
  }, []);

  return (
    <Grid direction="column" gap="3" margin="t-1">
      <BdsTypo variant="fs-24" margin={false}>
        Conexão com o Whatsapp API
      </BdsTypo>
      {isChannelActive ? (
        <>
          <BdsTypo variant="fs-14">
            Número de telefone conectado: {whatsAppInfo?.selectedNumber?.displayPhoneNumber}
          </BdsTypo>
        </>
      ) : (
        <>
          <BdsTypo variant="fs-14">Escolha o número de telefone*</BdsTypo>
          <MetaInputContainer>
            <BdsSelect
              danger={!whatsAppInfo?.selectedNumber?.displayPhoneNumber}
              error-message="Este campo é obrigatório"
              placeholder="Selecionar número"
              data-testid="meta-phone-dropdown"
            >
              {whatsAppInfo?.userPhoneNumbers?.map((number: PhoneNumber) => {
                return (
                  <BdsSelectOption
                    key={number?.id}
                    value={number?.displayPhoneNumber}
                    onClick={() => handleSelectedNumber(number)}
                  >
                    {number?.displayPhoneNumber}
                  </BdsSelectOption>
                );
              })}
            </BdsSelect>
          </MetaInputContainer>
          <BdsTypo variant="fs-14">Crie um código PIN para sua segurança*</BdsTypo>
          <MetaInputContainer>
            <BdsInput
              data-testid="meta-phone-pin"
              value={pin}
              type="password"
              min="0"
              danger={pin?.length < 6}
              error-message="Este campo é obrigatório"
              onInput={ev => handlePinInput(ev)}
              placeholder="Crie um código númerico de 6 digitos"
            ></BdsInput>
          </MetaInputContainer>
        </>
      )}
      <Button
        variant="primary"
        disabled={connectBtnLoading}
        onClick={() => activateWhatsApp()}
        dataTestId="active-whatsapp"
        text="Conectar ao Whatsapp Api"
      />
      {isLoading && <BdsLoadingPage />}
      <WhatsappActivationFooter linkPressed={() => sendReportTracking()} linkTitle="Reportar problema" />
    </Grid>
  );
};
