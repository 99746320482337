import { BdsSelect, BdsSelectOption } from 'blip-ds/dist/blip-ds-react/components';

type SelectProps = {
  danger?: boolean;
  errorMessage?: string;
  options?: any[];
  label?: string;
  onChange?: (event: CustomEvent) => void;
  dataTestId?: string;
  optionsDataTestId?: string;
  value?: string;
};

export const Select = ({
  value,
  danger,
  errorMessage,
  options,
  label,
  onChange,
  dataTestId,
  optionsDataTestId,
}: SelectProps) => {
  return (
    <BdsSelect
      value={value}
      onBdsChange={onChange}
      danger={danger}
      data-testid={dataTestId}
      label={label}
      error-message={errorMessage}
    >
      {options?.map(option => {
        return (
          <BdsSelectOption value={option.value} key={option.value} data-testid={optionsDataTestId}>
            {option.label}
          </BdsSelectOption>
        );
      })}
    </BdsSelect>
  );
};
