import React from 'react';
import './style.scss';
import { BdsGrid, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import SelectContactsIcon from './../../../../assets/SelectContactsIcon.svg';
import NoContactsIcon from './../../../../assets/NoContactsIcon.svg';
import './style.scss';

interface ChatIconPageProps {
  existContacts: boolean;
}

const ChatIconPage: React.FC<ChatIconPageProps> = ({ existContacts }) => {
  return (
    <BdsGrid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className="image-container"
      gap="2"
    >
      {existContacts ? (
        <>
          <BdsGrid>
            <img src={SelectContactsIcon} alt="Select Contact" />
          </BdsGrid>
          <BdsGrid direction="column" justifyContent="center" alignItems="center">
            <BdsTypo variant="fs-16" bold="bold">
              Tudo pronto!
            </BdsTypo>
            <BdsTypo variant="fs-14">Escolha um contato ao lado para visualizar as mensagens!</BdsTypo>
          </BdsGrid>
        </>
      ) : (
        <>
          <BdsGrid>
            <img src={NoContactsIcon} alt="No Contact" />
          </BdsGrid>
          <BdsGrid direction="column" justifyContent="center" alignItems="center">
            <BdsTypo variant="fs-16" bold="bold">
              Não há contatos no momento.
            </BdsTypo>
            <BdsTypo variant="fs-14">Assim que seus clientes entrarem em contato com sua empresa</BdsTypo>
            <BdsTypo variant="fs-14">eles aparecerão aqui!</BdsTypo>
          </BdsGrid>
        </>
      )}
    </BdsGrid>
  );
};

export default ChatIconPage;
