import Iframe from 'components/Iframe';
import LayoutContainer from 'components/LayoutContainer/LayoutContainer';
import MainLayout from 'components/MainLayout/MainLayout';
import { DeskUrl } from 'configurations/Environment';
import React from 'react';

const Desk: React.FC = () => {
  const titleDesk = 'Atendimento';

  return (
    <MainLayout>
      <LayoutContainer>
        <Iframe url={DeskUrl} title={titleDesk} />
      </LayoutContainer>
    </MainLayout>
  );
};

export default Desk;
