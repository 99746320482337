import { Channel } from 'objects/types/Channel';
import instagramLogo from '../assets/channels/instagram.png';
import messengerLogo from '../assets/channels/messenger.png';
import takeLogo from '../assets/channels/take.png';
import whatsAppLogo from '../assets/channels/whatsapp.png';
import ChannelsName from './ChannelsName';

export const CHANNELS: Channel[] = [
  {
    title: ChannelsName.Whatsapp,
    photo: whatsAppLogo,
    link: 'https://api.whatsapp.com/send/?phone=553192160059&text=Olá, quero fazer o *upgrade* para o *plano Pro* do BlipGo',
    linkToTest: '',
    imageModal: '',
    description: 'Seus clientes estão no canal mais usado do Brasil.',
    textAvailability: 'Plano Basic',
  },
  {
    title: ChannelsName.Instagram,
    photo: instagramLogo,
    link: '',
    linkToTest: '',
    imageModal: '/assets/images/instagramActivationModal.svg',
    description: 'Concecte-se e atenda na rede social que mais cresce.',
    textAvailability: 'Disponível',
  },
  {
    title: ChannelsName.Messenger,
    photo: messengerLogo,
    link: '',
    linkToTest: '',
    imageModal: '/assets/images/messengerActivationModal.svg',
    description: 'Atenda clientes que entram em contato pelo Facebook.',
    textAvailability: 'Disponível',
  },
  {
    title: ChannelsName.Blipchat,
    photo: takeLogo,
    link: '',
    linkToTest: '',
    imageModal: '',
    description: 'Inclua um chat no seu site para ampliar seu atendimeto.',
    textAvailability: 'Disponível',
  },
];
