import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import IAccountState from './interface';
import { Profile } from 'oidc-client';

const InitialState: IAccountState = {
  token: '',
  profile: {} as Profile,
};

const accountSlice = createSlice({
  name: 'account',
  initialState: InitialState,
  reducers: {
    addAccount(state, { payload }: PayloadAction<IAccountState>) {
      return {
        ...state,
        token: payload.token,
        profile: payload.profile,
      };
    },
  },
});

export default accountSlice.reducer;

export const { addAccount } = accountSlice.actions;

export const useAccount = (state: { account: IAccountState }) => state.account;
