import './style.scss';
import { BdsTypo } from 'blip-ds/dist/blip-ds-react/components';

type GptChatBubbleProps = {
  contentHTML: JSX.Element | string | undefined;
  sender: 'received' | 'sent' | 'default';
  type: string | undefined;
};

function ChatBubble({ contentHTML, sender, type }: GptChatBubbleProps) {
  let bubbleClass = 'gpt-chat-bubble default';

  switch (type) {
    case 'application/vnd.iris.ticket+json':
      bubbleClass = 'gpt-chat-bubble default';
      break;
    default:
      switch (sender) {
        case 'received':
          bubbleClass = 'gpt-chat-bubble user';
          break;
        case 'sent':
          bubbleClass = 'gpt-chat-bubble assistant';
          break;
        case 'default':
          bubbleClass = 'gpt-chat-bubble default';
          break;
      }
  }

  return (
    <div className={bubbleClass} data-testid="chat-bubble">
      <BdsTypo variant="fs-12" style={{ color: sender === 'sent' ? '#FFFFFF' : '#454545' }}>
        {typeof contentHTML === 'string' ? <div dangerouslySetInnerHTML={{ __html: contentHTML }}></div> : contentHTML}
      </BdsTypo>
    </div>
  );
}

export default ChatBubble;
