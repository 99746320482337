import { BdsButton, BdsGrid, BdsIllustration, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { INSTAGRAM_ACTIVATION_BUSINESS_ACCOUNTS } from 'constants/Routes';
import { Paper } from 'globalStyles';
import useGraphApi from 'hooks/useGraphApi';
import { Analytics } from 'infra/adapters';
import { useAuth } from 'oidc-react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useInstallation } from 'redux/slices/InstallationSlice/installationSlice';
import { getFacebookAppInfo } from 'services/PackService';

function FacebookConnection() {
  const { initFacebookSdk, launchInstagramSignup, getUser } = useGraphApi();
  const { router } = useSelector(useInstallation);
  const auth = useAuth();
  const navigate = useNavigate();

  const getUserInfoAndRedirect = async (token: string) => {
    const user = await getUser(token);
    return navigate(INSTAGRAM_ACTIVATION_BUSINESS_ACCOUNTS, { state: { user, token } });
  };

  const openFacebookPopup = async () => {
    const fbAppInfo = await getFacebookAppInfo(router.shortName, auth.userData?.access_token || '');
    await initFacebookSdk(fbAppInfo.appId);
    await launchInstagramSignup(accessToken => getUserInfoAndRedirect(accessToken));
  };

  useEffect(() => {
    Analytics.Track(Analytics.events.INSTAGRAM_ACTIVATION_FACEBOOK_CONNECTION_PAGE);
  }, []);

  return (
    <BdsGrid justifyContent="center" alignItems="center">
      <Paper>
        <BdsGrid gap="4" padding="y-3" justifyContent="center" alignItems="center">
          <BdsIllustration style={{ height: '10rem' }} type="logo-integration" name="blip-instagram" />
          <BdsGrid direction="column" gap="2">
            <BdsTypo tag="h3" bold="bold" variant="fs-24">
              Para conectar seu Instagram, vamos fazer seu login no Facebook:
            </BdsTypo>
            <BdsButton
              icon="facebook"
              onBdsClick={() => {
                Analytics.Track(Analytics.events.INSTAGRAM_ACTIVATION_CONNECT_FACEBOOK_BUTTON);
                openFacebookPopup();
              }}
            >
              Conectar Facebook
            </BdsButton>
          </BdsGrid>
        </BdsGrid>
      </Paper>
    </BdsGrid>
  );
}

export default FacebookConnection;
