import { BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { Button } from 'components/Button/Button';
import Grid from 'components/Grid/Grid';
import { Logger } from 'infra/adapters';
import { WabaActivation } from 'pages/ExperienceCluster/subPages/WabaActivation/WabaActivation';
import { WhatsappActivationFooter } from 'pages/ExperienceCluster/subPages/WhatsappActivationFooter/WhatsappActivationFooter';
import { useEffect } from 'react';
import { useMetaConnection } from './hooks/useMetaConnection';
import { MainContainer, MetaLink } from './styles';

const className = 'MetaConnection';

export const MetaConnection = () => {
  const {
    handleLeanMoreLink,
    handleRegisterBtn,
    retryOpenEmbedded,
    handleReportProblemBtn,
    metaUserToken,
    readyToConnectToWaba,
    showRetryPage,
    initHook,
  } = useMetaConnection();
  const methodName = 'MetaConnection';

  useEffect(() => {
    initHook();
    Logger.Info('Meta Connection page loaded', { methodName, className });
  }, []);

  const RenderFirstMetaConnection = (
    <>
      {showRetryPage ? (
        <div>
          <span>Se o facebook não abrir </span>
          <MetaLink data-testid={'click-here'} onClick={() => retryOpenEmbedded()}>
            clique aqui
          </MetaLink>
        </div>
      ) : (
        <Grid direction="column" gap="3" margin="t-1">
          <BdsTypo className="typo" variant="fs-24">
            Como conectar
          </BdsTypo>
          <BdsTypo variant="fs-14">
            Para utilizar a API do whatsapp você precisa fazer um <b>cadastro na Meta/facebook</b> da sua empresa e do
            número que deseja conectar.
          </BdsTypo>
          <Button variant="primary" onClick={() => handleRegisterBtn()} text="Cadastrar agora" />
          <div>
            <MetaLink onClick={() => handleLeanMoreLink()} data-testid="learn-more">
              Saiba mais
            </MetaLink>
            <span> sobre o cadastro no facebook</span>
          </div>
          <WhatsappActivationFooter linkPressed={() => handleReportProblemBtn()} linkTitle="Reportar problema" />
        </Grid>
      )}
    </>
  );

  return (
    <MainContainer>
      {readyToConnectToWaba ? <WabaActivation metaUserToken={metaUserToken} /> : RenderFirstMetaConnection}
    </MainContainer>
  );
};
