import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CHANNELS } from 'constants/Channels';
import ChannelsName from 'constants/ChannelsName';
import { Channel } from 'objects/types/Channel';
import IChannelsModuleState from './interface';
import { fetchBlipChatConfigKey } from './thunkMiddleware';

const InitialState: IChannelsModuleState = {
  channels: CHANNELS,
};

const channelsModuleSlice = createSlice({
  name: 'channelsModule',
  initialState: InitialState,
  reducers: {
    updateChannels(state, { payload }: PayloadAction<Channel[]>) {
      return { ...state, channels: payload };
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchBlipChatConfigKey.fulfilled, (state, { payload }: PayloadAction<string>) => {
      state.channels.forEach(channel => {
        if (channel.title === ChannelsName.Blipchat) {
          channel.linkToTest = payload;
        }
      });
    });
  },
});

export default channelsModuleSlice.reducer;

export const { updateChannels } = channelsModuleSlice.actions;

export const useChannelsModule = (state: { channelsModule: IChannelsModuleState }) => state.channelsModule;
