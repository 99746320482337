import InputText from 'components/InputText';
import SubPageHeader from 'components/SubPageHeader/SubPageHeader';
import ConfigurationsContainer from 'pages/Configurations/components/ConfigurationsContainer/ConfigurationsContainer';
import { Content, SpaceTextInput } from './styles';
import { ANALYTICS } from 'constants/Analytics';
import { RESOURCES } from 'constants/ResourcesNames';
import { TOAST_FAILURE, TOAST_FAILURE_MESSAGE, TOAST_SUCCESS, TOAST_SUCCESS_MESSAGE } from 'constants/ToastMessages';
import { Analytics, Logger } from 'infra/adapters';
import { TrackingProperties } from 'objects/types/TrackingProperties';
import { Resource } from 'objects/types/commands/Resource';
import { useAuth } from 'oidc-react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInstallation } from 'redux/slices/InstallationSlice/installationSlice';
import { useProject } from 'redux/slices/ProjectSlice/projectSlice';
import { updateMessages, useResource } from 'redux/slices/ResourceSlices/resourceSlice';
import { setInstallationResources } from 'services/PackService';
import { createToastError, createToastSuccess } from 'services/Toats';
import { formatLogMessage } from 'utils/LogMessage';
import { BdsGrid } from 'blip-ds/dist/blip-ds-react';

type stateType = {
  value: string;
  error: boolean;
  errorMessage: string;
  isModified: boolean;
};

const className = 'useGreeting';

const Greeting: React.FC = () => {
  const auth = useAuth();
  const dispatch = useDispatch();

  const { messages } = useSelector(useResource);
  const { router } = useSelector(useInstallation);
  const { activeSubPage } = useSelector(useProject);

  const [disabledButtonSave, setDisabledButtonSave] = useState<boolean>(true);
  const [titleCallUS, setTitleCallUS] = useState<stateType>({
    error: false,
    errorMessage: '',
    value: messages.titleCallUS,
    isModified: false,
  });
  const [returnMessage, setReturnMessage] = useState<stateType>({
    error: false,
    errorMessage: '',
    value: messages.returnMessage,
    isModified: false,
  });
  const [firstContactMessage, setFirstContactMessage] = useState<stateType>({
    error: false,
    errorMessage: '',
    value: messages.firstContactMessage,
    isModified: false,
  });

  const handleFirstContactMessageInput = (event: Event) => {
    const { value } = event.target as HTMLBdsInputElement;

    const isChange = (value as string) === messages.firstContactMessage;

    validateDisableSaveButton(!isChange, returnMessage.isModified, titleCallUS.isModified);
    setFirstContactMessage({ ...firstContactMessage, value: value as string, isModified: !isChange });
  };

  const handleReturnMessageInput = (event: Event) => {
    const { value } = event.target as HTMLBdsInputElement;

    const isChange = (value as string) === messages.returnMessage;

    validateDisableSaveButton(firstContactMessage.isModified, !isChange, titleCallUS.isModified);
    setReturnMessage({ ...returnMessage, value: value as string, isModified: !isChange });
  };

  const handleTitleCallUSInput = (event: Event) => {
    const { value } = event.target as HTMLBdsInputElement;

    const isChange = (value as string) === messages.titleCallUS;

    validateDisableSaveButton(firstContactMessage.isModified, returnMessage.isModified, !isChange);
    setTitleCallUS({ ...titleCallUS, value: value as string, isModified: !isChange });
  };

  const validateDisableSaveButton = (
    firstContactMessageModified: boolean,
    returnMessageModified: boolean,
    titleCallUsModified: boolean,
  ) => {
    if (firstContactMessageModified || returnMessageModified || titleCallUsModified) {
      setDisabledButtonSave(false);
    } else {
      setDisabledButtonSave(true);
    }
  };

  const saveResources = async () => {
    if (!auth.userData?.access_token) {
      return location.reload();
    }

    const methodName = 'saveResources';
    const resourcesToBeSaved = [] as Resource[];

    if (returnMessage.isModified) {
      resourcesToBeSaved.push({
        name: RESOURCES.MESSAGES.RETURN,
        type: 'text/plain',
        value: returnMessage.value,
      });
      setReturnMessage({ ...returnMessage, isModified: false });
    }

    if (firstContactMessage.isModified) {
      resourcesToBeSaved.push({
        name: RESOURCES.MESSAGES.FIRST_CONTACT,
        type: 'text/plain',
        value: firstContactMessage.value,
      });
      setFirstContactMessage({ ...firstContactMessage, isModified: false });
    }

    if (titleCallUS.isModified) {
      resourcesToBeSaved.push({
        name: RESOURCES.MESSAGES.BUTTON_MESSAGE,
        type: 'text/plain',
        value: titleCallUS.value,
      });
      setTitleCallUS({ ...titleCallUS, isModified: false });
    }

    try {
      await setInstallationResources(router.shortName, resourcesToBeSaved, auth.userData.access_token);
      dispatch(
        updateMessages({
          firstContactMessage: firstContactMessage.value,
          returnMessage: returnMessage.value,
          titleCallUS: titleCallUS.value,
          preQueueMessage: messages.preQueueMessage,
          workdayWithSaturday: messages.workdayWithSaturday,
        }),
      );
      Analytics.Track(ANALYTICS.CLICK_SAVE_BUTTON, {
        status: 'sucesso',
        screenName: activeSubPage.title,
        warningModal: false,
      } as TrackingProperties);
      createToastSuccess(TOAST_SUCCESS, TOAST_SUCCESS_MESSAGE);
    } catch (error) {
      createToastError(TOAST_FAILURE, TOAST_FAILURE_MESSAGE);
      Logger.Error(formatLogMessage(error, 'There was a failure to save changes.'), {
        methodName,
        className,
      });

      Analytics.Track(ANALYTICS.CLICK_SAVE_BUTTON, {
        status: 'falha',
        error: error,
        screenName: activeSubPage.title,
        warningModal: false,
      } as TrackingProperties);
    }
  };

  return (
    <>
      <SubPageHeader showTestButton onSave={saveResources} buttonSaveDisabled={disabledButtonSave} />

      <ConfigurationsContainer>
        <Content>
          <BdsGrid height="392px" direction="column" alignItems="center" gap="2">
            Personalize a mensagem de boas-vindas que seu bot enviará quando entrarem em contato com a sua empresa.
            <SpaceTextInput>
              <BdsGrid direction="column" height="120px" padding="r-2" margin="b-2" gap="2">
                <InputText
                  value={firstContactMessage.value}
                  onInputChange={handleFirstContactMessageInput}
                  isTextArea={true}
                  rows={3}
                  label="Saudação ao primeiro contato"
                  disabled={false}
                  required={false}
                  maxLength={220}
                  counterLength={true}
                  dataTestId="testFirstContactMessage"
                />

                <InputText
                  value={returnMessage.value}
                  onInputChange={handleReturnMessageInput}
                  isTextArea={true}
                  rows={3}
                  label="Saudação de retorno"
                  disabled={false}
                  required={false}
                  maxLength={220}
                  counterLength={true}
                  dataTestId="testReturnMessage"
                />

                <InputText
                  value={titleCallUS.value}
                  onInputChange={handleTitleCallUSInput}
                  isTextArea={false}
                  rows={1}
                  label="Nome do botão"
                  disabled={false}
                  required={false}
                  maxLength={20}
                  counterLength={true}
                  dataTestId="testTitleCallUs"
                />
              </BdsGrid>
            </SpaceTextInput>
          </BdsGrid>
        </Content>
      </ConfigurationsContainer>
    </>
  );
};

export default Greeting;
