import { BdsButton, BdsGrid, BdsInput, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { BusinessInformationContainer } from './style';

interface IProps {
  nextPageEvent: () => void;
}

export const BusinessInformation = ({ nextPageEvent }: IProps) => {
  const handleNextBtnClick = () => {
    nextPageEvent();
  };

  return (
    <BusinessInformationContainer>
      <BdsTypo bold="bold" variant="fs-32" tag="h2">
        Nos conte sobre seu negócio
      </BdsTypo>
      <BdsTypo bold="regular" variant="fs-16">
        Vamos lá! Preencha os dados essenciais para configurar o Blip Go.
      </BdsTypo>
      <BdsInput></BdsInput>
      <BdsInput></BdsInput>
      <BdsInput></BdsInput>
      <BdsGrid margin="t-4" direction="row-reverse" justifyContent="space-between">
        <BdsButton onClick={handleNextBtnClick}>Continuar</BdsButton>
      </BdsGrid>
    </BusinessInformationContainer>
  );
};
