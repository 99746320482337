import robotPhone from 'assets/RobotPhone.svg';
import { BdsButton, BdsGrid, BdsModalAction, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { IsManualFreemium } from 'constants/FeatureToggle';
import { ModalSubItem } from '../ModalSubItem/ModalSubItem';
import { ProModalContent } from '../ProModalContent/ProModalContent';

interface IBasicModalContent {
  intentPlanType?: string | null;
  handleCloseModal: () => void;
  handlePlanDirection: () => void;
}

export const BasicModalContent = ({ intentPlanType, handleCloseModal, handlePlanDirection }: IBasicModalContent) => {
  if (IsManualFreemium) {
    if (intentPlanType === 'basic' || intentPlanType === 'free') {
      return (
        <div>
          <BdsGrid gap="4" margin="t-4" style={{ width: '43.75rem' }}>
            <img src={robotPhone} alt="" />
            <BdsGrid direction="column" gap="2">
              <BdsTypo variant="fs-20" margin={false} line-height="simple" bold="bold">
                Para conectar ao WhatsApp, confirme sua assinatura Basic gratuitamente
              </BdsTypo>
              <ModalSubItem itemLabel="Conecte com a com API Oficial do WhatsApp" />
              <ModalSubItem itemLabel="Tenha uma franquia de 50 mensagens grátis mensais" />
              <ModalSubItem itemLabel="Pague somente as mensagens excedentes mensais" />
              <BdsTypo bold="bold" variant="fs-14">
                Assinatura gratuita, cancele quando quiser!
              </BdsTypo>
            </BdsGrid>
          </BdsGrid>
          <BdsModalAction>
            <BdsGrid gap="2" justifyContent="flex-end" padding="t-2" style={{ width: '100%' }}>
              <BdsButton variant="secondary" onBdsClick={handleCloseModal} data-testid="plan-modal-close-btn">
                Cancelar
              </BdsButton>
              <BdsButton arrow={true} onBdsClick={handlePlanDirection} data-testid="plan-modal-btn">
                Quero o plano Basic grátis
              </BdsButton>
            </BdsGrid>
          </BdsModalAction>
        </div>
      );
    } else {
      return (
        <ProModalContent
          activeMessageOrigin={false}
          handleCloseModal={handleCloseModal}
          handlePlanDirection={handlePlanDirection}
        />
      );
    }
  }

  return <></>;
};
