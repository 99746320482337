import React from 'react';
import { SupportMenuContainer, SupportMenuContent } from './style';
import { BdsButton, BdsGrid, BdsPaper } from 'blip-ds/dist/blip-ds-react';
import ExternalUrls from 'constants/ExternalUrls';
import { Analytics } from 'infra/adapters';
import { useLocation } from 'react-router-dom';

const SupportMenu = () => {
  const { pathname } = useLocation();
  const screenName = pathname.split('/').pop();

  const handleDirectionHelpCenter = () => {
    Analytics.Track(Analytics.events.MANAGEMENT_HELP_CENTER, { screenName });
    window.open(ExternalUrls.blipGoHelp);
  };

  const handleDirectionTeamWhatsapp = () => {
    Analytics.Track(Analytics.events.MANAGEMENT_REQUEST_SUPPORT, { screenName, origin: 'DesktopSidebar' });
    window.open(`${ExternalUrls.whatsappActivationChat}Olá, estou com dúvidas sobre o Blip GO!`, '_blank');
  };

  return (
    <BdsGrid direction="column" data-testid="balloon-help" style={{ position: 'absolute', left: '80%' }}>
      <BdsPaper elevation="primary">
        <SupportMenuContainer>
          <BdsGrid justifyContent="center" direction="column">
            <SupportMenuContent>
              <BdsButton
                data-testid="help-center-btn"
                icon="book"
                variant="secondary"
                onClick={() => handleDirectionHelpCenter()}
              >
                Central de ajuda
              </BdsButton>
              <BdsButton
                data-testid="support-btn"
                icon="message-ballon"
                variant="secondary"
                onClick={() => handleDirectionTeamWhatsapp()}
              >
                Acionar suporte
              </BdsButton>
            </SupportMenuContent>
          </BdsGrid>
        </SupportMenuContainer>
      </BdsPaper>
    </BdsGrid>
  );
};

export default SupportMenu;
