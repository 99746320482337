import { BdsGrid } from 'blip-ds/dist/blip-ds-react';
import React, { ReactNode } from 'react';

interface IGrid extends Partial<Omit<HTMLBdsGridElement, 'children' | 'style'>> {
  children?: ReactNode;
  style?: React.CSSProperties;
}

const Grid = (props: IGrid) => {
  return (
    <BdsGrid containerFluid xxs={props.xxs || '12'} {...(props as any)}>
      {props.children}
    </BdsGrid>
  );
};

export default Grid;
