import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
export const NavigationBtnContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
`;

export const StepperContainer = styled.div`
  height: calc(100vh - 64px);
  overflow: hidden auto;

  .fade-in {
    animation: ${fadeIn} 0.4s ease-in-out;
  }

  @media screen and (max-width: 1366px) {
    margin: 0px 8px;
    height: calc(100vh - 78px);
  }
`;

export const SafeEnvironmentContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  background: #f6f6f6;
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
  border-top: solid #dbdbdb 1px;
  left: 50%;
  z-index: 9999;
`;
