import { AxiosInstance } from 'axios';
import { HttpConnector } from './HttpConnector';

/**
 * Connects to an http Rest service over Axios http client
 * @constructor Requires an service url string
 * 
 * @param baseUrl a service URL as `string` for consumer
*/
export class LogConnector extends HttpConnector {
    protected logClient: AxiosInstance;

    constructor( baseUrl: string) {
        super(baseUrl);
        
        this.logClient = this.httpClient;
    }
}