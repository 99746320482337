import { LARGE_MOBILE_SCREEN } from 'constants/MediaQueryResolutions';
import styled from 'styled-components';

export const ReviewContractInfoSubitems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ReviewInfoCardContainer = styled.div`
  margin: 16px;
  width: 620px;

  @media screen and (max-width: ${LARGE_MOBILE_SCREEN}) {
    width: 100%;
    margin: 8px;
  }
`;

export const ReviewContractInfoObservations = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 4px;
`;
