import { BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { ReportBtn } from './styles';

interface Props {
  linkPressed: () => void;
  linkTitle: string;
}

export const WhatsappActivationFooter = ({ linkPressed, linkTitle }: Props) => {
  return (
    <ReportBtn>
      <BdsTypo variant="fs-14" onClick={() => linkPressed()} data-testid="footer-link">
        {linkTitle}
      </BdsTypo>
    </ReportBtn>
  );
};
