import { BdsButton, BdsGrid, BdsInput, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { CompanyObjectivesContainer } from './styles';

interface IProps {
  nextPageEvent: () => void;
}

export const CompanyObjectives = ({ nextPageEvent }: IProps) => {
  const handleNextBtnClick = () => {
    nextPageEvent();
  };

  return (
    <BdsGrid direction="column">
      <CompanyObjectivesContainer>
        <BdsTypo bold="bold" variant="fs-32" tag="h2">
          Fale sobre seus objetivos
        </BdsTypo>
        <BdsTypo bold="regular" variant="fs-16">
          Agora fale um pouco mais sobre como pretende usar Blip Go.
        </BdsTypo>
        <BdsInput></BdsInput>
        <BdsInput></BdsInput>
        <BdsInput></BdsInput>
        <BdsInput></BdsInput>
        <BdsInput></BdsInput>
        <BdsGrid margin="t-4" direction="row-reverse" justifyContent="space-between">
          <BdsButton onClick={handleNextBtnClick}>Continuar</BdsButton>
        </BdsGrid>
      </CompanyObjectivesContainer>
    </BdsGrid>
  );
};
