import { createAsyncThunk } from '@reduxjs/toolkit';
import { RESOURCES } from 'constants/ResourcesNames';
import { setSpecificResource } from 'services/CommandsService';
import { ISetString } from './interface';

export const setPreServiceQuestion = createAsyncThunk(
  'resources/setPreServiceQuestion',
  async ({ key, value }: ISetString, { rejectWithValue }) => {
    try {
      await setSpecificResource(key, {
        name: RESOURCES.PRE_SERVICE_QUESTIONS,
        type: 'text/plain',
        value: value,
      });

      return value;
    } catch (ex) {
      const error = ex as Error;
      return rejectWithValue(error.message);
    }
  },
);

export const setPreQueueMessage = createAsyncThunk(
  'resources/setPreQueueMessage',
  async ({ key, value }: ISetString, { rejectWithValue }) => {
    try {
      await setSpecificResource(key, {
        name: RESOURCES.MESSAGES.PRE_QUEUE,
        type: 'text/plain',
        value: value,
      });

      return value;
    } catch (ex) {
      const error = ex as Error;
      return rejectWithValue(error.message);
    }
  },
);