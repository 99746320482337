import { BdsButton, BdsGrid, BdsInput, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { PersonalizeServiceContainer } from './styles';

interface IProps {
  nextPageEvent: () => void;
}

export const PersonalizeService = ({ nextPageEvent }: IProps) => {
  const handleNextBtnClick = () => {
    nextPageEvent();
  };

  return (
    <BdsGrid direction="column">
      <PersonalizeServiceContainer>
        <BdsTypo bold="bold" variant="fs-32" tag="h2">
          Personalize o seu atendimento
        </BdsTypo>
        <BdsTypo bold="regular" variant="fs-16">
          Iremos deixar tudo pré configurado e pronto para o uso.
        </BdsTypo>

        <BdsInput></BdsInput>
        <BdsInput></BdsInput>
        <BdsInput></BdsInput>
        <BdsInput></BdsInput>
        <BdsInput></BdsInput>
        <BdsGrid margin="t-4" direction="row-reverse" justifyContent="space-between">
          <BdsButton onClick={handleNextBtnClick}>Continuar</BdsButton>
        </BdsGrid>
      </PersonalizeServiceContainer>
    </BdsGrid>
  );
};
