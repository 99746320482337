import { BdsGrid, BdsLoadingSpinner, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { PermissionItem } from './components/PermissionItem';
import { getBotPermissions, setAttendantsPermissions, setBotPermissions } from 'services/BlipGoApiService';
import { useSelector } from 'react-redux';
import { useInstallation } from 'redux/slices/InstallationSlice/installationSlice';
import { useCustomerService } from 'redux/slices/CustomerServiceSlice/customerServiceSlice';
import { AttendantPermission } from 'constants/Permissions';
import { Analytics, Logger } from 'infra/adapters';
import { createToastError, createToastSuccess } from 'services/Toats';
import ConfigurationsContainer from '../components/ConfigurationsContainer/ConfigurationsContainer';
import SubPageHeader from 'components/SubPageHeader/SubPageHeader';
import { TOAST_FAILURE, TOAST_FAILURE_MESSAGE } from 'constants/ToastMessages';
import { useEffect, useState } from 'react';
import { formatLogMessage } from 'utils/LogMessage';
import { BotPermissions } from 'objects/types/BlipApi';

export const Permissions = () => {
  const className = 'Permissions.tsx';
  const { tenant, desk } = useSelector(useInstallation);
  const { attendants } = useSelector(useCustomerService);
  const [botConfigurations, setBotConfigurations] = useState<BotPermissions>();
  const [infoLoaded, setInfoLoaded] = useState<boolean>(false);

  useEffect(() => {
    handleBotPermissions();
  }, []);

  const handleConfigurationChange = async (configuration: string, attendantPermission: string, status: boolean) => {
    try {
      await setBotPermissions(tenant?.id, desk.shortName, configuration, status);
      await setAttendantsPermissions(tenant?.id, desk.shortName, attendantPermission, status, attendants);
      Analytics.Track(Analytics.events.TOGGLE_BOT_PERMISSION, {
        status: 'sucesso',
        type: configuration,
        value: status,
      });
      createToastSuccess('Dados alterados com sucesso!');
    } catch {
      createToastError(TOAST_FAILURE, TOAST_FAILURE_MESSAGE);
      Analytics.Track(Analytics.events.TOGGLE_BOT_PERMISSION, { status: 'falha', type: configuration, value: status });
    }
  };

  const handleBotPermissions = async () => {
    const methodName = 'handleBotPermissions';
    try {
      const permissions = await getBotPermissions(tenant.id, desk.shortName);
      setBotConfigurations(permissions);
    } catch (ex) {
      Logger.Error(formatLogMessage(ex, 'Failed to retrieve bot permissions.'), {
        methodName,
        className,
      });
    } finally {
      setInfoLoaded(true);
    }
  };

  if (!infoLoaded)
    return (
      <BdsGrid justifyContent="center" alignItems="center" height="100%">
        <BdsLoadingSpinner />
      </BdsGrid>
    );

  return (
    <>
      <SubPageHeader showTestButton />
      <ConfigurationsContainer>
        <BdsGrid direction="column" gap="2">
          <BdsTypo variant="fs-16">Habilite quais recursos todos os seus atendentes terão acesso no dia a dia</BdsTypo>
          <PermissionItem
            permissionTitle="Consultar contatos"
            permissionDescription="Permite consultas a informações de atendimentos anteriores em Conversas"
            onChange={ev =>
              handleConfigurationChange('HistoryEnabled', AttendantPermission.canAccessContactHistory, ev)
            }
            isActive={botConfigurations?.historyEnabled || false}
          />
          <PermissionItem
            permissionTitle="Editar contato em Conversas"
            permissionDescription="Permite editar informações básicas dos contatos em Conversas"
            onChange={ev => handleConfigurationChange('CanEditContact', AttendantPermission.canEditContact, ev)}
            isActive={botConfigurations?.canEditContact || false}
          />
        </BdsGrid>
      </ConfigurationsContainer>
    </>
  );
};
