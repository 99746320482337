import { BdsGrid, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import LayoutContainer from 'components/LayoutContainer/LayoutContainer';
import MainLayout from 'components/MainLayout/MainLayout';
import { ActiveMessagesCard } from './components/ActiveMessagesCard/ActiveMessagesCard';
import { Analytics } from 'infra/adapters';
import activeMessageGif from 'assets/ActiveMessageDemo.gif';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { IInstallationState } from 'redux/slices/InstallationSlice/interface';
import { useInstallation } from 'redux/slices/InstallationSlice/installationSlice';
import { ApplicationDetail } from 'configurations/Environment';

export const ActiveMessages = () => {
  const { tenant, router } = useSelector<RootState, IInstallationState>(useInstallation);
  const createActiveMessagesUrl = `https://${tenant.id}.${ApplicationDetail}/${router.shortName}/contents/messagetemplate`;
  const sendActiveMessagesUrl = `https://${tenant.id}.${ApplicationDetail}/${router.shortName}/growth/activemessages`;

  const handleBtnClick = (link: string) => {
    window.open(link, '_blank');
  };

  return (
    <MainLayout>
      <LayoutContainer>
        <BdsGrid direction="column" gap="4" lg="6" md="8" style={{ margin: 'auto' }}>
          <BdsGrid direction="row" gap="4" lg="6" md="8" padding="t-6" style={{ width: '100%' }}>
            <ActiveMessagesCard
              icon="message-ballon"
              title="Modelos de mensagem"
              btnLabel="Criar modelo de mensagem"
              description="Crie modelos de mensagem para utilizar em campanhas no WhatsApp ou contatar ativamente seus leads e clientes."
              tracking={Analytics.events.ACTIVE_MESSAGES_CREATE}
              testId="create-active-message"
              btnClickEvent={() => handleBtnClick(createActiveMessagesUrl)}
            />
            <ActiveMessagesCard
              icon="megaphone"
              title="Campanhas no WhatsApp"
              btnLabel="Enviar mensagens em massa"
              description="Envie mensagens em massa para o WhatsApp dos seus leads e clientes. Dispare até 1000 mensagens em uma única vez."
              tracking={Analytics.events.ACTIVE_MESSAGES_SEND}
              testId="send-active-message"
              btnClickEvent={() => handleBtnClick(sendActiveMessagesUrl)}
            />
          </BdsGrid>
          <BdsPaper style={{ background: '#3F7DE8', marginBottom: '5rem' }}>
            <BdsGrid gap="10" padding="3">
              <BdsGrid direction="column" gap="3" justifyContent="center">
                <BdsTypo variant="fs-24" margin={false} bold="bold" style={{ color: 'white' }}>
                  Potencialize seu WhatsApp
                </BdsTypo>
                <BdsTypo variant="fs-16" style={{ color: 'white' }}>
                  Envie mensagens em massa no WhatsApp para divulgar ofertas, realizar vendas ou compartilhar
                  informações sobre seu negócio, e fique mais próximo do seu público.
                </BdsTypo>
                <BdsTypo variant="fs-16" style={{ color: 'white' }}>
                  Mais eficiente que lista de transmissão, pois o seu destinatário não precisa ter seu telefone salvo
                  nos contatos.
                </BdsTypo>
              </BdsGrid>
              <img src={activeMessageGif} width={'50%'} />
            </BdsGrid>
          </BdsPaper>
        </BdsGrid>
      </LayoutContainer>
    </MainLayout>
  );
};
