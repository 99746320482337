import { BdsLoadingPage } from 'blip-ds/dist/blip-ds-react';
import MainLayout from 'components/MainLayout/MainLayout';
import { CONNECTION_CHOICE } from 'constants/MetaActivation';
import {
  CONNECTION_CHOICE_ROUTE,
  HOME_ROUTE,
  NUMBER_PREPARATION,
  PAYWALL_ROUTE,
  SIGNATURE_ROUTE,
} from 'constants/Routes';
import { Logger } from 'infra/adapters';
import { IUserInfo, PlanTypeEnum } from 'objects/types/BlipGoApi';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { useInstallation } from 'redux/slices/InstallationSlice/installationSlice';
import { useProject } from 'redux/slices/ProjectSlice/projectSlice';
import { getIsEmailRegistered } from 'services/BlipGoApiService';
import { formatLogMessage } from 'utils/LogMessage';

const WhatsAppActivation: React.FC = () => {
  const className = 'ExperienceCluster';
  const navigate = useNavigate();

  const { planType, whatsAppActive } = useSelector(useProject);
  const { ownerIdentity } = useSelector(useInstallation);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    redirectUser();
  }, []);

  const validPlanBasic = (userInfo: IUserInfo) => {
    if (whatsAppActive) {
      navigate(SIGNATURE_ROUTE);
    } else if (!whatsAppActive && userInfo?.readyToMeta) {
      navigate(NUMBER_PREPARATION);
    } else {
      navigate(HOME_ROUTE);
    }
  };

  const validPlanPro = (userInfo: IUserInfo) => {
    if (!whatsAppActive && userInfo?.readyToMeta) {
      navigate(NUMBER_PREPARATION);
    } else if (
      userInfo.activation === CONNECTION_CHOICE.pending.key ||
      userInfo.activation === CONNECTION_CHOICE.self.key ||
      userInfo.activation === CONNECTION_CHOICE.assisted.key
    ) {
      navigate(CONNECTION_CHOICE_ROUTE, {
        state: { activation: userInfo.activation },
      });
    }
  };

  const redirectUser = async () => {
    const methodName = 'redirectUser';
    if (!ownerIdentity) {
      Logger.Error('User email not found', { methodName, className });
      return location.reload();
    }
    try {
      setIsLoading(true);
      const userInfo = await getIsEmailRegistered(ownerIdentity);

      if (planType === PlanTypeEnum.Pro) {
        validPlanPro(userInfo);
      } else if (planType === PlanTypeEnum.Basic) {
        validPlanBasic(userInfo);
      } else {
        navigate(PAYWALL_ROUTE);
      }
    } catch (error) {
      Logger.Error(formatLogMessage(error, `Experience Cluster api error: ${error}`), {
        methodName,
        className,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <MainLayout>
      {isLoading && <BdsLoadingPage />}
      <Outlet />
    </MainLayout>
  );
};

export default WhatsAppActivation;
