import { BdsGrid, BdsIcon, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import Button from 'components/Button';
import ChannelsName from 'constants/ChannelsName';
import { PlanTypeEnum } from 'objects/types/BlipGoApi';
import { Channel } from 'objects/types/Channel';
import { useSelector } from 'react-redux';
import { useProject } from 'redux/slices/ProjectSlice/projectSlice';
import { BlipChatContent, CardChannelContent, Connected, MessageConnected, TitlePlan } from './styles';

type CardChannelProps = {
  item: Channel;
  activeChannel: boolean;
  onConnect: (item: Channel) => void;
  onTest: (item: Channel) => void;
  onBlipChatDoc?: () => void;
};

const CardChanel: React.FC<CardChannelProps> = ({
  item,
  activeChannel,
  onConnect,
  onTest,
  onBlipChatDoc,
}: CardChannelProps) => {
  const { planType } = useSelector(useProject);
  const isBlipChatChannel = () => item.title.toLocaleLowerCase() === ChannelsName.Blipchat.toLocaleLowerCase();

  const BlipChatChannelButton = (
    <BdsGrid alignItems="center" direction="column" gap="1" padding="b-3">
      <BdsGrid direction="column" alignItems="center" gap="1">
        <MessageConnected>
          <BdsTypo variant="fs-12" bold="regular">
            O Blip Chat já vem ativo.
            <BlipChatContent onClick={onBlipChatDoc} data-testid="blip-chat-documentation-link">
              Entenda como usar no site
            </BlipChatContent>
          </BdsTypo>
        </MessageConnected>
      </BdsGrid>
      <Button text="Configurar" variant="tertiary" onClick={() => onConnect(item)} dataTestId="click-connect" />
      <Button text="Testar" variant="secondary" onClick={() => onTest(item)} dataTestId="click-test" />
    </BdsGrid>
  );

  const ChannelButton = (
    <div>
      {activeChannel ? (
        <BdsGrid direction="column" alignItems="center" padding="b-6" gap="1">
          <Connected>
            <BdsGrid height="25px" alignItems="center" justifyContent="center" gap="1">
              <BdsIcon theme="solid" name="checkball" size="small" />
              <BdsTypo variant="fs-12" bold="bold" margin={false} data-testid="text-connected">
                ativo
              </BdsTypo>
            </BdsGrid>
          </Connected>
          <MessageConnected>
            <BdsGrid height="44px" margin="t-4">
              <BdsTypo variant="fs-12" bold="semi-bold" margin={false} data-testid="text-informative-connected">
                Seu chatbot está ativo nesse canal.
              </BdsTypo>
            </BdsGrid>
          </MessageConnected>
        </BdsGrid>
      ) : (
        <BdsGrid margin="t-2" alignItems="center" direction="column" gap="1" padding="b-3">
          <Button text="Ativar canal" variant="tertiary" onClick={() => onConnect(item)} dataTestId="click-connect" />
          <Button text="Testar" variant="secondary" onClick={() => onTest(item)} dataTestId="click-test" />
        </BdsGrid>
      )}
    </div>
  );

  return (
    <CardChannelContent>
      <BdsGrid direction="column" alignItems="center" gap="half" padding="t-3">
        <img src={item.photo} alt={item.title} style={{ width: '80px', height: '80px' }} />
        <BdsGrid height="28px" alignItems="center">
          <BdsTypo variant="fs-20" bold="bold" margin={false}>
            {item.title}
          </BdsTypo>
        </BdsGrid>
        {!activeChannel && (
          <TitlePlan>
            <BdsGrid height="24px" alignItems="center" justifyContent="center">
              <BdsTypo variant="fs-12" bold="bold" margin={false}>
                {planType != PlanTypeEnum.Free &&
                item.title.toLocaleLowerCase() == ChannelsName.Whatsapp.toLocaleLowerCase()
                  ? 'Disponivel'
                  : item.textAvailability}
              </BdsTypo>
            </BdsGrid>
          </TitlePlan>
        )}
      </BdsGrid>
      {isBlipChatChannel() ? BlipChatChannelButton : ChannelButton}
    </CardChannelContent>
  );
};

export default CardChanel;
