import styled, { css } from 'styled-components';

interface MainContainerProps {
  $isEmojiSwitch: boolean;
}

export const MainContainer = styled.div<MainContainerProps>`
  display: flex;

  ${props =>
    props.$isEmojiSwitch
      ? css`
          height: 26px;
          padding-left: 16px;
        `
      : css`
          margin-right: auto;
        `}

  .typo {
    margin-left: 10px;
  }
`;
