import styled, { css } from 'styled-components';

interface MainContainerProps {
  $index: number;
  $showRemoveIcon: boolean;
}

export const MainContainer = styled.div<MainContainerProps>`
  display: flex;
  position: relative;

  ${props =>
    !props.$showRemoveIcon &&
    css`
      padding-right: 34px;

      @media screen and (max-width: 500px) {
        padding-top: 12px;
      }
    `}

  ${props =>
    props.$index !== 0 &&
    css`
      margin-top: 15px;
    `}

  ${props =>
    props.$index === 2 &&
    css`
      padding-right: 34px;

      .icon-remove > bds-icon {
        margin-top: 2px;
      }

      @media screen and (max-width: 500px) {
        padding-top: 12px;
      }
    `}

    ${props =>
    props.$index < 2 &&
    css`
      .icon-remove {
        left: 100%;
        top: 0;
      }
    `}

    .icon {
    cursor: pointer;
    color: #3f7de8;
    margin-left: 10px;
  }

  .tooltip {
    margin-top: 8px;
  }
`;

export const HoursContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;
  width: 250px;
`;
