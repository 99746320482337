import CardChannel from 'components/CardChannel/CardChannel';
import ChannelsName from 'constants/ChannelsName';
import ExternalUrls from 'constants/ExternalUrls';
import useChannelRedirects from 'hooks/useChannelRedirects';
import { Analytics } from 'infra/adapters';
import { FC, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useChannelsModule } from 'redux/slices/ChannelsModuleSlice/channelsModuleSlice';
import { useProject } from 'redux/slices/ProjectSlice/projectSlice';
import { Container } from './styles';
import { ProPlanModal } from 'components/ProPlanModal/ProPlanModal';
import { WPP_ACTIVATION } from 'constants/Routes';
import { useNavigate } from 'react-router-dom';
import { useContractInfo } from 'redux/slices/ContractInfoSlice/contractInfoSlice';

const Channelshelf: FC = () => {
  const { channels } = useSelector(useChannelsModule);
  const { redirectToConnect, redirectToTest } = useChannelRedirects();
  const { whatsAppActive, instagramActive, messengerActive, activeModule } = useSelector(useProject);
  const { signedContract } = useSelector(useContractInfo);
  const navigate = useNavigate();
  const modalRef = useRef<null | HTMLBdsModalElement>(null);

  useEffect(() => {
    Analytics.Track(Analytics.events.PG_OPEN_CHANNELS);
  }, []);

  const redirectBlipChatDocumentation = () => {
    Analytics.Track(Analytics.events.CHANNELS_BLIP_CHAT_CONFIGURED);
    window.open(ExternalUrls.addBlipChatInWebsite);
  };

  const goToWppActivation = () => {
    if (signedContract) {
      navigate(WPP_ACTIVATION);
    } else {
      if (modalRef?.current) {
        Analytics.Track(Analytics.events.CLICK_CONNECTED_CHANNEL_BUTTON, {
          channel: 'WhatsApp',
          screen: activeModule.title,
        });
        modalRef?.current?.toggle?.();
      }
    }
  };

  return (
    <Container>
      <ProPlanModal modalRef={modalRef} />
      {channels.map(item => {
        return (
          <div key={item.title}>
            {item.title.toLocaleLowerCase() == ChannelsName.Whatsapp.toLocaleLowerCase() && (
              <CardChannel
                item={item}
                activeChannel={whatsAppActive}
                onConnect={goToWppActivation}
                onTest={redirectToTest}
              />
            )}
            {item.title.toLocaleLowerCase() == ChannelsName.Instagram.toLocaleLowerCase() && (
              <CardChannel
                item={item}
                activeChannel={instagramActive}
                onConnect={redirectToConnect}
                onTest={redirectToTest}
              />
            )}
            {item.title.toLocaleLowerCase() == ChannelsName.Messenger.toLocaleLowerCase() && (
              <CardChannel
                item={item}
                activeChannel={messengerActive}
                onConnect={redirectToConnect}
                onTest={redirectToTest}
              />
            )}
            {item.title.toLocaleLowerCase() == ChannelsName.Blipchat.toLocaleLowerCase() && (
              <CardChannel
                item={item}
                activeChannel={true}
                onConnect={redirectToConnect}
                onTest={redirectToTest}
                onBlipChatDoc={redirectBlipChatDocumentation}
              />
            )}
          </div>
        );
      })}
    </Container>
  );
};

export default Channelshelf;
