import React, { useState } from 'react';
import './style.scss';
import { BdsGrid, BdsIcon, BdsTypo } from 'blip-ds/dist/blip-ds-react';

interface NotificationBannerProps {
  message: string;
}

const NotificationBanner: React.FC<NotificationBannerProps> = ({ message }) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <BdsGrid className="notification-banner">
      <BdsGrid className="notification-content">
        <BdsIcon size="small" name="attention" data-testid="attention-icon"></BdsIcon>
        <BdsTypo variant="fs-12" className="notification-message">
          {message}
        </BdsTypo>
      </BdsGrid>
      <BdsIcon size="small" name="close" onClick={handleClose} data-testid="close-icon"></BdsIcon>
    </BdsGrid>
  );
};

export default NotificationBanner;
