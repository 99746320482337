import { BdsButton, BdsChipTag, BdsGrid, BdsIcon, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { EXTENSIONS_ROUTE } from 'constants/Routes';
import { Analytics } from 'infra/adapters';
import { useNavigate } from 'react-router-dom';
import YouTube from 'react-youtube';

function DeskAddons() {
  const navigate = useNavigate();

  return (
    <BdsGrid direction="column" padding="6" gap="4">
      <BdsButton variant="secondary" icon="arrow-left" onBdsClick={() => navigate(EXTENSIONS_ROUTE)}>
        Voltar
      </BdsButton>
      <BdsGrid justifyContent="space-between" padding="4">
        <BdsGrid direction="column" lg="6">
          <BdsGrid alignItems="center" justifyContent="space-between" margin="b-2">
            <div>
              <BdsTypo variant="fs-24" bold="bold">
                Desk Addons
              </BdsTypo>
              <BdsChipTag color="outline">Extensão web</BdsChipTag>
            </div>
            <BdsButton
              onBdsClick={() => {
                Analytics.Track(Analytics.events.EXTENSIONS_ADDON_BUTTON);
                window.open(
                  'https://chromewebstore.google.com/detail/desk-addons/hohapgjceacjlkmedbegdobcomhjimcg?hl=pt-BR',
                  '_blank',
                );
              }}
            >
              Acessar extensão
            </BdsButton>
          </BdsGrid>
          <BdsTypo variant="fs-14">
            Desk Addons é uma extensão para o seu navegador que oferece recursos avançados de pesquisa de tickets e
            acesso ao histórico de mensagens, na tela de Conversas do Blip Go!
          </BdsTypo>
          <br />
          <BdsTypo bold="bold">Como utilizar:</BdsTypo>
          <br />
          <ul>
            <li>
              <BdsTypo variant="fs-14">
                Clique em [Acessar extensão] para ser redirecionado(a) e fazer a instalação no seu navegador.
              </BdsTypo>
            </li>
            <li>
              <BdsTypo variant="fs-14">
                Após isso, em Conversas <BdsIcon name="message-talk" /> estarão disponíveis no canto superior direito os
                recursos de Busca e Histórico.
              </BdsTypo>
            </li>
            <li>
              <BdsTypo variant="fs-14">
                Clique em <BdsIcon name="clock" /> e acesse o histórico dos tickets já atendidos por sua equipe
              </BdsTypo>
            </li>
            <li>
              <BdsTypo variant="fs-14">
                Clique em <BdsIcon name="search" /> para buscar conversas por nome e número
              </BdsTypo>
            </li>
            <li>
              <BdsTypo variant="fs-14">
                Exporte conversas clicando em <BdsIcon name="download" />
              </BdsTypo>
            </li>
          </ul>
        </BdsGrid>
        <YouTube videoId="gZ7h3ltTh-s" />
      </BdsGrid>
      <div>
        <BdsTypo bold="bold">Dados extras:</BdsTypo>
        <br />
        <BdsTypo variant="fs-14">
          <b>Suporte: </b>
          <a href="mailto:support.tech@blip.ai" target="_blank" rel="noreferrer">
            support.tech@blip.ai
          </a>
        </BdsTypo>
        <BdsTypo variant="fs-14">
          <b>Versão:</b> 1.0.0
        </BdsTypo>
      </div>
    </BdsGrid>
  );
}

export default DeskAddons;
