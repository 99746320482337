import { BdsLoadingPage } from 'blip-ds/dist/blip-ds-react';
import FloatingButton from 'components/FloatingButton';
import { DEFAULT_INSTALLATION_ID } from 'constants/Installation';
import { DESK_ROUTE, HOME_ROUTE, INTENTION_PLAN_PRO } from 'constants/Routes';
import { GlobalStyles } from 'globalStyles';
import useActiveModuleManager from 'hooks/useActiveModuleManager';
import { Analytics } from 'infra/adapters';
import { useAuth } from 'oidc-react';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { useInstallation } from 'redux/slices/InstallationSlice/installationSlice';
import { AppCuesService } from 'services/AppCues';
import blipChatImage from './../../assets/BlipChatIcon.png';
import useMainRoute from './hooks/useMainRoute';

const MainRoute: React.FC = () => {
  useActiveModuleManager();
  const auth = useAuth();
  const navigate = useNavigate();

  const { installationId } = useSelector(useInstallation);
  const { initHook, isApplicationReady, goTeamWhatsapp, isIntentProPlan, setFirstAccess } = useMainRoute();

  const isReadyToRender = auth.userData && isApplicationReady;

  useEffect(() => {
    if (auth.userData) {
      initHook();
      Analytics.Identify(auth.userData?.profile?.email);

      AppCuesService.instance.identify(auth.userData.profile.email, {
        name: auth.userData.profile.name,
        email: auth.userData.profile.email,
        createdAt: new Date(),
      });
      setTimeout(() => AppCuesService.instance.start(), 1000);
      Analytics.Track(Analytics.events.OPEN_LITE_PLATAFORM);
    }
  }, [auth.userData]);

  useEffect(() => {
    if (isApplicationReady) {
      const redirectMainPage = isIntentProPlan() ? INTENTION_PLAN_PRO : HOME_ROUTE;
      const defaultRoute = installationId === DEFAULT_INSTALLATION_ID ? DESK_ROUTE : redirectMainPage;
      setFirstAccess('false');
      navigate(defaultRoute);
    }
  }, [isApplicationReady]);

  return isReadyToRender ? (
    <>
      <GlobalStyles />
      <Outlet />
      <FloatingButton
        fBtnImage={blipChatImage}
        tooltip={'Solicitar ajuda'}
        onClick={goTeamWhatsapp}
        dataTestId="fbtn-support"
      />
    </>
  ) : (
    <BdsLoadingPage />
  );
};

export default MainRoute;
