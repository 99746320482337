export const PACK_INSTALLATIONS_ROUTE = 'v1/packs/installation';
export const INSTALLATION_ROUTE = 'v1/installations';
export const INSTALLATION_SHORTNAME_ROUTE = `${INSTALLATION_ROUTE}/router-shortname`;
export const LAST_INSTALLATION_ROUTE = `${INSTALLATION_ROUTE}/last`;
export const BLIP_CHAT_CONFIGURATION_ROUTE = 'v1/configurations/blip-chat';
export const UPDATE_CONTACT_STATUS_HUBSPOT = 'v1/hubspot/contact';
export const ACTIVATE_NUMBER_WHATSAPP = 'v1/whatsapp/activate-number';
export const HEALTH_CHECK = '/healthcheck';
export const RESOURCES_ROUTE = 'v1/resources';
export const ADD_USERS_TENTANT = 'v1/tenants/user';
export const ACTIVATE_INSTAGRAM = 'v1/instagram/activate-instagram';
export const GET_FACEBOOK_APP_INFORMATION = 'v1/meta/facebook-app-information';
export const GET_INSTALLATION_METRICS = 'v1/metrics/installation';
export const GET_INSTALLATIONS_BY_MEMBER_ROUTE = 'v1/packs/authenticated-user';
export const MEMBER_ROUTE = 'v1/members/pack';
export const MEMBER_ASSOCIATE_ROUTE = 'installations/associate';
export const MEMBER_DISASSOCIATE_ROUTE = 'installations/disassociate';
