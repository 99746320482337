import { JSX as LocalJSX, defineCustomElements } from 'blip-ds/loader';
import { DetailedHTMLProps, HTMLAttributes, StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import GoogleTagManager from 'services/GoogleTagManager/GoogleTagManager';
import App, { HealthCheck } from './App';
import './index.css';
import store from './redux/store';

type StencilToReactElements<T = LocalJSX.IntrinsicElements> = {
  [P in keyof T]?: T[P] &
    Omit<DetailedHTMLProps<HTMLAttributes<T[P]>, T[P]>, 'className'> & {
      class?: string;
    };
};

type StencilToReactRef<T = HTMLElementTagNameMap> = {
  [P in keyof T]: {
    ref?: DetailedHTMLProps<HTMLAttributes<T[P]>, T[P]>['ref'];
  };
};

type StencilToReact<T = LocalJSX.IntrinsicElements, U = HTMLElementTagNameMap> = StencilToReactElements<T> &
  StencilToReactRef<U>;

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  export namespace JSX {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface IntrinsicElements extends StencilToReact {}
  }
}
defineCustomElements(window);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <HealthCheck />
    <Provider store={store}>
      <GoogleTagManager />
      <App />
    </Provider>
  </StrictMode>,
);
