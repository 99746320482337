import { BdsGrid, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { CONNECTION_CHOICE } from 'constants/MetaActivation';
import Hourglass from 'assets/Hourglass.svg';
import { useLocation } from 'react-router-dom';

export const SuccessfulSolicitation = () => {
  const location = useLocation();
  const { activation } = location.state;

  return (
    <BdsGrid
      direction="column"
      gap="3"
      lg="6"
      md="5"
      alignItems="center"
      padding="t-6"
      style={{ textAlign: 'center', margin: 'auto' }}
    >
      <img src={Hourglass} alt="" />
      <BdsTypo variant="fs-32" margin={false} bold="bold">
        Solicitação enviada com sucesso! 🎉
      </BdsTypo>
      <BdsTypo variant="fs-16">
        {activation === CONNECTION_CHOICE.assisted.key
          ? CONNECTION_CHOICE.assisted.helpText
          : CONNECTION_CHOICE.self.helpText}
      </BdsTypo>
    </BdsGrid>
  );
};
