import { BlipChat } from 'blip-chat-widget';
import { BlipChatPrefix } from 'configurations/Environment';
import { Simulator } from 'constants/Simulator';
import { v4 as uuidv4 } from 'uuid';
import { setContactSimulator } from './CommandsService';

let blipClient = new BlipChat();

export const mountSimulateBlipChat = (
  tenantId: string,
  appKeyBlipChat: string,
  nameLoggedUser: string,
  routerShortName: string,
  key: string,
) => {
  const customStyle = `
  #blip-chat-iframe {
    border-radius: 15px;
  }
  #app {
    border-radius: 15px;
  }
  #message-input {
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 0px 0px 15px 15px;
  }
  #blip-chat-header {
    border-radius: 15px 15px 0px 0px;
  }
  #message-input textarea {
    background: #FFFFFF;
    font-size: 12px;
  }`;

  const guidIdentity = uuidv4();
  const identity = `${guidIdentity}.${routerShortName}@0mn.io`;

  blipClient = new BlipChat();
  blipClient
    .withAppKey(appKeyBlipChat)
    .withTarget(Simulator.DIV_NAME_SIMULATOR)
    .withCustomCommonUrl(`https://${tenantId}.${BlipChatPrefix}`)
    .withAuth({
      authType: BlipChat.DEV_AUTH,
      userIdentity: guidIdentity,
      userPassword: 'PackMangerBlipGOSimulator',
    })
    .withAccount({
      fullName: nameLoggedUser,
      extras: {
        isTestersGroup: 'True',
        typeOfCompile: 'flow',
      },
      source: '0mn.io',
    })
    .withCustomStyle(customStyle)
    .withEventHandler(BlipChat.CREATE_ACCOUNT_EVENT, function () {
      setContactSimulator(key, {
        group: 'Testers',
        identity: identity,
        name: nameLoggedUser,
        source: '0mn.io',
        extras: {
          isTestersGroup: true,
          typeOfCompile: ' flow',
        },
      });
    })
    .withEventHandler(BlipChat.LOAD_EVENT, function () {
      blipClient.sendMessage({
        type: 'text/plain',
        content: 'Olá!',
      });
    })
    .build();
};

export const DestroyBlipChatTest = () => {
  blipClient.destroy();
};
