import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ApplicationVersion } from 'configurations/Environment';
import { PlanTypeEnum } from 'objects/types/BlipGoApi';
import { Module } from 'objects/types/Module';
import { SubPage } from 'objects/types/SubPage';
import { IProjectState } from './interface';

const InitialState: IProjectState = {
  version: ApplicationVersion,
  loggedUser: '',
  isMenuOpen: true,
  isSubPageActive: false,
  activeModule: {
    icon: '',
    route: '',
    title: '',
    tooltip: '',
    subPages: [],
  } as Module,
  activeSubPage: {
    route: '',
    title: '',
  } as SubPage,
  loading: false,
  whatsAppActive: false,
  instagramActive: false,
  messengerActive: false,
};

const projectSlice = createSlice({
  name: 'project',
  initialState: InitialState,
  reducers: {
    addLoggedUser(state, { payload }: PayloadAction<string>) {
      return { ...state, loggedUser: payload };
    },
    addActiveModule(state, { payload }: PayloadAction<Module>) {
      return { ...state, activeModule: payload };
    },
    updateIsMenuOpen(state, { payload }: PayloadAction<boolean>) {
      return { ...state, isMenuOpen: payload };
    },
    addActiveSubPage(state, { payload }: PayloadAction<SubPage>) {
      return { ...state, activeSubPage: payload };
    },
    updateIsSubMenuOpen(state, { payload }: PayloadAction<boolean>) {
      return { ...state, isSubPageActive: payload };
    },
    updateWhatsAppActive(state, { payload }: PayloadAction<boolean>) {
      return { ...state, whatsAppActive: payload };
    },
    updateMessengerActive(state, { payload }: PayloadAction<boolean>) {
      return { ...state, messengerActive: payload };
    },
    updateInstagramActive(state, { payload }: PayloadAction<boolean>) {
      return { ...state, instagramActive: payload };
    },
    updatePlanType(state, { payload }: PayloadAction<PlanTypeEnum>) {
      return { ...state, planType: payload };
    },
  },
});

export default projectSlice.reducer;

export const {
  addLoggedUser,
  addActiveModule,
  updateIsMenuOpen,
  addActiveSubPage,
  updateIsSubMenuOpen,
  updateWhatsAppActive,
  updateMessengerActive,
  updateInstagramActive,
  updatePlanType,
} = projectSlice.actions;

export const useProject = (state: { project: IProjectState }) => state.project;
