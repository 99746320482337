import { SubPage } from 'objects/types/SubPage';
import {
  ATTENDANCE_QUEUES_CONFIGURATIONS_ROUTE,
  GREETING_CONFIGURATIONS_ROUTE,
  MEMBERS_CONFIGURATIONS_ROUTE,
  MENU_AUTOMATIC_RESPONSES_CONFIGURATIONS_ROUTE,
  OPENING_HOURS_CONFIGURATIONS_ROUTE,
  PERMISSIONS_CONFIGURATIONS_ROUTE,
} from './Routes';
import { SubModuleNames } from './SubModuleNames';

const SubPages = {
  configurations: [
    {
      title: SubModuleNames.greeting,
      route: GREETING_CONFIGURATIONS_ROUTE,
    },
    {
      title: SubModuleNames.automaticResponses,
      route: MENU_AUTOMATIC_RESPONSES_CONFIGURATIONS_ROUTE,
    },
    {
      title: SubModuleNames.members,
      route: MEMBERS_CONFIGURATIONS_ROUTE,
    },
    {
      title: SubModuleNames.permissions,
      route: PERMISSIONS_CONFIGURATIONS_ROUTE,
    },
    {
      title: SubModuleNames.attendanceQueues,
      route: ATTENDANCE_QUEUES_CONFIGURATIONS_ROUTE,
    },
    {
      title: SubModuleNames.openingHours,
      route: OPENING_HOURS_CONFIGURATIONS_ROUTE,
    },
  ] as SubPage[],
};

export default SubPages;
