import { parse, format, isValid } from 'date-fns';
import { Off, WeekDay } from 'objects/types/humamService/AttendanceHour';

export const validateDates = (dates: string) => {
  let isValid = true;

  const dateRegex = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))$/i;

  const datesList = dates.split(',');
  if (datesList.length === 0) {
    return false;
  }
  datesList.forEach(date => {
    isValid = isValid && !(date.match(dateRegex) === null);
  });

  return isValid;
};

export const formatListDates = (chips: string) => {
  const dateRegex = /([0-3]{1}[0-9]{1})\/([01]{1}[0-9]{1})/g;
  const result = (chips?.toString().match(dateRegex)?.toString() as string) || undefined;

  return result;
};

export const formatDateToDDMMYY = (date: Date): string => {
  return format(date, 'dd/MM/yy');
};

export const fillWeekDaysWithDefaultShift = (weekDays: WeekDay[]): WeekDay[] => {
  return weekDays.map(day => {
    if (day.shifts.length === 0) {
      return { ...day, shifts: [{ startTime: '', endTime: '' }] };
    }
    return day;
  });
};

export const extractAndFormatChips = (offs: Off[]): string[] => {
  return offs
    .map(holiday => {
      const parsedDate = parse(holiday.startDate, 'yyyy-MM-dd', new Date());
      return isValid(parsedDate) ? formatDateToDDMMYY(parsedDate) : null;
    })
    .filter((dateStr): dateStr is string => dateStr !== null);
};
