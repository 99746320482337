import { BdsButton, BdsGrid } from 'blip-ds/dist/blip-ds-react/components';
import DesktopSidebar from 'components/DesktopSidebar/DesktopSidebar';
import Header from 'components/Header/Header';
import { HOME_ROUTE, INSTAGRAM_ACTIVATION_FACEBOOK_CONNECTION } from 'constants/Routes';
import { Analytics } from 'infra/adapters';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { useProject } from 'redux/slices/ProjectSlice/projectSlice';
import { ContentHorizontalOffset } from './style';

function InstagramActivation(): JSX.Element {
  const { isMenuOpen } = useSelector(useProject);
  const navigate = useNavigate();

  useEffect(() => {
    navigate(INSTAGRAM_ACTIVATION_FACEBOOK_CONNECTION);
  }, []);

  const handleReturnButton = () => {
    Analytics.Track(Analytics.events.INSTAGRAM_ACTIVATION_RETURN_BUTTON);
    const currentRoute = window.location.pathname;
    if (currentRoute === INSTAGRAM_ACTIVATION_FACEBOOK_CONNECTION) {
      return navigate(HOME_ROUTE);
    }
    navigate(-1);
  };

  return (
    <>
      <DesktopSidebar />
      <ContentHorizontalOffset $isMenuOpen={isMenuOpen}>
        <Header title="Conectar Instagram" />
        <BdsGrid direction="column">
          <BdsGrid justifyContent="flex-end">
            <BdsButton variant="secondary" onBdsClick={handleReturnButton}>
              Voltar
            </BdsButton>
          </BdsGrid>
          <Outlet />
          <br />
        </BdsGrid>
      </ContentHorizontalOffset>
    </>
  );
}

export default InstagramActivation;
