import DesktopSidebar from 'components/DesktopSidebar/DesktopSidebar';
import Header from 'components/Header/Header';
import { ContentHorizontalOffset } from 'pages/InstagramActivation/style';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useProject } from 'redux/slices/ProjectSlice/projectSlice';

function Extensions() {
  const { isMenuOpen } = useSelector(useProject);

  return (
    <>
      <DesktopSidebar />
      <ContentHorizontalOffset $isMenuOpen={isMenuOpen}>
        <Header title="Extensões e Aplicativos" />
        <Outlet />
      </ContentHorizontalOffset>
    </>
  );
}

export default Extensions;
