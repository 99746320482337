export const ROUTE_COMMANDS = '/commands';

export const POSTMASTER = 'postmaster';
export const MSGING_NET = 'msging.net';

export const DESK_MSGING_NET = 'desk.msging.net';
export const WA_GW_MSGING_NET = 'wa.gw.msging.net';

export const GET_METHOD = 'get';
export const DELETE_METHOD = 'delete';
export const ADD_OR_UPDATE_METHOD = 'set';
export const MERGE_METHOD = 'merge';

export const RULES_ROUTE = '/rules';
export const PROFILE_ROUTE = '/profile';
export const RESOURCES_ROUTE = '/resources';
export const ATTENDANTS_ROUTE = '/attendants';
export const ATTENDANCE_QUEUES_ROUTE = '/attendance-queues';
export const VALIDATE_PAGE_TOKEN_ROUTE = '/validate-page-token';
export const CONTACTS_ROUTE = '/contacts';

export const APPLICATION_IRIS_DESK_RULE_JSON = 'application/vnd.iris.desk.rule+json';
export const APPLICATION_IRIS_DESK_ATTENDANT_JSON = 'application/vnd.iris.desk.attendant+json';
export const APPLICATION_IRIS_DESK_ATTENDANCEQUEUE_JSON = 'application/vnd.iris.desk.attendancequeue+json';
export const APPLICATION_IRIS_CONTACT_JSON = 'application/vnd.lime.contact+json';
