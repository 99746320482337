import NavigationBars from 'components/NavigationBars/NavigationBars';
import { GlobalStyles } from 'globalStyles';
import React from 'react';
import { useSelector } from 'react-redux';
import { useInstallation } from 'redux/slices/InstallationSlice/installationSlice';

interface IMainLayout {
  children: React.ReactNode;
}

const MainLayout: React.FC<IMainLayout> = ({ children }) => {
  const { applicationId } = useSelector(useInstallation);

  return (
    <>
      <GlobalStyles />
      {applicationId >= 0 && <NavigationBars>{children}</NavigationBars>}
    </>
  );
};

export default MainLayout;
