import { LARGE_MOBILE_SCREEN } from 'constants/MediaQueryResolutions';
import styled from 'styled-components';

export const ReviewInfoContainer = styled.div`
  display: grid;
  gap: 16px;
  width: 620px;
  padding-bottom: 80px;

  @media screen and (max-width: ${LARGE_MOBILE_SCREEN}) {
    width: auto;
    margin: 5px;
  }
`;
