import styled from 'styled-components';

interface Props {
  $isSubPageActive: boolean;
}

export const SubMenuContainer = styled.div`
  display: inline-flex;
  height: 100dvh;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex-shrink: 0;
  background: #e0e0e0;
  width: 230px;
`;

export const ItemsSection = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Item = styled.div<Props>`
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  cursor: pointer;

  ${({ $isSubPageActive }) =>
    $isSubPageActive
      ? 'border-radius: 8px; background: #F6F6F6; opacity: 0.9;'
      : `
            &:hover {
                border-radius: 8px; 
                background: #F6F6F6; 
                opacity: 0.5;
            }
        `}
`;
