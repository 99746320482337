import { BdsAvatar, BdsButton, BdsChipTag, BdsGrid, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import useGraphApi from 'hooks/useGraphApi';
import { Analytics } from 'infra/adapters';
import { User } from 'objects/types/Facebook';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from './style';

type FacebookConnectedAccountProps = {
  user: User;
  token: string;
  hasFacebookPages: boolean;
};

function FacebookConectedAccount({ user, token, hasFacebookPages }: FacebookConnectedAccountProps) {
  const [loading, setLoading] = useState(false);
  const { logoutFacebook } = useGraphApi();
  const navigate = useNavigate();

  const changeAccountHandler = async () => {
    setLoading(true);
    await logoutFacebook(user.id, token);
    setLoading(false);
    return navigate(-1);
  };

  return (
    <Container>
      <BdsGrid gap="2" direction="column" padding="2">
        <BdsGrid direction="column" alignItems="center">
          <BdsChipTag color="outline" icon="facebook">
            Conta conectada:
          </BdsChipTag>
        </BdsGrid>
        <BdsGrid direction="column" gap="1" alignItems="center">
          <BdsAvatar id="avatar" name={user.name} size="extra-large" />
          <BdsTypo variant="fs-16" bold="bold">
            {user.name}
          </BdsTypo>
        </BdsGrid>
        {hasFacebookPages && (
          <BdsGrid direction="column" margin="t-2" alignItems="center">
            <BdsButton
              variant="secondary"
              bdsLoading={loading}
              onBdsClick={() => {
                Analytics.Track(Analytics.events.INSTAGRAM_ACTIVATION_CHANGE_ACCOUNT_BUTTON);
                changeAccountHandler();
              }}
            >
              Trocar conta
            </BdsButton>
          </BdsGrid>
        )}
      </BdsGrid>
    </Container>
  );
}

export default FacebookConectedAccount;
