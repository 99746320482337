import React, { useEffect, useState } from 'react';
import ChatList from './components/ChatList';
import ChatHistory from './components/ChatHistory';
import ContactDetails from './components/ContactDetails';
import MainLayout from 'components/MainLayout/MainLayout';
import { useSelector } from 'react-redux';
import { useInstallation } from 'redux/slices/InstallationSlice/installationSlice';
import { BdsGrid, BdsLoadingPage } from 'blip-ds/dist/blip-ds-react';
import { LoadingContainerChannels } from 'pages/Channels/styles';
import { getContacts } from 'services/BlipGoApiService';
import { ContactsDto, ContactItemDto } from 'objects/types/ContactsResponse';
import './style.scss';
import ChatIconPage from './components/ChatIconPage/ChatIconPage';

const Contacts: React.FC = () => {
  const [contacts, setContacts] = useState<ContactsDto>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedContact, setSelectedContact] = useState<ContactItemDto | null>(null);
  const { tenant, router } = useSelector(useInstallation);

  async function fetchContacts() {
    const contactsResponse: ContactsDto = await getContacts(tenant.id, router.shortName);
    if (contactsResponse) {
      const sortedContacts = contactsResponse.itens.sort((a, b) => {
        const dateA = new Date(a.lastMessageDate || 0);
        const dateB = new Date(b.lastMessageDate || 0);
        return dateB.getTime() - dateA.getTime();
      });

      setContacts({ ...contactsResponse, itens: sortedContacts });
    }
    setIsLoading(false);
  }

  const handleSelectContact = (contact: ContactItemDto) => {
    setSelectedContact(contact);
  };

  useEffect(() => {
    fetchContacts();
  }, [tenant.id, router.shortName]);

  if (isLoading) {
    return (
      <MainLayout>
        <LoadingContainerChannels>
          <BdsLoadingPage />
        </LoadingContainerChannels>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <BdsGrid className="container-contacts" padding="1">
        <BdsGrid md="3" lg="2" className="chat-list-container">
          <ChatList contacts={contacts ? contacts.itens : []} onSelectContact={handleSelectContact} />
        </BdsGrid>
        {selectedContact ? (
          <BdsGrid md="6" lg="8" direction="column">
            <ChatHistory contactIdentity={selectedContact.identity} />
          </BdsGrid>
        ) : (
          <BdsGrid justifyContent="center" lg="8" md="6" alignItems="center">
            <ChatIconPage existContacts={contacts ? contacts.itens.length > 0 : false} />
          </BdsGrid>
        )}
        <BdsGrid md="3" lg="2" justifyContent="center">
          <ContactDetails contact={selectedContact} />
        </BdsGrid>
      </BdsGrid>
    </MainLayout>
  );
};

export default Contacts;
