import { BdsButton } from 'blip-ds/dist/blip-ds-react';
import InputText from 'components/InputText';
import Select from 'components/Select';
import { CONTRACT_ERROR, PROCURATOR_POSITION } from 'constants/MetaActivation';
import { Analytics, Logger } from 'infra/adapters';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateStateAgentEmail,
  updateStateAgentName,
  updateStateAgentPosition,
  useContractInfo,
} from 'redux/slices/ContractInfoSlice/contractInfoSlice';
import { AppDispatch } from 'redux/store';
import { AgentInfoContainer, NavigationBtnContainer } from './styles';

interface IProps {
  nextPageEvent: () => void;
  previousPageEvent: () => void;
}

export const AgentInfo = ({ nextPageEvent, previousPageEvent }: IProps) => {
  const className = 'AgentInfo';
  const dispatch = useDispatch<AppDispatch>();

  const { agentName, agentEmail, agentPosition } = useSelector(useContractInfo);

  useEffect(() => {
    if (agentPosition.value === '') {
      dispatch(updateStateAgentPosition({ ...agentPosition, value: 'Analista' }));
    }
  }, []);

  const handleAgentPositionChange = (value: string) => {
    dispatch(updateStateAgentPosition({ ...agentPosition, value }));
  };

  const handleAgentEmailChange = (value: string) => {
    dispatch(updateStateAgentEmail({ ...agentEmail, value }));
  };

  const handleAgentNameChange = (value: string) => {
    if (value) {
      dispatch(updateStateAgentName({ ...agentName, value, isValid: true }));
    }
  };

  const isFormValid = () => {
    const isFormEmpty = agentPosition.isEmpty || agentEmail.isEmpty || agentName.isEmpty;
    const isFormValid = agentName.isValid && agentEmail.isValid && agentPosition.isValid;
    return !isFormEmpty && isFormValid;
  };

  const handleNextBtnClick = () => {
    const methodName = 'handleNextBtnClick';

    if (isFormValid()) {
      Logger.Info('user successfully fullfiled Agent  info form', { methodName, className });
      Analytics.Track(Analytics.events.CONTRACT_COMPANY_REPRESENTATIVE_INFO, {
        representativeName: agentName.value,
        representativeEmail: agentEmail.value,
        representativePosition: agentPosition.value,
      });
      nextPageEvent();
    } else {
      if (agentEmail.isEmpty) {
        dispatch(updateStateAgentEmail({ ...agentEmail, isValid: false }));
      }
      if (agentName.isEmpty) {
        dispatch(updateStateAgentName({ ...agentName, isValid: false }));
      }
      if (agentPosition.isEmpty) {
        dispatch(updateStateAgentPosition({ ...agentPosition, isValid: false }));
      }
      Logger.Info('agent info form fields are invalid', { methodName, className });
    }
  };

  const handlePreviousBtnClick = () => {
    Analytics.Track(Analytics.events.CONTRACT_PLAN_RETURN, {
      screenName: 'Dados do representante',
    });
    previousPageEvent();
  };

  return (
    <AgentInfoContainer>
      <InputText
        required={true}
        requiredErrorMessage={CONTRACT_ERROR.emptyField}
        label="Nome completo"
        value={agentName?.value}
        errorMessage={agentName?.errorMessage}
        danger={!agentName?.isValid}
        dataTestId="agent-name-input"
        onChange={(ev: any) => handleAgentNameChange(ev?.target?.value)}
      ></InputText>
      <InputText
        required={true}
        requiredErrorMessage={CONTRACT_ERROR.emptyField}
        label="E-mail corporativo"
        value={agentEmail?.value}
        errorMessage={agentEmail?.errorMessage}
        danger={!agentEmail?.isValid}
        dataTestId="agent-email-input"
        onChange={(ev: any) => handleAgentEmailChange(ev?.target?.value)}
      ></InputText>
      <Select
        value={agentPosition.value}
        options={PROCURATOR_POSITION}
        onChange={(ev: CustomEvent) => handleAgentPositionChange(ev.detail.value)}
        danger={!agentPosition?.isValid}
        label="Cargo atual"
        dataTestId="agent-positions-select"
        optionsDataTestId="agent-positions-select-options"
        errorMessage={agentPosition?.errorMessage}
      />
      <NavigationBtnContainer>
        <BdsButton arrow={true} onClick={handleNextBtnClick} data-testid="company-info-next-btn">
          Próximo
        </BdsButton>
        <BdsButton
          icon="arrow-left"
          variant="ghost"
          onClick={handlePreviousBtnClick}
          data-testid="company-info-previous-btn"
        >
          Anterior
        </BdsButton>
      </NavigationBtnContainer>
    </AgentInfoContainer>
  );
};
