import LockOfferBanner from 'assets/LockOfferBanner.svg';
import { BdsButton, BdsGrid, BdsTypo } from 'blip-ds/dist/blip-ds-react';

interface IOfferBanner {
  title: string;
  textContent: string;
  textButton: string;
  actionButton: () => void;
}

export const OfferBanner = ({ title, textContent, textButton, actionButton }: IOfferBanner) => {
  return (
    <BdsGrid
      direction="row"
      justify-content="center"
      gap="4"
      lg="12"
      style={{
        height: '11.75rem',
        backgroundColor: '#FFFFFF',
        borderRadius: '0.75rem',
        padding: '24px 36px 28px',
      }}
    >
      <BdsGrid>
        <img src={LockOfferBanner} alt="" style={{ width: '5.25rem', height: '5.25rem' }} />
      </BdsGrid>
      <BdsGrid gap="2" direction="column" justifyContent="center" style={{ height: '8.5rem' }}>
        <BdsTypo variant="fs-20" margin={false} bold="bold">
          {title}
        </BdsTypo>
        <BdsTypo variant="fs-14">{textContent}</BdsTypo>
        <BdsGrid direction="column" justifyContent="flex-start">
          <BdsButton arrow={true} onBdsClick={actionButton} data-testid="offer-banner-btn">
            {textButton}
          </BdsButton>
        </BdsGrid>
      </BdsGrid>
    </BdsGrid>
  );
};
