import AllowMessages from 'assets/facebook-allow-messages-access.svg';
import BindedAccounts from 'assets/facebook-binded-accounts.svg';
import FbConfigDisabled from 'assets/facebook-config-disabled.svg';
import FbConfigEnabled from 'assets/facebook-config-enabled.svg';
import ConfirmBind from 'assets/facebook-confirm-bind.svg';
import ConnectAccount from 'assets/facebook-connect-account.svg';
import SwitchAccounts from 'assets/facebook-switch-accounts.svg';
import {
  BdsButton,
  BdsGrid,
  BdsIllustration,
  BdsModal,
  BdsStep,
  BdsStepper,
  BdsTypo,
} from 'blip-ds/dist/blip-ds-react';
import {
  HOME_ROUTE,
  INSTAGRAM_ACTIVATION_FACEBOOK_CONNECTION,
  INSTAGRAM_ACTIVATION_MESSAGES_ACCESS,
} from 'constants/Routes';
import { Paper } from 'globalStyles';
import useGraphApi from 'hooks/useGraphApi';
import { Analytics } from 'infra/adapters';
import { FacebookPage, InstagramActivationErrors, User } from 'objects/types/Facebook';
import { useAuth } from 'oidc-react';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useInstallation } from 'redux/slices/InstallationSlice/installationSlice';
import { updateInstagramActive } from 'redux/slices/ProjectSlice/projectSlice';
import { ActivateInstagram } from 'services/PackService';
import { createToastError } from 'services/Toats';

function BindPagesChecklist() {
  const [isLoading, setIsLoading] = useState(false);
  const locationState = useLocation()?.state as { page: FacebookPage; user: User };
  const { router } = useSelector(useInstallation);
  const navigate = useNavigate();
  const { validateInstagramPageAndReturnDetails } = useGraphApi();
  const auth = useAuth();
  const dispatch = useDispatch();

  const accessFacebookPage = () => {
    const url = locationState?.page?.id
      ? `https://www.facebook.com/${locationState.page.id}`
      : 'https://www.facebook.com';
    window.open(url, '_blank');
  };

  const handleActivate = async () => {
    if (!auth.userData) {
      return location.reload();
    }
    try {
      setIsLoading(true);
      const accountDetails = await validateInstagramPageAndReturnDetails(
        locationState.user.id,
        locationState.page.id,
        locationState.page.access_token,
      );
      await ActivateInstagram(router.shortName, locationState.user.id, accountDetails, auth.userData.access_token);
      Analytics.Track(Analytics.events.INSTAGRAM_ACTIVATION_CONNECTION_SUCCESS, { accountDetails: accountDetails });
      dispatch(updateInstagramActive(true));
      navigate(HOME_ROUTE);
    } catch (error) {
      switch (error) {
        case InstagramActivationErrors.InvalidInstagramAccount:
          Analytics.Track(Analytics.events.INSTAGRAM_ACTIVATION_ERROR, {
            error: 'Invalid Instagram account',
          });
          await createToastError('Conta do Instagram inválida', 'Por favor, faça o login novamente');
          navigate(INSTAGRAM_ACTIVATION_FACEBOOK_CONNECTION);
          break;
        case InstagramActivationErrors.NoMessagesAccess:
          Analytics.Track(Analytics.events.INSTAGRAM_ACTIVATION_ERROR, {
            error: 'No messages access',
          });
          navigate(INSTAGRAM_ACTIVATION_MESSAGES_ACCESS);
          break;
        case InstagramActivationErrors.NoBindedPagesFound:
          const modalRef = document.getElementById('bind-pages-modal') as HTMLBdsModalElement;
          if (modalRef) {
            Analytics.Track(Analytics.events.INSTAGRAM_ACTIVATION_ERROR, {
              error: 'No binded pages found for selected page',
            });
            modalRef.toggle?.();
          }
          break;
        default:
          break;
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Paper>
      <BdsGrid direction="column" padding="3">
        <BdsStepper>
          <BdsStep>Selecionar página</BdsStep>
          <BdsStep active>Vincular contas</BdsStep>
          <BdsStep>Conectar Instagram</BdsStep>
        </BdsStepper>
        <BdsGrid justifyContent="center" margin="t-3">
          <BdsGrid gap="2" direction="column">
            <BdsTypo variant="fs-24" tag="h3" bold="bold" lineHeight="plus">
              Vincule sua página do Facebook a um Instagram em 7 passos.
            </BdsTypo>
            <BdsTypo tag="p">
              1 - Acesse sua página no <b>Facebook</b>:
            </BdsTypo>
            <BdsButton
              variant="tertiary"
              onBdsClick={() => {
                Analytics.Track(Analytics.events.INSTAGRAM_ACTIVATION_OPEN_FACEBOOK_PAGE_NEW_TAB);
                accessFacebookPage();
              }}
              arrow
            >
              Acessar página do Facebook
            </BdsButton>
            <BdsTypo tag="p">
              2 - Clique em “Configurações” no canto esquerdo da tela, mesmo que esse item esteja desabilitado (cinza
              claro).
            </BdsTypo>
            <BdsGrid gap="2" direction={isMobile ? 'column' : 'row'}>
              <img src={FbConfigDisabled} alt="Facebook Configuração Desabilitada" />
              <img src={FbConfigEnabled} alt="Facebook Configuração Habilitada" />
            </BdsGrid>
            <BdsTypo tag="p">
              3 - Caso o item “Configurações” esteja desabilitado, clique em “Switch” ou “Alterar” no modal que irá
              aparecer.
            </BdsTypo>
            <div>
              <img src={SwitchAccounts} alt="Facebook Switch Accounts" />
            </div>
            <BdsTypo tag="p">4 - Clique em “Instagram” no menu lateral esquerdo.</BdsTypo>
            <div>
              <img src={BindedAccounts} alt="Facebook Binded Accounts" />
            </div>
            <BdsTypo tag="p">5 - Clique em “Conectar Conta”</BdsTypo>
            <div>
              <img src={ConnectAccount} alt="Facebook Connect Account" />
            </div>
            <BdsTypo tag="p">
              6 - Siga todos os passos e faça login com a conta do Instagram em que você deseja ativar seu assistente.
            </BdsTypo>
            <BdsGrid gap="2" direction={isMobile ? 'column' : 'row'}>
              <img src={ConfirmBind} alt="Facebook Confirm Bind" />
              <img src={AllowMessages} alt="Facebook Allow Messages" />
            </BdsGrid>
            <BdsTypo tag="p">
              7 - Quando finalizar, é só voltar aqui e confirmar que terminou de vincular as contas.
            </BdsTypo>
            <BdsButton
              bdsLoading={isLoading}
              onBdsClick={() => {
                Analytics.Track(Analytics.events.INSTAGRAM_ACCOUNT_BIND_PAGES_FINISHED_BUTTON);
                handleActivate();
              }}
              arrow
            >
              Finalizar
            </BdsButton>
            <div>
              <BdsTypo variant="fs-12" margin={false} italic>
                Finalize quando as páginas
              </BdsTypo>
              <BdsTypo variant="fs-12" margin={false} italic>
                já estiverem vinculadas.
              </BdsTypo>
            </div>
          </BdsGrid>
        </BdsGrid>
        <InstagramFailedBindPagesModal />
      </BdsGrid>
    </Paper>
  );
}

const InstagramFailedBindPagesModal = () => {
  const closeModal = () => {
    const modal = document.getElementById('bind-pages-modal') as HTMLBdsModalElement;
    modal.toggle?.();
  };

  return (
    <BdsModal id="bind-pages-modal" closeButton={false} size="dynamic">
      <BdsGrid direction="column" gap="3">
        <BdsGrid alignItems="center" gap="2">
          <BdsIllustration style={{ height: '2.5rem' }} type="spots" name="letter-cancel-error" />
          <BdsTypo bold="bold" lineHeight="plus">
            As contas ainda não estão vinculadas.
          </BdsTypo>
        </BdsGrid>
        <BdsTypo>
          <b>Siga o passo a passo</b> para vincular contas.
        </BdsTypo>
        <BdsGrid justifyContent="center">
          <BdsButton
            icon="arrow-left"
            onBdsClick={() => {
              Analytics.Track(Analytics.events.INSTAGRAM_ACTIVATION_ERROR_BACK);
              closeModal();
            }}
          >
            Voltar
          </BdsButton>
        </BdsGrid>
      </BdsGrid>
    </BdsModal>
  );
};

export default BindPagesChecklist;
