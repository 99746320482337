import { BdsButton, BdsGrid, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { HOW_DELETE_NUMBER, REPORT_PROBLEM } from 'constants/MetaActivation';
import { META_CONNECTION_ROUTE } from 'constants/Routes';
import { Analytics, Logger } from 'infra/adapters';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Hyperlink, NumberPreparationBtnGroup, ReportActionContainer } from './styles';

const className = 'NumberPreparation';

const NumberPreparation: React.FC = () => {
  const methodName = 'NumberPreparation';

  const navigate = useNavigate();

  useEffect(() => {
    Analytics.Track(Analytics.events.NUMBER_PREPARATION_OPEN);
    Logger.Info('Number Preparation page loaded', { methodName, className });
  }, []);

  const reportProblem = () => {
    Analytics.Track(Analytics.events.NUMBER_PREPARATION_REPORT_PROBLEM);
    window.open(REPORT_PROBLEM, ' _blank');
  };

  const howDeleteNumber = () => {
    Analytics.Track(Analytics.events.NUMBER_PREPARATION_DELETE_ACCOUNT_TUTORIAL);
    window.open(HOW_DELETE_NUMBER, ' _blank');
  };

  const openMetaConnectionPage = (trackEvent: string) => {
    const methodName = 'openMetaConnectionPage';
    Analytics.Track(trackEvent);
    navigate(META_CONNECTION_ROUTE);
    Logger.Info(`redirecting to meta conection page (${META_CONNECTION_ROUTE})`, { methodName, className });
  };

  return (
    <Container>
      <BdsGrid className="number-preparation-container" direction="column" gap="4" container-fluid padding="half">
        <BdsGrid gap="2" direction="column">
          <BdsTypo variant="fs-24" margin={false}>
            Seu número está preparado para a API?
          </BdsTypo>
          <BdsTypo variant="fs-14" data-testid="prepare-connect-text">
            Para conectar à API você precisa ter um <b>número totalmente disponível.</b>
          </BdsTypo>
          <BdsTypo variant="fs-14">
            A <b>Meta/facebook não permite</b> que um mesmo número utilize <b>app e API ao mesmo tempo.</b>
          </BdsTypo>
          <BdsTypo variant="fs-14">Você precisa...</BdsTypo>
          <BdsTypo variant="fs-14">
            • <b> ou apagar a conta do app do whatsapp</b> do número que deseja conectar à API (
            <Hyperlink data-testid="how-deleted-btn" onClick={howDeleteNumber}>
              veja como apagar sua conta aqui
            </Hyperlink>
            )
          </BdsTypo>
          <BdsTypo variant="fs-14">
            • <b>ou usar um número novo sem o app</b> do whatsapp (pode ser um número fixo com linha)
          </BdsTypo>
          <NumberPreparationBtnGroup>
            <BdsButton
              onClick={() => openMetaConnectionPage(Analytics.events.NUMBER_PREPARATION_DELETED_PHONE)}
              data-testid="number-deleted-btn"
              variant="primary"
            >
              Já apaguei minha conta
            </BdsButton>
            <BdsButton
              onClick={() => openMetaConnectionPage(Analytics.events.NUMBER_PREPARATION_REGISTER_NEW_PHONE)}
              data-testid="new-phone-btn"
              variant="primary"
            >
              Vou usar um número novo
            </BdsButton>
          </NumberPreparationBtnGroup>
        </BdsGrid>
        <ReportActionContainer>
          <BdsButton onClick={reportProblem} variant="secondary" data-testid="prepare-number-report-problem">
            Reportar problema
          </BdsButton>
        </ReportActionContainer>
      </BdsGrid>
    </Container>
  );
};

export default NumberPreparation;
