import { BdsButton, BdsGrid, BdsIcon, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { ProPlanModal } from 'components/ProPlanModal/ProPlanModal';
import { Analytics, Logger } from 'infra/adapters';
import { PlanTypeEnum } from 'objects/types/BlipGoApi';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useProject } from 'redux/slices/ProjectSlice/projectSlice';

interface IActiveMessagesCard {
  icon: string;
  title: string;
  description: string;
  btnLabel: string;
  tracking: string;
  testId: string;
  btnClickEvent: () => void;
}

const className = 'ActiveMessagesCard';

export const ActiveMessagesCard = ({
  icon,
  title,
  tracking,
  testId,
  description,
  btnLabel,
  btnClickEvent,
}: IActiveMessagesCard) => {
  const modalRef = useRef<null | HTMLBdsModalElement>(null);
  const { planType } = useSelector(useProject);

  const handleConnect = () => {
    const methodName = 'handleConnect';
    Analytics.Track(tracking);
    if (planType === PlanTypeEnum.Pro) {
      btnClickEvent();
    } else {
      if (modalRef?.current) {
        Logger.Info('opening pro plan modal', { methodName, className });
        modalRef.current.toggle?.();
      }
    }
  };

  return (
    <BdsPaper style={{ background: '#FFF' }}>
      <BdsGrid
        direction="column"
        justifyContent="center"
        alignItems="center"
        padding="3"
        gap="2"
        style={{ width: '100%' }}
      >
        <BdsGrid alignItems="center" gap="2">
          <BdsIcon name={icon} size="brand"></BdsIcon>
          <BdsTypo variant="fs-20" margin={false} bold="bold">
            {title}
          </BdsTypo>
        </BdsGrid>
        <BdsTypo variant="fs-14">{description}</BdsTypo>
        <BdsButton onClick={handleConnect} data-testid={testId}>
          {btnLabel}
        </BdsButton>
      </BdsGrid>
      <ProPlanModal modalRef={modalRef} activeMessageOrigin={true} />
    </BdsPaper>
  );
};
