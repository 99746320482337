import { BdsButton, BdsGrid, BdsIcon, BdsStep, BdsStepper, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { INSTAGRAM_ACTIVATION_BIND_CHECKLIST } from 'constants/Routes';
import { Paper } from 'globalStyles';
import { Analytics } from 'infra/adapters';
import { FacebookPage, User } from 'objects/types/Facebook';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function BindFacebookToInstagram() {
  const locationState = useLocation()?.state as { page: FacebookPage; user: User };
  const navigate = useNavigate();

  useEffect(() => {
    Analytics.Track(Analytics.events.INSTAGRAM_ACTIVATION_BIND_PAGES_PAGE);
  }, []);

  return (
    <Paper>
      <BdsGrid direction="column" padding="3">
        <BdsStepper>
          <BdsStep>Selecionar página</BdsStep>
          <BdsStep active>Vincular contas</BdsStep>
          <BdsStep>Conectar Instagram</BdsStep>
        </BdsStepper>
        <BdsGrid justifyContent="center" margin="t-3">
          <BdsGrid direction="column" gap="2" lg="8">
            <div>
              <BdsTypo variant="fs-24" tag="h3" bold="bold">
                Agora é preciso vincular a página {locationState?.page?.name ?? ''} a um perfil de Instagram.
              </BdsTypo>
            </div>
            <BdsGrid gap="2">
              <BdsIcon name="facebook" />
              <BdsTypo>
                Esse processo precisa ser feito <b>direto no Facebook.</b>
              </BdsTypo>
            </BdsGrid>
            <BdsGrid gap="2">
              <BdsIcon name="eye-open" />
              <BdsTypo>
                Para fazer o processo, <b>leia com cuidado o passo a passo</b> a seguir.
              </BdsTypo>
            </BdsGrid>
            <BdsButton
              onBdsClick={() => {
                Analytics.Track(Analytics.events.INSTAGRAM_ACTIVATION_BIND_PAGES_BUTTON);
                navigate(INSTAGRAM_ACTIVATION_BIND_CHECKLIST, {
                  state: { page: locationState.page, user: locationState.user },
                });
              }}
              arrow
            >
              Como vincular com o Instagram
            </BdsButton>
          </BdsGrid>
        </BdsGrid>
      </BdsGrid>
    </Paper>
  );
}

export default BindFacebookToInstagram;
