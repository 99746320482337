import React, { useState } from 'react';
import { ContactItemDto } from 'objects/types/ContactsResponse';
import './style.scss';
import { BdsGrid, BdsIcon, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { formatLastMessageDate, formatLastMessageTime, selectSourceChannelIcon } from 'utils/ChatHistoryFormater';
import { Analytics } from 'infra/adapters';

interface ChatListProps {
  contacts: Array<ContactItemDto>;
  onSelectContact: (contact: ContactItemDto) => void;
}

const ChatList: React.FC<ChatListProps> = ({ contacts, onSelectContact }) => {
  const [selectedContact, setSelectedContact] = useState<ContactItemDto | null>(null);

  const handleSelectContact = (contact: ContactItemDto) => {
    setSelectedContact(contact);
    onSelectContact(contact);
    Analytics.Track(Analytics.events.CONTACT_CLICK);
  };

  return (
    <BdsGrid direction="column">
      {contacts.length === 0 || !contacts ? (
        <>
          <BdsPaper className="contact-card disabled" data-testid="contact-card-placeholder" />
        </>
      ) : (
        contacts.map((contact, index) => (
          <BdsGrid key={index} padding="1" data-testid="contact-card" onClick={() => handleSelectContact(contact)}>
            <BdsPaper className={`contact-card ${selectedContact === contact ? 'selected' : ''}`}>
              <BdsGrid direction="row" padding="1" gap="1" justifyContent="space-between">
                <BdsGrid className="avatar-container">
                  <BdsIcon className="avatar" size="large" name="user-default"></BdsIcon>
                  <BdsGrid className="overlay-icon">
                    <BdsIcon size="xx-small" type="logo" name={selectSourceChannelIcon(contact.source)}></BdsIcon>
                  </BdsGrid>
                </BdsGrid>
                <BdsGrid alignItems="center" className="contact-name">
                  <BdsTypo variant="fs-12" bold="bold">
                    {contact.name || 'sem nome'}
                  </BdsTypo>
                </BdsGrid>
                <BdsGrid alignItems="center" direction="column" justifyContent="center">
                  {contact.lastMessageDate ? (
                    <>
                      <BdsTypo lineHeight="plus" variant="fs-12" bold="semi-bold">
                        {formatLastMessageTime(new Date(contact.lastMessageDate))}
                      </BdsTypo>
                      <BdsTypo variant="fs-12">{formatLastMessageDate(new Date(contact.lastMessageDate))}</BdsTypo>
                    </>
                  ) : (
                    'Data não disponível'
                  )}
                </BdsGrid>
                <BdsGrid alignItems="center">
                  <BdsIcon name="arrow-right"></BdsIcon>
                </BdsGrid>
              </BdsGrid>
            </BdsPaper>
          </BdsGrid>
        ))
      )}
    </BdsGrid>
  );
};

export default ChatList;
