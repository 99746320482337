import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { DContainer, MContainer } from './style';

interface ILayoutContainer {
  children: React.ReactNode;
}

const LayoutContainer: React.FC<ILayoutContainer> = ({ children }) => {
  return (
    <>
      <MobileView>
        <MContainer>{children}</MContainer>
      </MobileView>

      <BrowserView>
        <DContainer>{children}</DContainer>
      </BrowserView>
    </>
  );
};

export default LayoutContainer;
