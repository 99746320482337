import { BdsGrid, BdsIcon, BdsTooltip, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import whatsappLogo from 'assets/WhatsappLogo.svg';

interface ITableLineFeature {
  featureLabel: string;
  freeIncluded: boolean;
  proIncluded: boolean;
  showWhatsappLogo: boolean;
  additionalInfoFree?: string;
  additionalInfoPro?: string;
  tooltipText?: string;
}

interface IFeatureField {
  featureIncluded: boolean;
  background: string;
  additionalInfo?: string;
  fontColor?: string;
}

export const PaywallTableLine = ({
  freeIncluded,
  proIncluded,
  additionalInfoFree,
  additionalInfoPro,
  featureLabel,
  showWhatsappLogo,
  tooltipText,
}: ITableLineFeature) => {
  const FeatureDetail = ({ featureIncluded, additionalInfo, fontColor, background }: IFeatureField) => {
    return (
      <BdsGrid alignItems="center" justifyContent="center" style={{ background, width: '225px', height: '100%' }}>
        {!featureIncluded ? (
          <BdsIcon name="error" theme="solid" color="#8C8C8C" />
        ) : additionalInfo ? (
          <BdsTypo style={{ color: fontColor }}>{additionalInfo}</BdsTypo>
        ) : (
          <BdsIcon name="verified" theme="solid" style={{ color: '#00CD74' }} />
        )}
      </BdsGrid>
    );
  };

  return (
    <BdsGrid style={{ height: '56px' }} alignItems="center">
      <BdsGrid gap="2" alignItems="center" style={{ width: '550px' }}>
        <BdsTypo variant="fs-20" margin={false}>
          {featureLabel}
        </BdsTypo>
        {showWhatsappLogo && <img src={whatsappLogo} />}
        {tooltipText && (
          <BdsTooltip position="right-top" tooltipText={tooltipText}>
            <BdsIcon name="info" size="small" />
          </BdsTooltip>
        )}
      </BdsGrid>

      <FeatureDetail featureIncluded={freeIncluded} additionalInfo={additionalInfoFree} background="#E0E0E0" />
      <FeatureDetail
        featureIncluded={proIncluded}
        additionalInfo={additionalInfoPro}
        fontColor="#fff"
        background="#1E6BF1"
      />
    </BdsGrid>
  );
};
