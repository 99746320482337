import { BdsLoadingSpinner } from 'blip-ds/dist/blip-ds-react';
import MainLayout from 'components/MainLayout/MainLayout';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useProject } from 'redux/slices/ProjectSlice/projectSlice';
import Channelshelf from './components/Channelshelf/Channelshelf';
import { LoadingContainerChannels, MainContainer } from './styles';

export const ChannelsContainer: FC = () => {
  const { loading } = useSelector(useProject);

  return (
    <>
      {loading ? (
        <LoadingContainerChannels>
          <BdsLoadingSpinner color="main" size="small" data-testid="loading-channels" />
        </LoadingContainerChannels>
      ) : (
        <MainLayout>
          <MainContainer>
            <Channelshelf />
          </MainContainer>
        </MainLayout>
      )}
    </>
  );
};
