import { CreateToastType } from 'blip-ds/dist/types/components/toast/toast-interface';

export const createToastSuccess = async (title: string, text?: string) => {
  if (!!title) {
    const toast: CreateToastType = {
      actionType: 'icon',
      icon: 'like',
      variant: 'success',
      toastTitle: title,
      toastText: text ?? '',
      position: 'bottom-left',
      duration: 3,
    };

    appendToast(toast);
  }
};

export const createToastInfo = async (title: string, text?: string) => {
  if (!!title) {
    const toast: CreateToastType = {
      actionType: 'icon',
      icon: 'info',
      variant: 'system',
      toastTitle: title,
      toastText: text ?? '',
      position: 'bottom-left',
      duration: 65,
    };

    appendToast(toast);
  }
};

export const createToastError = async (title: string, text?: string) => {
  if (!!title) {
    const toast: CreateToastType = {
      actionType: 'icon',
      icon: 'error',
      variant: 'error',
      toastTitle: title,
      toastText: text ?? '',
      position: 'bottom-left',
      duration: 5,
    };

    appendToast(toast);
  }
};

export const appendToast = async (toast: CreateToastType) => {
  const newToast = document.createElement('bds-toast');

  document.body.appendChild(newToast);

  await newToast.create(toast);
};
