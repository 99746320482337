import { CommandsApi, TimeOutRequest } from 'configurations/Environment';
import { LocalStorageService } from 'services/LocalStorageService';
import { BaseRestService } from './BaseRestAdapter';

export class CommandsApiAdapter<T> extends BaseRestService<T> {
  constructor(methodOrigin: string) {
    const urlSuffix = CommandsApi.split('https://')[1];
    const tenantId = LocalStorageService.Get('userTraits')?.tenant?.id;
    const CommandsAPIFormattedUrl = tenantId ? `https://${tenantId}.${urlSuffix}` : CommandsApi;
    super(CommandsAPIFormattedUrl, 'CommandsService', methodOrigin);
  }

  protected setInstanceDefaultHeaders(): void {
    this.getDefaults().headers.common['Content-Type'] = 'application/json';
  }

  protected setInstanceDefaultTimeOut(): void {
    this.getDefaults().timeout = TimeOutRequest;
  }

  protected setInstanceInterceptors(): void {
    this.getInterceptors().response.use(this.handleResponse, this.handleError);
  }
}

export default CommandsApiAdapter;