import { LogLevel } from 'typescript-logging';
import { Environment } from '../constants/Environments';

export const ApplicationName = process.env.REACT_APP_NAME ?? '';
export const ApplicationVersion = process.env.REACT_APP_PROJECT_VERSION ?? '';

export const SegmentKey = process.env.REACT_APP_SEGMENT_KEY ?? '';
export const AnalyticsKey = process.env.REACT_APP_ANALYTICS_KEY ?? '';

export const CurrentLogLevel = process.env.REACT_APP_LOG_LEVEL ?? LogLevel[LogLevel.Info];
export const LogServerUrl = process.env.REACT_APP_LOG_SERVER ?? '';
export const LogServerUser = process.env.REACT_APP_LOG_USER ?? '';
export const LogServerPass = process.env.REACT_APP_LOG_PASSWORD ?? '';

export const CurrentEnvironment = process.env.REACT_APP_ENV ?? Environment?.Development;

export const ApiPort = process.env.REACT_APP_API_PORT ?? '';
export const PacksApi = process.env.REACT_APP_PACKS_API ?? '';
export const MsgingServer = process.env.REACT_APP_MSGING_URL ?? '';
export const ApplicationDetail = process.env.REACT_APP_APPLICATION_DETAIL ?? '';

export const BlipChatPrefix = process.env.REACT_APP_BLIP_CHAT_PREFIX ?? '';

export const FacebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID ?? '';
export const NumberDaysToExpireUserSession = Number(process.env.REACT_APP_NUMBER_DAYS_TO_EXPIRE_USER_SESSION);
export const FacebookBusinessSystemUserToken = process.env.REACT_APP_FACEBOOK_BUSINESS_SYSTEM_USER_TOKEN ?? '';

export const PacksHomeUrl = process.env.REACT_APP_URL_PACKS_HOME ?? '';
export const SelfOnboardingUrl = process.env.REACT_APP_SELF_ONBOARDING_URL ?? '';
export const PACK_MANAGER_URL = process.env.REACT_APP_PACK_MANAGER_URL ?? '';

export const Authority = process.env.REACT_APP_AUTHORITY ?? '';
export const ClientId = process.env.REACT_APP_CLIENT_ID ?? '';
export const ResponseType = process.env.REACT_APP_RESPONSE_TYPE ?? '';
export const AcntScope = process.env.REACT_APP_ACNT_SCOPE ?? '';
export const TokenExpiringMin = Number(process.env.REACT_APP_TOKEN_EXPIRING_MIN);

export const BlipGoId = process.env.REACT_APP_BLIP_GO_ID ?? '';

export const DeskUrl = process.env.REACT_APP_DESK_URL ?? '';

export const TimeOutRequest = Number(process.env.REACT_APP_TIME_OUT_REQUEST);

export const CommandsApi = process.env.REACT_APP_COMMANDS_BLIP ?? '';

export const blipGoApiUser = process.env.REACT_APP_BLIPGO_ADMIN_USER ?? '';
export const blipGoApiPassword = process.env.REACT_APP_BLIPGO_ADMIN_PASSWORD ?? '';
export const blipGoApiUrl = process.env.REACT_APP_BLIPGO_API_URL ?? '';

export const novemberUserPacks = process.env.REACT_APP_NOVEMBER_AUTHORIZATION_USER ?? '';
export const novemberUserToken = process.env.REACT_APP_NOVEMBER_AUTHORIZATION_TOKEN ?? '';
export const mailApiUrl = process.env.REACT_APP_MAIL_API_URL ?? '';

export const GraphApiUrl = process.env.REACT_APP_GRAPH_API_URL ?? '';

export const PackManagerV1 = process.env.REACT_APP_PACK_MANAGER_V1 ?? '';

export const AppKeyBlipChat = process.env.REACT_APP_BLIP_CHAT_APP_KEY ?? '';
export const CustomCommonUrl = process.env.REACT_APP_CUSTOM_COMMON_URL ?? '';

export const docusignEnvironment = process.env.REACT_APP_DOCUSIGN_URL;
export const docusignAccountId = process.env.REACT_APP_DOCUSIGN_ACCOUNT_ID;
export const docusignClickwrapId = process.env.REACT_APP_DOCUSIGN_CLICKWRAP_ID;
export const docusignSdk = process.env.REACT_APP_META_DOCUSIGN_SDK;

export const metaConnectionOptions = process.env.REACT_APP_META_CONNECTION_OPTIONS;

export const BlipApi = process.env.REACT_APP_BLIP_API ?? '';

export const ADMIN_MEMBER_ID = process.env.REACT_APP_ADMIN_MEMBER_ID ?? '';
export const ATTENDANT_MEMBER_ID = process.env.REACT_APP_ATTENDANT_MEMBER_ID ?? '';
