import { BdsPaper, BdsGrid, BdsIcon, BdsTypo, BdsButton } from 'blip-ds/dist/blip-ds-react';

interface IConnectionChoiceCard {
  icon: string;
  title: string;
  description: string;
  buttonLabel: string;
  choiceKey: string;
  tracking: string;
  buttonId: string;
  chooseEvent: (choiceKey: string, tracking: string) => void;
}

export const ConnectionChoiceCard = ({
  icon,
  title,
  description,
  buttonLabel,
  choiceKey,
  tracking,
  buttonId,
  chooseEvent,
}: IConnectionChoiceCard) => (
  <BdsPaper style={{ background: '#fff' }}>
    <BdsGrid direction="column" justifyContent="center" alignItems="center" padding="2" gap="1">
      <BdsIcon size="brand" name={icon} />
      <BdsTypo variant="fs-16" bold="bold">
        {title}
      </BdsTypo>
      <BdsTypo variant="fs-14">{description}</BdsTypo>
      <BdsGrid margin="t-1">
        <BdsButton icon="plugin" onClick={() => chooseEvent(choiceKey, tracking)} data-testid={buttonId}>
          {buttonLabel}
        </BdsButton>
      </BdsGrid>
    </BdsGrid>
  </BdsPaper>
);
