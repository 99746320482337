import styled from 'styled-components';

export const TipsContainer = styled.div`
  width: 514px;
  height: auto;
  color: #454545;
  background-color: #e0e0e0;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding-bottom: 12rem;

  .title {
    width: 402px;
    height: 80px;
  }

  .channel-activated {
    display: flex;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

export const Link = styled.a`
  cursor: pointer;
  color: #454545;
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
`;
