import CollapseMenu from 'assets/blipgoCollapseMenu.svg';
import BlipGoSidebarClose from 'assets/blipgoLogoDesktopSidebarClose.svg';
import BlipGoSidebarOpen from 'assets/blipgoLogoDesktopSidebarOpen.svg';
import { BdsIcon, BdsTooltip, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { HelpCenterModule, Modules } from 'constants/Modules';
import { Analytics } from 'infra/adapters';
import { Module } from 'objects/types/Module';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useContractInfo } from 'redux/slices/ContractInfoSlice/contractInfoSlice';
import { addActiveModule, updateIsMenuOpen, useProject } from 'redux/slices/ProjectSlice/projectSlice';
import SupportMenu from './components/SupportMenu/SupportMenu';
import {
  BlipGoLogo,
  BottomSection,
  CollapseButton,
  CollapseItem,
  CollaspseContainer,
  HelpCenter,
  Item,
  ItemContainer,
  ItemsSection,
  MenuItem,
  ProjectVersion,
  SidebarContainer,
  TopSection,
} from './style';
import { useInstallation } from 'redux/slices/InstallationSlice/installationSlice';
import { DEFAULT_INSTALLATION_ID } from 'constants/Installation';

const DesktopSidebar: React.FC = () => {
  const { isMenuOpen, version, activeModule } = useSelector(useProject);
  const { signedContract } = useSelector(useContractInfo);
  const [helpCenterOpen, setHelpCenterOpen] = useState(false);
  const { pathname } = useLocation();
  const { installationId } = useSelector(useInstallation);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  let OBJ_MODULES = Object.values(Modules);

  if (installationId === DEFAULT_INSTALLATION_ID) {
    OBJ_MODULES = [Modules.desk, Modules.extensions];
  }

  const ICON_SIZE = 'x-large';
  const TOOLTIP_POSITION = 'right-bottom';
  const COLLAPSE = {
    CollapseOpen: {
      icon: 'arrow-left',
      tooltip: 'Recolher menu',
    },
    CollapseClose: {
      icon: 'arrow-right',
      tooltip: 'Expandir menu',
    },
  };

  const handleSetActiveModule = (module: Module) => {
    if (module.title === HelpCenterModule.title) {
      if (!helpCenterOpen) {
        const screenName = pathname.split('/').pop();
        Analytics.Track(Analytics.events.HELP_SUBMENU, { screenName });
      }
      setHelpCenterOpen(prev => !prev);
    } else {
      Analytics.Track(Analytics.events.PG_OPEN_MODULE, {
        moduleName: module.title,
        proPlan: signedContract,
      });
      dispatch(addActiveModule(module));
      navigate(module.route);
      if (module.title === Modules.configurations.title) {
        Analytics.Track(Analytics.events.PG_OPEN_GREETINGS);
      }
      if (module.title === Modules.contacts.title) {
        Analytics.Track(Analytics.events.OPEN_CONTACTS);
      }
    }
  };

  const isShowTypo = () => isMenuOpen;

  const handleUpdateSidebarOpen = () => {
    dispatch(updateIsMenuOpen(!isMenuOpen));
  };

  const TopSidebar = (
    <>
      <BlipGoLogo
        $isCollapseOpen={isMenuOpen}
        src={isMenuOpen ? BlipGoSidebarOpen : BlipGoSidebarClose}
        alt="Blip Go! logo"
      />
      <CollaspseContainer data-testid={'click-collapse'} onClick={handleUpdateSidebarOpen}>
        <BdsTooltip
          className="tooltip"
          tooltip-text={isMenuOpen ? COLLAPSE.CollapseOpen.tooltip : COLLAPSE.CollapseClose.tooltip}
          position={TOOLTIP_POSITION}
        >
          <CollapseItem $isCollapseOpen={isMenuOpen}>
            <CollapseButton $isCollapseOpen={isMenuOpen} src={CollapseMenu} alt="Collapse menu" />
            <BdsIcon className="icon" name={isMenuOpen ? COLLAPSE.CollapseOpen.icon : COLLAPSE.CollapseClose.icon} />
          </CollapseItem>
        </BdsTooltip>
      </CollaspseContainer>
    </>
  );

  const TopItems = (
    <>
      {TopSidebar}
      <ItemsSection>
        {OBJ_MODULES.map(module => (
          <MenuItem key={module.title}>
            <BdsTooltip tooltip-text={module.tooltip} position={TOOLTIP_POSITION}>
              <ItemContainer $isCollapseOpen={isMenuOpen}>
                <Item
                  data-testid={`click-${module.title}`}
                  $isModuleActive={activeModule.title === module.title}
                  onClick={() => handleSetActiveModule(module)}
                >
                  <BdsIcon className="icon" name={module.icon} size={ICON_SIZE} />
                  {isShowTypo() && <BdsTypo className="icon">{module.title}</BdsTypo>}
                </Item>
              </ItemContainer>
            </BdsTooltip>
          </MenuItem>
        ))}
      </ItemsSection>
    </>
  );

  const ButtomItems = (
    <>
      <HelpCenter>
        <MenuItem>
          <ItemContainer $isCollapseOpen={isMenuOpen}>
            <Item
              data-testid={`click-${HelpCenterModule.title}`}
              $isModuleActive={false}
              onClick={() => handleSetActiveModule(HelpCenterModule)}
            >
              <BdsIcon className="icon" name={HelpCenterModule.icon} size={ICON_SIZE} />
              {isShowTypo() && <BdsTypo className="icon">{HelpCenterModule.title}</BdsTypo>}
              {helpCenterOpen && <SupportMenu />}
            </Item>
          </ItemContainer>
        </MenuItem>
      </HelpCenter>
      <ProjectVersion $isCollapseOpen={isMenuOpen}>
        <BdsTypo className="typoColor" variant="fs-12">
          Versão
        </BdsTypo>
        <BdsTypo className="typoColor" variant="fs-12">
          {version}
        </BdsTypo>
      </ProjectVersion>
    </>
  );

  return (
    <SidebarContainer $isCollapseOpen={isMenuOpen}>
      <TopSection>{TopItems}</TopSection>
      <BottomSection>{ButtomItems}</BottomSection>
    </SidebarContainer>
  );
};

export default DesktopSidebar;
