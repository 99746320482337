import { CurrentEnvironment } from '../configurations/Environment';

export const Environment = {
    Development: 'development',
    Staging: 'staging',
    Production: 'production',
};

export const IsDevelopment = () => {
    return CurrentEnvironment === Environment.Development;
}

export const IsStaging = () => {
    return CurrentEnvironment === Environment.Staging;
}

export const IsProduction = () => {
    return CurrentEnvironment === Environment.Production;
}
