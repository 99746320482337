import { Authority } from 'configurations/Environment';
import { Analytics } from 'infra/adapters';
import { AuthContextProps } from 'oidc-react';

export const LogOut = async (auth: AuthContextProps) => {
  Analytics.Track(Analytics.events.BLIP_GO_LOGOUT);
  const urlRedirect = window.location.href;
  const urlEncoded = encodeURI(urlRedirect);
  const urlLogout = `${Authority}/logout/redirect?returnDesiredUrl=${urlEncoded}`;
  await auth.signOut();
  window.location.href = urlLogout;
};
