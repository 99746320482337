import { BdsGrid, BdsIcon } from 'blip-ds/dist/blip-ds-react';
import { EXTENSIONS_ADDONS_ROUTE, EXTENSIONS_BLIPCHAT_ROUTE } from 'constants/Routes';
import { Analytics } from 'infra/adapters';
import { useNavigate } from 'react-router-dom';
import Card from '../components/Card';

function ExtensionList() {
  const navigate = useNavigate();

  return (
    <BdsGrid gap="4" padding="6" margin="x-12" flexWrap="wrap">
      <Card
        onClick={() => {
          Analytics.Track(Analytics.events.EXTENSIONS_ADDON_CARD);
          navigate(EXTENSIONS_ADDONS_ROUTE);
        }}
        Logo={DeskAddonsLogo}
        title="Desk Addons"
        author="Blip"
        description="Acesse o histórico de tickets atendidos, faça buscas por nome, telefone e mais."
        category="Extensão web"
        pricing="Instalação grátis"
      />
      <Card
        onClick={() => {
          Analytics.Track(Analytics.events.EXTENSIONS_BLIPCHAT_CARD);
          navigate(EXTENSIONS_BLIPCHAT_ROUTE);
        }}
        Logo={BlipChatLogo}
        title="Blip Chat para smartphone"
        author="Blip"
        description="Fale com seus clientes e acompanhe seus atendimentos pelo smartphone."
        category="Aplicativo"
        pricing="Instalação grátis"
      />
    </BdsGrid>
  );
}

function DeskAddonsLogo() {
  return <BdsIcon style={{ backgroundColor: '#0096FA', color: 'white' }} name="message-total" size="brand" />;
}

function BlipChatLogo() {
  return <BdsIcon name="blip-chat" type="logo" size="brand" />;
}

export default ExtensionList;
