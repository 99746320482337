import {
  ADD_OR_UPDATE_METHOD,
  APPLICATION_IRIS_CONTACT_JSON,
  APPLICATION_IRIS_DESK_ATTENDANCEQUEUE_JSON,
  APPLICATION_IRIS_DESK_ATTENDANT_JSON,
  APPLICATION_IRIS_DESK_RULE_JSON,
  ATTENDANCE_QUEUES_ROUTE,
  ATTENDANTS_ROUTE,
  CONTACTS_ROUTE,
  DELETE_METHOD,
  DESK_MSGING_NET,
  GET_METHOD,
  MERGE_METHOD,
  MSGING_NET,
  POSTMASTER,
  PROFILE_ROUTE,
  RESOURCES_ROUTE,
  RULES_ROUTE,
  VALIDATE_PAGE_TOKEN_ROUTE,
  WA_GW_MSGING_NET,
} from 'constants/CommandsApiRoutes';
import { CommandsApi } from 'infra/adapters';
import { CommandErrorCodes } from 'objects/types/CommandRequest';
import Queue from 'objects/types/Queue';
import Rule from 'objects/types/Rule';
import { BotAttendant } from 'objects/types/commands/BotAttendant';
import { GetAllAttendants } from 'objects/types/commands/GetAllAttendants';
import GetAllQueues from 'objects/types/commands/GetAllQueues';
import GetAllRules from 'objects/types/commands/GetAllRules';
import { Resource } from 'objects/types/commands/Resource';
import SetAttendant from 'objects/types/commands/SetAttendant';
import SetContactSimulator from 'objects/types/commands/SetContactSimulator';
import SetQueue from 'objects/types/commands/SetQueue';
import SetRule from 'objects/types/commands/SetRule';
import { ValidatePageToken } from 'objects/types/commands/ValidatePageToken';
import { AllResourceResponse } from 'objects/types/commands/allResourcesResponse';
import { ResourceResponse } from 'objects/types/commands/resourceResponse';
import { v4 as uuidv4 } from 'uuid';

const ROUTE_COMMANDS = '/commands';

export const getSpecificResource = async (key: string, resourceName: string) => {
  try {
    const commandApi = new CommandsApi('getSpecificResource');

    const response = await commandApi.Post<ResourceResponse>(
      ROUTE_COMMANDS,
      {
        id: uuidv4(),
        method: GET_METHOD,
        uri: `${RESOURCES_ROUTE}/${encodeURIComponent(resourceName)}`,
      },
      {
        headers: {
          Authorization: key,
        },
      },
    );

    return response.data;
  } catch (ex) {
    return Promise.reject(ex);
  }
};
export const getAllResources = async (key: string) => {
  try {
    const commandApi = new CommandsApi('getAllResources');

    const response = await commandApi.Post<AllResourceResponse>(
      ROUTE_COMMANDS,
      {
        id: uuidv4(),
        method: GET_METHOD,
        uri: RESOURCES_ROUTE,
      },
      {
        headers: {
          Authorization: key,
        },
      },
    );

    return response.data;
  } catch (ex) {
    return Promise.reject(ex);
  }
};

export const setSpecificResource = async (key: string, resource: Resource) => {
  try {
    const commandApi = new CommandsApi('setSpecificResource');

    const response = await commandApi.Post<ResourceResponse>(
      ROUTE_COMMANDS,
      {
        id: uuidv4(),
        method: ADD_OR_UPDATE_METHOD,
        uri: `${RESOURCES_ROUTE}/${encodeURIComponent(resource.name)}`,
        type: resource.type,
        resource: resource.value,
      },
      {
        headers: {
          Authorization: key,
        },
      },
    );

    return response.data;
  } catch (ex) {
    return Promise.reject(ex);
  }
};

export const setSpecificsResources = async (key: string, resources: Resource[]) => {
  try {
    const commandApi = new CommandsApi('setSpecificsResources');

    const responses: ResourceResponse[] = [];

    for (const res of resources) {
      const response = await commandApi.Post<ResourceResponse>(
        ROUTE_COMMANDS,
        {
          id: uuidv4(),
          method: ADD_OR_UPDATE_METHOD,
          uri: `${RESOURCES_ROUTE}/${encodeURIComponent(res.name)}`,
          type: res.type,
          resource: res.value,
        },
        {
          headers: {
            Authorization: key,
          },
        },
      );

      responses.push(response.data);
    }

    return responses;
  } catch (ex) {
    return Promise.reject(ex);
  }
};

export const getValidatePageToken = async (key: string, channel: string) => {
  try {
    const commandApi = new CommandsApi('getValidatePageToken');

    const response = await commandApi.Post<ValidatePageToken>(
      ROUTE_COMMANDS,
      {
        id: uuidv4(),
        method: GET_METHOD,
        uri: VALIDATE_PAGE_TOKEN_ROUTE,
        to: `${POSTMASTER}@${channel}.gw.msging.net`,
      },
      {
        headers: {
          Authorization: key,
        },
      },
    );

    return response.data;
  } catch (ex) {
    return Promise.reject(ex);
  }
};

export const deleteSpecificResource = async (key: string, resourceName: string) => {
  try {
    const commandApi = new CommandsApi('deleteSpecificResource');

    const response = await commandApi.Post<ResourceResponse>(
      ROUTE_COMMANDS,
      {
        id: uuidv4(),
        method: DELETE_METHOD,
        uri: `${RESOURCES_ROUTE}/${encodeURIComponent(resourceName)}`,
        type: 'text/plain',
      },
      {
        headers: {
          Authorization: key,
        },
      },
    );

    if (response.data.status === 'failure') {
      throw new Error(`Falha ao deletar o recurso ${resourceName}`);
    }

    return response.data;
  } catch (ex) {
    return Promise.reject(ex);
  }
};

export const getInfoWppStatus = async (key: string) => {
  try {
    const commandApi = new CommandsApi('getInfoWppStatus');

    const response = await commandApi.Post<ResourceResponse>(
      ROUTE_COMMANDS,
      {
        id: uuidv4(),
        method: GET_METHOD,
        to: `${POSTMASTER}@${WA_GW_MSGING_NET}`,
        uri: PROFILE_ROUTE,
      },
      {
        headers: {
          Authorization: key,
        },
      },
    );

    return response.data;
  } catch (ex) {
    return Promise.reject(ex);
  }
};

export const getAllAttendants = async (key: string) => {
  try {
    const commandApi = new CommandsApi('getAllAttendants');

    const response = await commandApi.Post<GetAllAttendants>(
      ROUTE_COMMANDS,
      {
        id: uuidv4(),
        to: `${POSTMASTER}@${DESK_MSGING_NET}`,
        method: GET_METHOD,
        uri: ATTENDANTS_ROUTE,
      },
      {
        headers: {
          Authorization: key,
        },
      },
    );
    if (response.data.reason?.code === CommandErrorCodes.NotFound) {
      return { resource: { items: [] as BotAttendant[] } } as GetAllAttendants;
    }
    return response.data;
  } catch (ex) {
    return Promise.reject(ex);
  }
};

export const addNewAttendant = async (key: string, email: string, teams: string[] = ['Default']) => {
  try {
    const commandApi = new CommandsApi('addNewAttendant');

    const response = await commandApi.Post<SetAttendant>(
      ROUTE_COMMANDS,
      {
        id: uuidv4(),
        to: `${POSTMASTER}@${DESK_MSGING_NET}`,
        method: ADD_OR_UPDATE_METHOD,
        uri: ATTENDANTS_ROUTE,
        type: APPLICATION_IRIS_DESK_ATTENDANT_JSON,
        resource: {
          identity: email,
          teams: teams,
        },
      },
      {
        headers: {
          Authorization: key,
        },
      },
    );

    if (!response.succeeded) {
      const reason =
        response.errors?.data?.description ?? response.errors?.message ?? 'Por favor, entre em contato com o suporte';
      throw new Error(`Falha ao adicionar atendente: ${reason}`);
    }

    return response.data;
  } catch (ex) {
    return Promise.reject(ex);
  }
};

export const deleteAttendant = async (key: string, email: string) => {
  try {
    const commandApi = new CommandsApi('deleteAttendant');

    await commandApi.Post(
      ROUTE_COMMANDS,
      {
        id: uuidv4(),
        to: `${POSTMASTER}@${DESK_MSGING_NET}`,
        method: DELETE_METHOD,
        uri: `${ATTENDANTS_ROUTE}/${email}`,
      },
      {
        headers: {
          Authorization: key,
        },
      },
    );

    return true;
  } catch (ex) {
    return Promise.reject(ex);
  }
};

export const getAllQueues = async (key: string) => {
  try {
    const commandApi = new CommandsApi('getAllQueues');

    const response = await commandApi.Post<GetAllQueues>(
      ROUTE_COMMANDS,
      {
        id: uuidv4(),
        to: `${POSTMASTER}@${DESK_MSGING_NET}`,
        method: GET_METHOD,
        uri: ATTENDANCE_QUEUES_ROUTE,
      },
      {
        headers: {
          Authorization: key,
        },
      },
    );

    return response.data;
  } catch (ex) {
    return Promise.reject(ex);
  }
};

export const getAllRules = async (key: string) => {
  try {
    const commandApi = new CommandsApi('getAllRules');

    const response = await commandApi.Post<GetAllRules>(
      ROUTE_COMMANDS,
      {
        id: uuidv4(),
        to: `${POSTMASTER}@${DESK_MSGING_NET}`,
        method: GET_METHOD,
        uri: RULES_ROUTE,
      },
      {
        headers: {
          Authorization: key,
        },
      },
    );

    if (response.data.reason?.code === CommandErrorCodes.NotFound) {
      return { resource: { items: [] as Rule[] } } as GetAllRules;
    }
    return response.data;
  } catch (ex) {
    return Promise.reject(ex);
  }
};

export const setQueue = async (key: string, payload: Queue) => {
  try {
    const commandApi = new CommandsApi('setQueue');

    const response = await commandApi.Post<SetQueue>(
      ROUTE_COMMANDS,
      {
        id: uuidv4(),
        to: `${POSTMASTER}@${DESK_MSGING_NET}`,
        method: ADD_OR_UPDATE_METHOD,
        uri: ATTENDANCE_QUEUES_ROUTE,
        type: APPLICATION_IRIS_DESK_ATTENDANCEQUEUE_JSON,
        resource: payload,
      },
      {
        headers: {
          Authorization: key,
        },
      },
    );

    return response.data;
  } catch (ex) {
    return Promise.reject(ex);
  }
};

export const deleteQueue = async (key: string, id: string) => {
  try {
    const commandApi = new CommandsApi('deleteQueue');

    const response = await commandApi.Post(
      ROUTE_COMMANDS,
      {
        id: uuidv4(),
        to: `${POSTMASTER}@${DESK_MSGING_NET}`,
        method: DELETE_METHOD,
        uri: `${ATTENDANCE_QUEUES_ROUTE}/${id}`,
      },
      {
        headers: {
          Authorization: key,
        },
      },
    );

    return response.data;
  } catch (ex) {
    return Promise.reject(ex);
  }
};

export const setRule = async (key: string, payload: Rule) => {
  try {
    const commandApi = new CommandsApi('setRule');

    const response = await commandApi.Post<SetRule>(
      ROUTE_COMMANDS,
      {
        id: uuidv4(),
        to: `${POSTMASTER}@${DESK_MSGING_NET}`,
        method: ADD_OR_UPDATE_METHOD,
        uri: RULES_ROUTE,
        type: APPLICATION_IRIS_DESK_RULE_JSON,
        resource: payload,
      },
      {
        headers: {
          Authorization: key,
        },
      },
    );

    return response.data;
  } catch (ex) {
    return Promise.reject(ex);
  }
};

export const setContactSimulator = async (key: string, payload: SetContactSimulator) => {
  try {
    const commandApi = new CommandsApi('setContactSimulator');

    const response = await commandApi.Post<SetContactSimulator>(
      ROUTE_COMMANDS,
      {
        id: uuidv4(),
        to: `${POSTMASTER}@${MSGING_NET}`,
        method: MERGE_METHOD,
        uri: CONTACTS_ROUTE,
        type: APPLICATION_IRIS_CONTACT_JSON,
        resource: payload,
      },
      {
        headers: {
          Authorization: key,
        },
      },
    );

    return response.data;
  } catch (ex) {
    return Promise.reject(ex);
  }
};
