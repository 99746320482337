import { PacksApi, TimeOutRequest } from 'configurations/Environment';
import { BaseRestService } from './BaseRestAdapter';

export class PackApiAdapter<T> extends BaseRestService<T> {
  constructor(methodOrigin: string) {
    super(PacksApi, 'PackService', methodOrigin);
  }

  protected setInstanceDefaultHeaders(): void {
    this.getDefaults().headers.common['Content-Type'] = 'application/json';
  }

  protected setInstanceDefaultTimeOut(): void {
    this.getDefaults().timeout = TimeOutRequest;
  }

  protected setInstanceInterceptors(): void {
    this.getInterceptors().response.use(this.handleResponse, this.handleError);
  }
}

export default PackApiAdapter;
