import { AxiosInstance, AxiosResponse } from 'axios';
import { Buffer } from 'buffer';
import {
  ApplicationName,
  CurrentLogLevel,
  LogServerPass,
  LogServerUrl,
  LogServerUser,
  TimeOutRequest,
} from 'configurations/Environment';
import { LogConnector } from 'infra/connectors/LogConnector';
import { ILogService } from 'objects/interfaces/ILogService';
import { Log, LogArgs } from 'objects/types/Log';
import { LogLevel } from 'typescript-logging';
import { Category, CategoryProvider } from 'typescript-logging-category-style';

// Mutate for compatibility with service levels names
export enum LogLevels {
  Debug = LogLevel.Debug,
  Information = LogLevel.Info,
  Warning = LogLevel.Warn,
  Error = LogLevel.Error,
  Fatal = LogLevel.Fatal,
}

export class LogServiceAdapter extends LogConnector implements ILogService {
  private clientBasicAuth: string;
  private clientUser: string;
  private clientPass: string;
  private currentLevel: LogLevel;
  private applicationName: string;
  protected logger: Category;
  protected client: AxiosInstance;

  constructor(baseUrl: string, user: string, pass: string, applicationName: string, currentLevel: LogLevel) {
    super(baseUrl);

    this.applicationName = applicationName;
    this.currentLevel = currentLevel;
    this.clientBasicAuth = '';
    this.clientUser = user;
    this.clientPass = pass;
    this.client = this.httpClient;
    this.logger = this.getLoggerIdentity();

    this.setClientCredentials();
    this.setInstanceDefaults();
  }

  // Interceptors
  private handleResponse = (res: AxiosResponse) => res;
  private handleError = (err: any) => err.response || err;
  private injectHeaders = (req: any) => {
    req.headers = {
      Authorization: `Basic ${this.clientBasicAuth}`,
    };
    return req;
  };

  private getLoggerIdentity() {
    CategoryProvider.clear();

    const level = this.currentLevel;
    const provider = CategoryProvider.createProvider('LoggerProvider', { level });

    return provider.getCategory(this.applicationName);
  }

  private setClientCredentials() {
    const base64Encoded = Buffer.from(`${this.clientUser}:${this.clientPass}`, 'binary').toString('base64');
    this.clientBasicAuth = base64Encoded;
  }

  protected setInstanceDefaults(): void {
    // Sets client defaults param
    this.client.defaults.timeout = TimeOutRequest;

    const hasContentTypeHeader = this.client.defaults.headers.common?.['Content-Type'];

    if (hasContentTypeHeader) {
      this.client.defaults.headers.common['Content-Type'] = 'application/json';
    } else {
      this.client.defaults.headers.common = {
        ...this.client.defaults.headers.common,
        'Content-Type': 'application/json',
      };
    }

    // Sets client default interceptors
    this.client.interceptors.response.use(this.handleResponse, this.handleError);
    this.client.interceptors.request.use(this.injectHeaders);
  }

  private async postLog(log: Log, level: LogLevel) {
    if (process.env.NODE_ENV === 'development') return;

    const resourceServerPath = 'Logger';
    const { message, args } = log;

    try {
      const res = await this.client.post(resourceServerPath, {
        application: 'Packs.BlipGo.Web',
        class: args?.className,
        method: args?.methodName,
        level: LogLevels[level],
        message,
      });

      this.logger.info(message, res);
    } catch (err) {
      this.logger.error('Error sending log to server', err);
    }
  }

  private formatLog = (log: Log) => {
    if (!log.args) {
      log['args'] = {
        className: 'LogServiceAdapter',
        methodName: 'formatLog',
      };
    }

    if (!log.data) {
      log.data = {};
    }

    log.message = `${log.message}`;

    return log;
  };

  Debug = async (message: string, args?: LogArgs, data?: any) => {
    return this.postLog(this.formatLog({ message, args, data }), LogLevel.Debug);
  };

  Info = async (message: string, args?: LogArgs, data?: any) => {
    return this.postLog(this.formatLog({ message, args, data }), LogLevel.Info);
  };

  Warn = async (message: string, args?: LogArgs, data?: any) => {
    return this.postLog(this.formatLog({ message, args, data }), LogLevel.Warn);
  };

  Error = async (message: string, args?: LogArgs, data?: any) => {
    return this.postLog(this.formatLog({ message, args, data }), LogLevel.Error);
  };

  Fatal = async (message: string, args?: LogArgs, data?: any) => {
    return this.postLog(this.formatLog({ message, args, data }), LogLevel.Fatal);
  };
}

const level = LogLevel.toLogLevel(CurrentLogLevel) ?? LogLevel.Info;

export default new LogServiceAdapter(LogServerUrl, LogServerUser, LogServerPass, ApplicationName, level);
