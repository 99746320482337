import styled from 'styled-components';

export const AccountRow = styled.div`
  > * {
    border-top: 1px solid #c7c7c7;
  }

  > *:last-child {
    border-bottom: 1px solid #c7c7c7;
  }
`;
