import ChannelsName from 'constants/ChannelsName';
import { INSTAGRAM_ACTIVATION, WPP_ACTIVATION } from 'constants/Routes';
import { Analytics, Logger } from 'infra/adapters';
import { Channel } from 'objects/types/Channel';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useChannelsModule } from 'redux/slices/ChannelsModuleSlice/channelsModuleSlice';
import { useProject } from 'redux/slices/ProjectSlice/projectSlice';
import { createToastError } from 'services/Toats';

const useChannelRedirects = () => {
  const className = 'ChannelsRedirects';
  const navigate = useNavigate();
  const { activeModule } = useSelector(useProject);
  const { channels } = useSelector(useChannelsModule);

  const redirectToConnect = (item: Channel) => {
    Analytics.Track(Analytics.events.CLICK_CONNECTED_CHANNEL_BUTTON, {
      channel: item.title,
      screen: activeModule.title,
    });
    switch (item.title) {
      case ChannelsName.Whatsapp:
        navigate(WPP_ACTIVATION);
        break;
      case ChannelsName.Instagram:
        navigate(INSTAGRAM_ACTIVATION);
        break;
      default:
        window.open(item.link, '_blank');
        break;
    }
  };

  const redirectToTest = (item: Channel) => {
    const methodName = 'redirectButtonChannelsTest';
    if (item && item.title !== '') {
      Analytics.Track(Analytics.events.CLICK_TEST_CHANNEL_BUTTON, { channel: item.title });
      const blipChatChannel = channels.find(channel => channel.title === ChannelsName.Blipchat);
      window.open(blipChatChannel?.linkToTest, '_blank');
    } else {
      createToastError(`Falha ao obter o link do ${item.title}`, 'Por favor, tente daqui alguns minutos.');
      Logger.Error(`Failed to get link from ${item.title}.`, { methodName, className });
    }
  };

  return {
    redirectToConnect,
    redirectToTest,
  };
};

export default useChannelRedirects;
