import { LARGE_MOBILE_SCREEN } from 'constants/MediaQueryResolutions';
import styled from 'styled-components';

export const BusinessInformationContainer = styled.form`
  display: grid;
  gap: 1rem;
  width: 25rem;

  @media screen and (max-width: ${LARGE_MOBILE_SCREEN}) {
    width: 100%;
  }
`;
