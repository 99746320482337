import styled from 'styled-components';

export const MContainer = styled.div`
  width: 100dvw;
  height: 100dvh;
`;

export const DContainer = styled.div`
  height: calc(100vh - 4rem);
`;
