import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit';
import account from './slices/AccountSlice/accountSlice';
import installation from 'redux/slices/InstallationSlice/installationSlice';
import project from './slices/ProjectSlice/projectSlice';
import channelsModule from './slices/ChannelsModuleSlice/channelsModuleSlice';
import resources from './slices/ResourceSlices/resourceSlice';
import customerService from './slices/CustomerServiceSlice/customerServiceSlice';
import hubspot from './slices/HubspotSlice/HubspotSlice';
import contractInfo from './slices/ContractInfoSlice/contractInfoSlice';
import members from './slices/MembersSlice/membersSlice';

export const myReducers = {
  account,
  installation,
  project,
  channelsModule,
  resources,
  customerService,
  hubspot,
  contractInfo,
  members,
};

const rootReducer = combineReducers(myReducers);

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({ reducer: rootReducer, preloadedState });

export const store = configureStore({ reducer: rootReducer });

export default store;
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
