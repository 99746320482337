import DesktopSidebar from 'components/DesktopSidebar/DesktopSidebar';
import DesktopSubMenu from 'components/DesktopSubMenu/DesktopSubMenu';
import Header from 'components/Header/Header';
import { Modules } from 'constants/Modules';
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector, useDispatch } from 'react-redux';
import { useProject, updateIsMenuOpen } from 'redux/slices/ProjectSlice/projectSlice';
import { DesktopContainer, DesktopPageContent, MainContent } from './style';

interface INavigationBars {
  children: React.ReactNode;
}

const NavigationBars: React.FC<INavigationBars> = ({ children }: INavigationBars) => {
  const dispatch = useDispatch();

  const { activeModule, isMenuOpen } = useSelector(useProject);

  useEffect(() => {
    if (isMobile) {
      handleUpdateIsMenuOpenToFalse();
    }
  }, []);

  const handleUpdateIsMenuOpenToFalse = () => {
    dispatch(updateIsMenuOpen(false));
  };

  return (
    <>
      {activeModule && (
        <>
          <DesktopSidebar />
          <DesktopContainer $isCollapseOpen={isMenuOpen}>
            <Header />
            <MainContent>
              {activeModule.title === Modules.configurations.title && (
                <DesktopSubMenu subPages={Modules.configurations.subPages} />
              )}
              <DesktopPageContent>{children}</DesktopPageContent>
            </MainContent>
          </DesktopContainer>
        </>
      )}
    </>
  );
};

export default NavigationBars;
