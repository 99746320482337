import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IMembersState, Member } from './interface';
import { PROFILE } from 'constants/Profiles';

const InitialState: IMembersState = {
  admins: [],
};

const membersSlice = createSlice({
  name: 'members',
  initialState: InitialState,
  reducers: {
    addMembers(state, payload) {
      const admins = payload.payload.filter((profile: Member) => profile.profileName === PROFILE.admin.name);
      return {
        ...state,
        admins,
      };
    },
    addAdmin(state, { payload }: PayloadAction<Member>) {
      return {
        ...state,
        admins: [...state.admins, payload],
      };
    },
    removeAdmin(state, { payload }: PayloadAction<number>) {
      return {
        admins: state.admins.filter(admin => admin.id !== payload),
      };
    },
  },
});

export default membersSlice.reducer;

export const { addMembers, addAdmin, removeAdmin } = membersSlice.actions;

export const useMembers = (state: { members: IMembersState }) => state.members;
