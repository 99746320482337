import blipColors from 'blip-tokens/build/json/colors.json';
import { WIDTH_COLLAPSE_CLOSE, WIDTH_COLLAPSE_OPEN } from 'constants/Desktop';
import styled from 'styled-components';

interface IProps {
  $isCollapseOpen?: boolean;
}

export const DesktopContainer = styled.div<IProps>`
  position: fixed;
  width: ${({ $isCollapseOpen }) =>
    $isCollapseOpen ? `calc(100dvw - ${WIDTH_COLLAPSE_OPEN});` : `calc(100dvw - ${WIDTH_COLLAPSE_CLOSE});`};
  height: 100vh;
  z-index: -1;
  margin-left: ${({ $isCollapseOpen }) => ($isCollapseOpen ? '206px' : '64px')};
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  margin-bottom: 24px;

  h1 {
    font-size: 32px;
    line-height: 100%;
  }

  #logout {
    color: ${blipColors['color-primary-night']};
    cursor: pointer;
  }

  #title {
    color: rgb(20, 20, 20);
  }
`;

export const Flex = styled.div`
  display: flex;
`;

export const MainContent = styled.div`
  display: flex;
`;

export const DesktopPageContent = styled.div`
  width: 100dvw;
  height: 100dvh;
  background: #f6f6f6;
`;
