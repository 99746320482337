import styled from 'styled-components';

export const GrayContainer = styled.div`
  background-color: #e0e0e0;
  border-radius: 1rem;
  height: auto;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
`;

export const ImageContainer = styled.div`
  width: 360px;
  cursor: pointer;
`;
