import axios, { AxiosInstance } from 'axios';

/**
 * Connects to an http Rest service over Axios http client
 * @constructor Requires an service url string
 *
 * @param baseUrl a service URL as `string` for consumer
 */
export class HttpConnector {
  protected httpClient: AxiosInstance;

  public constructor(baseUrl: string) {
    this.httpClient = axios.create({
      baseURL: baseUrl,
    });
  }
}
