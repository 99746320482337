import { BotChannels } from 'objects/types/BotChannels';

export function isInstagramChannelActive(data: BotChannels): boolean {
  if (!data.instagram) {
    return false;
  }

  for (const item of data.instagram) {
    if (item.name.toLocaleLowerCase() === 'ischannelactive' && item.value.toLocaleLowerCase() === 'true') {
      return true;
    }
  }
  return false;
}

export function isMessengerChannelActive(data: BotChannels): boolean {
  if (!data.messenger) {
    return false;
  }

  for (const item of data.messenger) {
    if (item.name.toLocaleLowerCase() === 'ischannelactive' && item.value.toLocaleLowerCase() === 'true') {
      return true;
    }
  }
  return false;
}

export function isWhatsAppChannelActive(data: BotChannels): boolean {
  if (!data.wa) {
    return false;
  }

  for (const item of data.wa) {
    if (item.name.toLocaleLowerCase() === 'ischannelactive' && item.value.toLocaleLowerCase() === 'true') {
      return true;
    }
  }
  return false;
}
