export const PLAN_UPGRADE_INFO = [
  {
    label: 'Canal WhatsApp',
    freeIncluded: false,
    proIncluded: true,
    showWhatsappLogo: true,
    additionalInfoFree: '',
    additionalInfoPro: '',
    tooltipText: 'Somos parceiros oficial Meta, utilize o canal sem riscos!',
  },
  {
    label: '1 número de telefone para todos os atendentes',
    freeIncluded: false,
    proIncluded: true,
    showWhatsappLogo: false,
    additionalInfoFree: '',
    additionalInfoPro: '',
    tooltipText: '',
  },
  {
    label: 'Campanhas de marketing via WhatsApp',
    freeIncluded: false,
    proIncluded: true,
    showWhatsappLogo: false,
    additionalInfoFree: '',
    additionalInfoPro: '',
    tooltipText:
      'Aumente a visibilidade da sua marca, promova produtos e serviços de forma personalizada e conquiste seus clientes!',
  },
  {
    label: 'Celulares com o WhatsApp Blip Go!',
    freeIncluded: false,
    proIncluded: true,
    showWhatsappLogo: false,
    additionalInfoFree: '',
    additionalInfoPro: '7',
    tooltipText: 'Conecte o aplicativo com o WhatsApp Blip Go! em até 07 celulares e de mais liberdade a sua equipe!',
  },
  {
    label: 'Atendentes',
    freeIncluded: true,
    proIncluded: true,
    showWhatsappLogo: false,
    additionalInfoFree: '5',
    additionalInfoPro: '7',
    tooltipText: 'É possível contratar atendentes extras no valor de R$ 150,00 por cada, até um limite de 10.',
  },
  {
    label: 'Equipes de atendimento',
    freeIncluded: true,
    proIncluded: true,
    showWhatsappLogo: false,
    additionalInfoFree: '3',
    additionalInfoPro: '5',
    tooltipText: '',
  },
  {
    label: 'Conversas',
    freeIncluded: true,
    proIncluded: true,
    showWhatsappLogo: false,
    additionalInfoFree: '200/mês',
    additionalInfoPro: '450/mês',
    tooltipText: 'As conversas são consideradas por cada usuário único e contabilizadas por mês ',
  },
  {
    label: 'Valor por conversa extra',
    freeIncluded: false,
    proIncluded: true,
    showWhatsappLogo: false,
    additionalInfoFree: '',
    additionalInfoPro: '',
    tooltipText: 'Caso o limite seja excedido, é cobrado o valor de R$ 1,00 por cada conversa extra',
  },
];

export const PRO_PLAN_INFO = {
  general: {
    intro:
      'Para ativar o <b>WhatsApp</b> e aproveitar todos os recursos disponíveis, é necessário fazer o upgrade para o <b>Blip Go! PRO</b>.',
    title: 'Aproveite recursos exclusivos com o Blip Go! PRO!',
  },

  activeMessage: {
    intro: 'Para aproveitar todos os recursos disponíveis, é necessário fazer o upgrade para o <b>Blip Go! PRO</b>. ',
    title: 'Aproveite recursos exclusivos com o Blip Go! PRO!',
  },
};
