import { WebStorageStateStore } from 'oidc-client';
import { AcntScope, Authority, ClientId, ResponseType, TokenExpiringMin } from '../configurations/Environment';

const CURRENT_DOMAIN = `${window.location.origin}/`;
const CLIENT_SECRETS = [
  '}A n2p,Y$:g^Rt61fI[0~3-Q2NDPo6aPMcu"OcaN"N<SY',
  " O/>+nW=x)~f|;1wH8J#=gZ4O.Wo^'],[@FL#pc@a>&gL",
  '"1a+/Bi^$"f?59FwodX5":WglnOHUA$fgrC8y8y)$A@1 % ',
  "{H!gD!$lTt(kgP6,}-<KY`Si,q_C{;m'>(>,.)(203L,",
];

export const getOidcBaseConfig = (urlRedirect: string) => {
  return {
    authority: Authority,
    clientId: ClientId,
    redirectUri: urlRedirect,
    responseType: ResponseType,
    scope: AcntScope,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    automaticSilentRenew: true,
    silent_redirect_uri: `${CURRENT_DOMAIN}renew-callback.html`,
    accessTokenExpiringNotificationTime: (TokenExpiringMin || 5) * 60,
    clientSecret: CLIENT_SECRETS[Math.floor(Math.random() * CLIENT_SECRETS.length)],
  };
};
