import { createAsyncThunk } from '@reduxjs/toolkit';
import { IUpdateContactPropertiesHubspot } from './interface';
import { updateContactStatus } from 'services/PackService';
import { getSpecificResource, getValidatePageToken } from 'services/CommandsService';
import UpdateContactHubspot from 'constants/ContactStatusHubspot';
import ChannelsName from 'constants/ChannelsName';

export const updateContactPropertiesHubspot = createAsyncThunk(
    'hubspot/updateContactPropertiesHubspot',
    async ({ token, properties }: IUpdateContactPropertiesHubspot, { rejectWithValue }) => {
        try {
            await updateContactStatus(properties, token);

            if (properties[0].value === UpdateContactHubspot.ProPlanRequsted) {
                return true;
            }

            return false;
        } catch (ex) {
            const error = ex as Error;
            return rejectWithValue(error.message);
        }
    }
);

export const fetchIsProPlanRequested = createAsyncThunk(
    'hubspot/fetchIsProPlanRequested',
    async (key: string, { rejectWithValue }) => {
        try {
            const response = await getSpecificResource(key, UpdateContactHubspot.ProPlanRequestedKey);

            return response.resource ?? '';
        } catch (ex) {
            const error = ex as Error;
            return rejectWithValue(error.message);
        }
    }
);

export const fetchIsInstagramActive = createAsyncThunk(
    'hubspot/fetchIsInstagramActive',
    async (key: string, { rejectWithValue }) => {
        try {
            const response = await getValidatePageToken(key, ChannelsName.Instagram.toLowerCase());

            return (response.resource.IsValidated && response.resource.IsValidAccessToken) ?? false;
        } catch (ex) {
            const error = ex as Error;
            return rejectWithValue(error.message);
        }
    }
);

export const fetchIsMessengerActive = createAsyncThunk(
    'hubspot/fetchIsMessengerActive',
    async (key: string, { rejectWithValue }) => {
        try {
            const response = await getValidatePageToken(key, ChannelsName.Messenger.toLowerCase());

            return (response.resource.IsValidated && response.resource.IsValidAccessToken) ?? false;
        } catch (ex) {
            const error = ex as Error;
            return rejectWithValue(error.message);
        }
    }
);