import { BdsGrid, BdsIcon, BdsTypo } from 'blip-ds/dist/blip-ds-react';

interface IModalSubItem {
  itemLabel: string;
}

export const ModalSubItem = ({ itemLabel }: IModalSubItem) => {
  return (
    <BdsGrid gap="2">
      <BdsIcon name="checkball" theme="solid" style={{ color: '#00CD74' }} />
      <BdsTypo variant="fs-14">{itemLabel}</BdsTypo>
    </BdsGrid>
  );
};
