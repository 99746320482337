import styled from 'styled-components';

export const Content = styled.div`
  width: 560px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const Simulator = styled.div`
  width: 340px;
  height: 729px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SpaceTextInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 536px;
  height: 120px;
  padding: 0;
  padding-right: 16px;
  margin-bottom: 16px;
  gap: 16px;
`;
