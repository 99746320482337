import { createAsyncThunk } from '@reduxjs/toolkit';
import { IFetchBlipChatConfigKey } from './interface';
import { getBlipChatConfigKey } from 'services/PackService';

export const fetchBlipChatConfigKey = createAsyncThunk(
    'channelsModule/fetchBlipChatConfigKey',
    async ({ botShortName, token, testUrl }: IFetchBlipChatConfigKey, { rejectWithValue }) => {
        try {
            const response = await getBlipChatConfigKey(botShortName, token);

            return testUrl + response.appKey;
        } catch (ex) {
            const error = ex as Error;
            return rejectWithValue(error.message);
        }
    }
);