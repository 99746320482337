// https://docs.appcues.com/article/161-javascript-api

import { Logger } from 'infra/adapters';
import { LogArgs } from 'objects/types/Log';

declare const Appcues: any;

const ADD_BLOCK_ERROR_MESSAGE = '. Please, check if you have adblock enabled.';
const className = 'AppCues.tsx';

export interface AppCuesEvent {
  id: string;
  name: string;
  flowId: string;
  flowName: string;
  flowType: string;
  flowVersion: number;
  timestamp: number;
  sessionId: number;
  stepId: string;
  stepType: string;
}

export enum AppCuesEvents {
  FlowStarted = 'flow_started',
  FlowCompleted = 'flow_completed',
  FlowSkipped = 'flow_skipped',
  StepInteracted = 'step_interacted',
  FormSubmitted = 'form_submitted',
  FormFieldSubmitted = 'form_field_submitted',
}

export class AppCuesService {
  private static _instance: AppCuesService;
  private registeredEvents: Record<string, Record<string, (event: AppCuesEvent) => Promise<void>>> = {};

  user: any[] | undefined;

  private constructor() {
    if (AppCuesService._instance) {
      throw new Error('AppCuesService is a singleton. Use instance property instead of constructor');
    }
  }

  /**
   * Returns a singleton instance
   */
  public static get instance(): AppCuesService {
    AppCuesService._instance = AppCuesService._instance || new AppCuesService();
    return AppCuesService._instance;
  }

  /**
   * Identify user and add it to singleton class
   * @param args Appcues identify params
   */
  identify(...args: any[]): void {
    const methodName = 'identify';
    try {
      if (Appcues) {
        Appcues.identify(...args);

        Logger.Info('AppCues has been successfully initialized',
          {
            className: 'AppCuesService',
            methodName: 'identify'
          } as LogArgs
        );
      }
    } catch (e: any) {
      e.message += ADD_BLOCK_ERROR_MESSAGE;

      Logger.Error(e, {
        className,
        methodName,
      });
    }
  }

  /**
   * Start AppCues on page
   */
  start(): void {
    const methodName = 'start';
    try {
      if (Appcues) {
        Appcues.start();
      }
    } catch (e: any) {
      e.message += ADD_BLOCK_ERROR_MESSAGE;
      Logger.Error(e, {
        className,
        methodName,
      });
    }
  }

  /**
   * Executes a callback based on a flow event
   * @param event The event that happened (started, completed, etc.)
   * @param flowId The id of AppCues flow
   * @param callback The method that shold be executed
   */
  onEvent(event: AppCuesEvents, flowId: string, callback: (event?: AppCuesEvent) => Promise<void>): void {
    const methodName = 'onEvent';
    try {
      if (!Appcues) {
        return;
      }

      if (this.registeredEvents[event] === undefined) {
        Appcues.on(event, async (appCuesEvent: AppCuesEvent) => {
          if (this.registeredEvents[appCuesEvent.id] && this.registeredEvents[appCuesEvent.id][appCuesEvent.flowId]) {
            await this.registeredEvents[appCuesEvent.id][appCuesEvent.flowId](appCuesEvent);
          }
        });
      }

      this.registeredEvents[event] = { ...this.registeredEvents[event], [flowId]: callback };
    } catch (e: any) {
      e.message += ADD_BLOCK_ERROR_MESSAGE;
      Logger.Error(e, {
        className,
        methodName,
      });
    }
  }

  /**
   * Remove a callback execution based on a flow event
   * @param event The event that happened (started, completed, etc.)
   * @param flowId The id of AppCues flow
   */
  offEvent(event: AppCuesEvents, flowId: string): void {
    const methodName = 'offEvent';
    try {
      if (!Appcues) {
        return;
      }

      if (this.registeredEvents[event] && this.registeredEvents[event][flowId]) {
        this.registeredEvents[event][flowId];
      }
    } catch (e: any) {
      e.message += ADD_BLOCK_ERROR_MESSAGE;
      Logger.Error(e, {
        className,
        methodName,
      });
    }
  }
}
