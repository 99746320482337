import { GraphApiUrl } from 'configurations/Environment';
import { BaseRestService } from './BaseRestAdapter';

export class GraphApiAdapter<T> extends BaseRestService<T> {
    constructor(methodOrigin: string) {
        super(GraphApiUrl, 'GraphApiService', methodOrigin);
    }

    protected setInstanceDefaultHeaders(): void {
        this.getDefaults().headers.common['Content-Type'] = 'application/json';
    }

    protected setInstanceDefaultTimeOut(): void {
        this.getDefaults().timeout = 5000;
    }

    protected setInstanceInterceptors(): void {
        this.getInterceptors().response.use(this.handleResponse, this.handleError);
    }
}

export default GraphApiAdapter;
