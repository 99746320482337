const UpdateContactHubspot = {
    Status: 'blip_go_status_do_contato',
    AccountCreated: 'Conta criada',
    BlipGoInstalled: 'Blip Go! instalado',
    BlipGoFreeConnected: 'Blip Go! Free conectado',
    ProPlanRequsted: 'Plano PRO solicitado',
    ProPlanActive: 'Plano PRO ativo',
    ProPlanRequestedKey: 'isProPlanRequested'
};

export default UpdateContactHubspot;