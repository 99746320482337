import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import Attendant from 'objects/types/Attendant';
import Queue from 'objects/types/Queue';
import Rule from 'objects/types/Rule';
import RuleWithOldQueue from 'objects/types/RuleWithOldQueue';
import { hasElementByIndex } from 'utils/Array';
import { ICustomerServiceState } from './interface';
import {
  addAttendant,
  fetchAllAttendants,
  fetchAllQueues,
  fetchAllRules,
  removeAttendant,
  updateAttendant,
} from './thunkMiddleware';

const InitialState: ICustomerServiceState = {
  attendants: [] as Attendant[],
  queues: [] as Queue[],
  rules: [] as Rule[],
};

const customerServiceSlice = createSlice({
  name: 'customerServiceSlice',
  initialState: InitialState,
  reducers: {
    updateStateAttendants(state, { payload }: PayloadAction<Attendant[]>) {
      state.attendants = payload;
    },
    updateStateQueue(state, { payload }: PayloadAction<Queue>) {
      const findQueueIndex = state.queues.findIndex(queue => queue.id === payload.id);

      if (!hasElementByIndex(findQueueIndex)) {
        state.queues = [...state.queues, payload];
      } else {
        const updateQueues = state.queues.map(updateQueue => {
          if (updateQueue.id === payload.id) {
            return {
              ...updateQueue,
              name: payload.name,
              queueId: payload.queueId,
              uniqueId: payload.uniqueId,
            };
          }

          return updateQueue;
        });

        state.queues = updateQueues;
      }
    },
    updateQueuesAnRules(state, { payload }: PayloadAction<Queue[]>) {
      const filteredTeams = payload.map(queue => queue.name);
      const updateRules = state.rules.filter(rule => filteredTeams.includes(rule.team));

      state.queues = payload;
      state.rules = updateRules;
    },
    updateStateRule(state, { payload }: PayloadAction<RuleWithOldQueue>) {
      const findRuleIndex = state.rules.findIndex(rule => rule.team === payload.oldQueueName);

      if (!hasElementByIndex(findRuleIndex)) {
        state.rules = [...state.rules, payload.rule];
      } else {
        const updateRules = state.rules.map((updateRule: Rule) => {
          if (updateRule.team === payload.oldQueueName) {
            return {
              ...updateRule,
              team: payload.rule.team,
              title: payload.rule.title,
              values: payload.rule.values,
              queueId: payload.rule.queueId,
              conditions: payload.rule.conditions,
            };
          }

          return updateRule;
        });

        state.rules = updateRules;
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAllAttendants.fulfilled, (state, { payload }: PayloadAction<Attendant[]>) => {
        state.attendants = payload;
      })
      .addCase(addAttendant.fulfilled, (state, { payload }: PayloadAction<Attendant>) => {
        state.attendants = [...state.attendants, payload];
      })
      .addCase(updateAttendant.fulfilled, (state, { payload }: PayloadAction<Attendant>) => {
        const updateAttendants = state.attendants.map(attendant => {
          if (attendant.email === payload.email) {
            return { ...attendant, teams: payload.teams };
          }

          return attendant;
        });

        state.attendants = updateAttendants;
      })
      .addCase(removeAttendant.fulfilled, (state, { payload }: PayloadAction<Attendant>) => {
        state.attendants = state.attendants.filter(attendant => {
          return attendant.email !== payload.email;
        });
      })
      .addCase(fetchAllQueues.fulfilled, (state, { payload }: PayloadAction<Queue[]>) => {
        state.queues = payload;
      })
      .addCase(fetchAllRules.fulfilled, (state, { payload }: PayloadAction<Rule[]>) => {
        state.rules = payload;
      });
  },
});

export default customerServiceSlice.reducer;

export const { updateStateAttendants, updateStateQueue, updateQueuesAnRules, updateStateRule } =
  customerServiceSlice.actions;

export const useCustomerService = (state: { customerService: ICustomerServiceState }) => state.customerService;
