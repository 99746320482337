import { BdsGrid, BdsImage } from 'blip-ds/dist/blip-ds-react';
import { useEffect, useState } from 'react';
import defaultLogoBlipGo from '../../assets/LogoBlipGo.png';
import { ONBOARDING_STEPPER } from '../../constants/SelfOnboard';
import { BusinessInformation } from './components/BusinessInformation/BusinessInformation';
import { CompanyObjectives } from './components/CompanyObjectives/CompanyObjectives';
import { PersonalizeService } from './components/PersonalizeService/PersonalizeService';
import { StepperContent, StepperItem } from './styles';

export const Onboarding = () => {
  const [activeStep, setActiveStep] = useState(ONBOARDING_STEPPER.companyData);

  useEffect(() => {
    if (activeStep > ONBOARDING_STEPPER.personalizeService) {
      //todo navigate para tela de instalação
    }
  }, [activeStep]);

  const isTabActive = (index: number) => {
    return activeStep === index;
  };

  const handleNextPage = async () => {
    setActiveStep(activeStep + 1);
  };

  const StepContent = () => {
    switch (activeStep) {
      case ONBOARDING_STEPPER.companyData:
        return <BusinessInformation nextPageEvent={handleNextPage} />;
      case ONBOARDING_STEPPER.companyObjectives:
        return <CompanyObjectives nextPageEvent={handleNextPage} />;
      default:
        return <PersonalizeService nextPageEvent={handleNextPage} />;
    }
  };

  return (
    <BdsGrid direction="row" justifyContent="center">
      <BdsGrid
        margin="t-7"
        justifyContent="center"
        style={{ left: '18.875rem', position: 'relative', width: '10.25rem', height: '2.5rem' }}
        data-testid="blipGo-logo"
      >
        <BdsImage src={defaultLogoBlipGo} />
      </BdsGrid>
      <BdsGrid direction="column">
        <BdsGrid gap="4" style={{ position: 'relative', paddingTop: '152px', width: '560px' }}>
          {<StepContent />}
        </BdsGrid>
        <BdsGrid direction="row">
          <StepperContent id="stepper">
            <StepperItem $isActive={isTabActive(ONBOARDING_STEPPER.companyData)} data-testid="company-data" />
            <StepperItem
              $isActive={isTabActive(ONBOARDING_STEPPER.companyObjectives)}
              data-testid="company-objectives"
            />
            <StepperItem
              $isActive={isTabActive(ONBOARDING_STEPPER.personalizeService)}
              data-testid="personalize-service"
            />
          </StepperContent>
        </BdsGrid>
      </BdsGrid>
    </BdsGrid>
  );
};
