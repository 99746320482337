import styled from 'styled-components';

export const PaywallContainer = styled.div`
  width: 100%;
  margin: auto;

  .paywall-main-grid {
    margin: 30px auto;
    width: 1000px;
  }

  .paywall-title {
    color: var(--color-primary);
    width: 550px;
  }

  .paywall-header-container {
    width: 450px;
    height: 125px;
    border-bottom: 1px var(--color-surface-3) solid;
  }

  .paywall-plan-name {
    margin-bottom: 10px;
  }

  .paywall-free-header {
    width: 100%;
    background: var(--color-surface-2);
    border-top-left-radius: 12px;
  }

  .paywall-pro-header {
    width: 100%;
    background: var(--color-primary);
    border-top-right-radius: 12px;
  }

  .white-color {
    color: #fff;
  }
`;

export const ScrollableTableContainer = styled.div`
  overflow-y: auto;
  height: calc(100vh - 156px);
`;

export const PaywallTableDivider = styled.div`
  width: 100%;
  height: 1px;
  background: #c7c7c7;
`;

export const PlanCancelInfo = styled.div`
  margin-top: 10px;
`;
