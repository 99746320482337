import {
  BdsAvatar,
  BdsButton,
  BdsGrid,
  BdsIcon,
  BdsLoadingSpinner,
  BdsModal,
  BdsStep,
  BdsStepper,
  BdsTypo,
} from 'blip-ds/dist/blip-ds-react/components';
import FacebookConectedAccount from 'components/Instagram/FacebookConectedAccount';
import {
  INSTAGRAM_ACTIVATION_ACTIVATE,
  INSTAGRAM_ACTIVATION_BIND_PAGES,
  INSTAGRAM_ACTIVATION_FACEBOOK_CONNECTION,
} from 'constants/Routes';
import { Paper } from 'globalStyles';
import useGraphApi from 'hooks/useGraphApi';
import { Analytics } from 'infra/adapters';
import { FacebookPage, User } from 'objects/types/Facebook';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountRow } from './style';

function BusinessAccounts() {
  const [pageList, setPageList] = useState<FacebookPage[] | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);
  const { getFacebookPages } = useGraphApi();
  const navigate = useNavigate();
  const locationState = useLocation()?.state as { user: User; token: string };
  const hasFacebookPages = !!pageList?.length;

  const fetchAndSaveFacebookPages = async () => {
    if (!locationState?.token || !locationState?.user) {
      return navigate(INSTAGRAM_ACTIVATION_FACEBOOK_CONNECTION);
    }
    const pageList = await getFacebookPages(locationState.user.id, locationState.token);
    pageList.length > 0 && Analytics.Track(Analytics.events.INSTAGRAM_ACTIVATION_CHOOSE_PAGE_PAGE);
    setPageList(pageList);
  };

  const handleUserPageClick = (page: FacebookPage) => {
    if (!page.connected_instagram_account) {
      return navigate(INSTAGRAM_ACTIVATION_BIND_PAGES, { state: { page, user: locationState.user } });
    }
    return navigate(INSTAGRAM_ACTIVATION_ACTIVATE, { state: { page, user: locationState.user } });
  };

  useEffect(() => {
    fetchAndSaveFacebookPages();
  }, []);

  if (!pageList) {
    return (
      <BdsGrid justifyContent="center" alignItems="center">
        <BdsLoadingSpinner />
      </BdsGrid>
    );
  }

  return (
    <BdsGrid justifyContent="center" alignItems="center" direction="row">
      <Paper>
        <BdsGrid padding="3" direction="column" justifyContent="center">
          <BdsStepper>
            <BdsStep active>Selecionar página</BdsStep>
            <BdsStep>Vincular contas</BdsStep>
            <BdsStep>Conectar Instagram</BdsStep>
          </BdsStepper>
          <BdsGrid gap="4" justifyContent="center" margin="t-3">
            <FacebookConectedAccount
              hasFacebookPages={hasFacebookPages}
              user={locationState.user}
              token={locationState.token}
            />
            {hasFacebookPages ? (
              <BdsGrid gap="4" direction="column">
                <BdsTypo tag="span" variant="fs-24" bold="bold">
                  Selecione a página da sua empresa
                </BdsTypo>
                <BdsTypo tag="p">
                  Veja abaixo as <b>páginas comerciais</b> do Facebook que a conta {locationState?.user.name} tem acesso
                  e escolha a que você usa para <b>a sua empresa:</b>
                </BdsTypo>
                <AccountRow>
                  {pageList.map(page => {
                    return (
                      <BdsGrid
                        key={page.id}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        padding="y-2"
                      >
                        <BdsGrid gap="3" alignItems="center" padding="l-3">
                          <BdsAvatar id="avatar" name={page.name} size="standard" />
                          <BdsTypo variant="fs-16" bold="bold">
                            {page.name}
                          </BdsTypo>
                        </BdsGrid>
                        <BdsGrid padding="r-3">
                          <BdsButton
                            variant="tertiary"
                            onBdsClick={() => {
                              Analytics.Track(Analytics.events.INSTAGRAM_ACTIVATION_CHOOSE_PAGE_BUTTON);
                              handleUserPageClick(page);
                            }}
                          >
                            Usar essa página
                          </BdsButton>
                        </BdsGrid>
                      </BdsGrid>
                    );
                  })}
                </AccountRow>
                <BdsButton
                  variant="secondary"
                  icon="info"
                  onBdsClick={() => {
                    Analytics.Track(Analytics.events.INSTAGRAM_ACTIVATION_SHOW_NO_PAGE_FOUND_MODAL);
                    setShowModal(prev => !prev);
                  }}
                >
                  Não encontrei minha página
                </BdsButton>
              </BdsGrid>
            ) : (
              <NoPagesFound retryHandler={fetchAndSaveFacebookPages} />
            )}
          </BdsGrid>
        </BdsGrid>
      </Paper>
      <NoPageFoundModal showModal={showModal} setShowModal={setShowModal} />
    </BdsGrid>
  );
}

const NoPagesFound = ({ retryHandler }: { retryHandler: () => Promise<void> }) => {
  const { logoutFacebook } = useGraphApi();
  const navigate = useNavigate();
  const locationState = useLocation()?.state as { user: User; token: string };
  const [loading, setLoading] = useState(false);

  const createNewPageHandler = () => {
    window.open('https://www.facebook.com/pages/creation/', '_blank', 'noopener');
  };

  const changeAccountHandler = async () => {
    await logoutFacebook(locationState.user.id, locationState.token);
    return navigate(INSTAGRAM_ACTIVATION_FACEBOOK_CONNECTION);
  };

  const tryAgain = async () => {
    setLoading(true);
    await retryHandler();
    setLoading(false);
  };

  useEffect(() => {
    Analytics.Track(Analytics.events.INSTAGRAM_ACTIVATION_ERROR, { error: 'No pages found for logged account' });
  }, []);

  return (
    <>
      <BdsGrid direction="column" gap="3">
        <BdsTypo variant="fs-24" bold="bold" tag="h3">
          Não encontramos páginas do Facebook para essa conta.
        </BdsTypo>
        <BdsGrid gap="1">
          <BdsIcon name="blip-ideas" />
          <BdsTypo bold="bold" tag="p">
            Como resolver:
          </BdsTypo>
        </BdsGrid>
        <BdsTypo variant="fs-14" tag="p">
          <li>
            Caso sua empresa ainda não tenha uma página,&nbsp;
            <u
              style={{ cursor: 'pointer' }}
              onClick={() => {
                Analytics.Track(Analytics.events.INSTAGRAM_ACTIVATION_NO_ACCOUNTS_FOUND_CREATE_PAGE_LINK);
                createNewPageHandler();
              }}
            >
              crie uma nova página no Facebook.
            </u>
            &nbsp;Quando finalizar, conecte novamente.
          </li>
        </BdsTypo>
        <BdsGrid padding="l-1">
          <BdsButton
            variant="tertiary"
            bdsLoading={loading}
            onBdsClick={() => {
              Analytics.Track(Analytics.events.INSTAGRAM_ACTIVATION_NO_ACCOUNTS_FOUND_TRY_AGAIN_BUTTON);
              tryAgain();
            }}
          >
            Tentar novamente
          </BdsButton>
        </BdsGrid>
        <BdsTypo variant="fs-14" tag="p">
          <li>
            Caso sua página seja administrada por outra conta de Facebook,
            <u
              style={{ cursor: 'pointer' }}
              onClick={() => {
                Analytics.Track(Analytics.events.INSTAGRAM_ACTIVATION_NO_ACCOUNTS_FOUND_CHANGE_ACCOUNT_LINK);
                changeAccountHandler();
              }}
            >
              &nbsp;troque de conta.
            </u>
          </li>
        </BdsTypo>
      </BdsGrid>
    </>
  );
};

const NoPageFoundModal = ({
  showModal,
  setShowModal,
}: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const locationState = useLocation()?.state as { user: User; token: string };
  const { logoutFacebook } = useGraphApi();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const changeUserHandler = async () => {
    setLoading(true);
    await logoutFacebook(locationState.user.id, locationState.token);
    setLoading(false);
    setShowModal(false);
    navigate(INSTAGRAM_ACTIVATION_FACEBOOK_CONNECTION);
  };

  return (
    <BdsModal size="dynamic" open={showModal}>
      <BdsGrid direction="column" gap="1">
        <BdsTypo variant="fs-24" bold="bold" tag="h4">
          Se você não encontrou a página que queria usar:
        </BdsTypo>
        <BdsTypo variant="fs-14">
          1 - A página o usuário conectado não tem acesso a essa página. Nesse caso você pode:
        </BdsTypo>
        <ul style={{ paddingLeft: '2rem' }}>
          <li>
            <BdsTypo variant="fs-14">Pedir para quem alguma pessoa administradora te adicione à página</BdsTypo>
          </li>
          <li>
            <BdsTypo variant="fs-14">Conectar com um usuário que tem acesso a essa conta</BdsTypo>
          </li>
        </ul>
        <BdsButton
          variant="tertiary"
          bdsLoading={loading}
          onBdsClick={() => {
            Analytics.Track(Analytics.events.INSTAGRAM_ACTIVATION_CHANGE_ACCOUNT_BUTTON);
            changeUserHandler();
          }}
          icon="arrow-left"
        >
          Trocar usuário
        </BdsButton>
        <br />
        <BdsTypo variant="fs-14">
          2 - A página ainda não foi criada. Nesse caso, você pode criar uma nova página diretamente no Facebook.
        </BdsTypo>
        <BdsButton
          variant="tertiary"
          icon="facebook"
          onBdsClick={() => {
            Analytics.Track(Analytics.events.INSTAGRAM_ACTIVATION_CREATE_NEW_PAGE_MODAL);
            setShowModal(false);
            window.open('https://www.facebook.com/pages/creation/', '_blank', 'noopener');
          }}
        >
          Criar nova página
        </BdsButton>
      </BdsGrid>
    </BdsModal>
  );
};

export default BusinessAccounts;
