import { Modules } from 'constants/Modules';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { addActiveSubPage } from 'redux/slices/ProjectSlice/projectSlice';

const DEFAULT_PAGE = Modules.configurations.subPages[0];

function useConfigurationPagesManager() {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const subPageHandler = () => {
      const subPage = Modules.configurations.subPages.find(subPage => subPage.route === location.pathname);
      dispatch(addActiveSubPage(subPage ?? DEFAULT_PAGE));
    };
    subPageHandler();
  }, [location.pathname]);
}

export default useConfigurationPagesManager;
