import { BdsGrid, BdsInputChips, BdsLoadingSpinner, BdsSwitch, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import SubPageHeader from 'components/SubPageHeader/SubPageHeader';
import { Analytics } from 'infra/adapters';
import ConfigurationsContainer from 'pages/Configurations/components/ConfigurationsContainer/ConfigurationsContainer';
import { useEffect, useRef, useState } from 'react';
import ConfigurationsModuleTexts from '../../../utils/texts/ConfigurationsModuleTexts.json';
import Attendance from './Components/Attendance';
import Offs from './Components/Offs';
import { useOpeningHours } from './hooks/useOpeningHours';
import { Content, SpaceTextInput } from './styles';

const OpeningHours: React.FC = () => {
  const {
    initHook,
    twentyFourHours,
    setTwentyFourHours,
    handleHolidaysChips,
    infoLoaded,
    schedule,
    handleToggleActive,
    handleUpdateShifts,
    saveDeskSchedule,
    chipsArray,
    chipsError,
    activeHolidaySwitch,
    setActiveHolidaySwitch,
  } = useOpeningHours();

  const [isModified, setIsModified] = useState(false);
  const effectAlreadyRan = useRef(false);

  useEffect(() => {
    if (effectAlreadyRan.current || process.env.NODE_ENV !== 'development') {
      initHook();
    }
    return () => {
      effectAlreadyRan.current = true;
    };
  }, []);

  const handle24hToggle = () => {
    Analytics.Track(Analytics.events.ATTENDANCE_24H, { status: !twentyFourHours });
    setIsModified(!isModified);
    setTwentyFourHours(!twentyFourHours);
  };

  const handleHolidaysToggle = (toggleActivated: boolean) => {
    Analytics.Track(Analytics.events.ATTENDANCE_HOLIDAYS_ACTIVATE, { status: toggleActivated });
    setActiveHolidaySwitch(toggleActivated);
  };

  if (!infoLoaded)
    return (
      <BdsGrid justifyContent="center" alignItems="center" height="100%">
        <BdsLoadingSpinner />
      </BdsGrid>
    );

  return (
    <>
      <SubPageHeader
        showTestButton
        onSave={() => {
          setIsModified(false);
          saveDeskSchedule();
        }}
      />
      <ConfigurationsContainer>
        <Content>
          Personalize os dias, horários e feriados que o seu atendimento humano estará disponível.
          <SpaceTextInput>
            <BdsGrid gap="2">
              <BdsSwitch
                refer=""
                name="twentyFourHours"
                checked={twentyFourHours}
                onBdsChange={handle24hToggle}
                data-testid="switchTwentyFourHours"
              />
              <BdsTypo className="typo" variant="fs-14">
                {ConfigurationsModuleTexts.schedules.twentyFourHours}
              </BdsTypo>
            </BdsGrid>

            {!twentyFourHours && (
              <>
                <>
                  {schedule.map(weekDay => (
                    <Attendance
                      key={weekDay.dayOfWeek}
                      weekDay={weekDay}
                      onToggleActive={handleToggleActive}
                      onUpdateShifts={handleUpdateShifts}
                    />
                  ))}
                  <Offs key={'Feriado'} active={activeHolidaySwitch} onToggleActive={handleHolidaysToggle} />
                  <BdsGrid padding="b-2">
                    <BdsInputChips
                      id="input-chips"
                      disabled={!activeHolidaySwitch}
                      duplicated={false}
                      chips={chipsArray}
                      danger={chipsError}
                      errorMessage={ConfigurationsModuleTexts.schedules.error.requiredHolidays}
                      onBdsChangeChips={handleHolidaysChips}
                      onBdsInputChipsFocus={() => setIsModified(true)}
                      data-testid="holidayDays"
                    />
                  </BdsGrid>
                </>
              </>
            )}
          </SpaceTextInput>
        </Content>
      </ConfigurationsContainer>
    </>
  );
};

export default OpeningHours;
