import {
  TOAST_FAILURE,
  TOAST_FAILURE_MESSAGE,
  TOAST_FETCH_HOURS_ERROR,
  TOAST_HOLIDAYS_DATE_ERROR,
  TOAST_INVALID_DATE,
  TOAST_SUCCESS,
  TOAST_SUCCESS_MESSAGE,
} from 'constants/ToastMessages';
import { format, isValid, parse } from 'date-fns';
import { Analytics, Logger } from 'infra/adapters';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useInstallation } from 'redux/slices/InstallationSlice/installationSlice';
import { useProject } from 'redux/slices/ProjectSlice/projectSlice';
import { defaultOff, defaultWeekDays } from '../../../../libs/baseAttendanceObjects';

import { ANALYTICS } from 'constants/Analytics';
import { AttendanceHourDetailed, Off, Shift, WeekDay } from 'objects/types/humamService/AttendanceHour';
import {
  getAttendanceHoursContainers,
  getAttendanceHoursDetailed,
  GetAttendanceRegistry,
  SaveAttendanceSchedule,
  UpdateIsFulltimeAttendance,
} from 'services/BlipGoApiService';
import { createToastError, createToastSuccess } from 'services/Toats';
import { extractAndFormatChips, fillWeekDaysWithDefaultShift } from 'utils/Date';
import { formatLogMessage } from 'utils/LogMessage';

const className = 'useOpeningHours.tsx';

export const useOpeningHours = () => {
  const { router, tenant, desk } = useSelector(useInstallation);
  const { activeSubPage } = useSelector(useProject);

  const [infoLoaded, setInfoLoaded] = useState(false);

  const [twentyFourHours, setTwentyFourHours] = useState(false);

  const [hoursdetailed, setHoursDetailed] = useState<AttendanceHourDetailed>();
  const [holidays, setHolidays] = useState<Off[]>([defaultOff]);
  const [schedule, setSchedule] = useState<WeekDay[]>(defaultWeekDays);
  const [chipsArray, setChipsArray] = useState<string[]>([]);
  const [chipsError, setChipsError] = useState<boolean>(false);
  const [activeHolidaySwitch, setActiveHolidaySwitch] = useState<boolean>(false);

  const getHours = async () => {
    try {
      const [hoursContainersResponse, attendanceRegistry] = await Promise.all([
        getAttendanceHoursContainers(desk.shortName, tenant.id),
        GetAttendanceRegistry(router.shortName, false),
      ]);

      if (hoursContainersResponse.itens.length > 0) {
        const scheduleId = hoursContainersResponse.itens[0].attendanceHourId;

        // Fetch detailed attendance hours
        const hoursDetailedResponse = await getAttendanceHoursDetailed(desk.shortName, tenant.id, scheduleId);

        // Process holidays to generate chips array
        const initialChips = extractAndFormatChips(hoursDetailedResponse.offs);
        setChipsArray(initialChips);

        // Fill week days with default shift for attendance component map
        const filledWeekDays = fillWeekDaysWithDefaultShift(hoursDetailedResponse.weekDays);

        // Update state
        setTwentyFourHours(attendanceRegistry.isFulltime);
        setActiveHolidaySwitch(hoursDetailedResponse.offs.length > 0);
        setHoursDetailed(hoursDetailedResponse);
        setSchedule(filledWeekDays);
        setHolidays(hoursDetailedResponse.offs);
      }

      setInfoLoaded(true);
    } catch (error) {
      createToastError(TOAST_FETCH_HOURS_ERROR);
      setInfoLoaded(true);
    }
  };

  const initHook = () => {
    getHours();
  };

  const updateWeekDay = (dayOfWeek: string, newWeekDay: Partial<WeekDay>) => {
    setSchedule(prevSchedule => {
      if (!prevSchedule) return prevSchedule;

      return prevSchedule.map(weekDay => (weekDay.dayOfWeek === dayOfWeek ? { ...weekDay, ...newWeekDay } : weekDay));
    });
  };

  const handleToggleActive = (dayOfWeek: string, active: boolean) => {
    updateWeekDay(dayOfWeek, { active });
  };

  const handleHolidayToggleActive = (active: boolean) => {
    setActiveHolidaySwitch(active);
  };

  const handleUpdateShifts = (dayOfWeek: string, shifts: Shift[]) => {
    updateWeekDay(dayOfWeek, { shifts });
  };

  const handleHolidaysChips = (ev: CustomEvent) => {
    if (ev.detail && Array.isArray(ev.detail.data)) {
      const newChips = ev.detail.data;

      const validChips = newChips.filter((dateStr: string) => {
        const date = parse(dateStr, 'dd/MM/yy', new Date());

        if (!isValid(date)) {
          console.error('Data inválida:', dateStr);
          setChipsError(true);
          createToastError(TOAST_HOLIDAYS_DATE_ERROR);
          return false;
        }
        setChipsError(false);
        return true;
      });

      setChipsArray(prevChips => {
        if (JSON.stringify(prevChips) === JSON.stringify(validChips)) {
          return prevChips;
        }
        return validChips;
      });
    }
  };

  const saveDeskSchedule = async () => {
    const methodName = 'saveOpeningHours';
    try {
      const activeSchedule = schedule.filter(day => day.active);

      const newHolidays = chipsArray
        .map((dateStr: string) => {
          const date = parse(dateStr, 'dd/MM/yy', new Date());

          if (!isValid(date)) {
            return;
          }

          const formattedDate = format(date, 'yyyy-MM-dd');

          return {
            reason: 'Feriado',
            startDate: formattedDate,
            endDate: formattedDate,
            startTime: '00:10',
            endTime: '23:55',
            allDay: true,
          };
        })
        .filter((holiday): holiday is Off => holiday !== null);

      const validHolidays = newHolidays.filter(
        holiday => holiday?.startDate && holiday?.endDate && holiday.startTime && holiday.endTime,
      );
      if (twentyFourHours) {
        await UpdateIsFulltimeAttendance(router.shortName, twentyFourHours);
      } else {
        const hasInvalidTimes = schedule.some(
          day => day.active && day.shifts.some(shift => !shift.startTime || !shift.endTime),
        );

        if (hasInvalidTimes) {
          createToastError(TOAST_INVALID_DATE);
          Analytics.Track(ANALYTICS.CLICK_SAVE_BUTTON, {
            status: 'falha',
            error: 'Formato de horário inválido',
            screenName: activeSubPage.title,
            service24h: twentyFourHours,
            warningModal: false,
          });
          return;
        }
        await SaveAttendanceSchedule(tenant.id, desk.shortName, activeSchedule, validHolidays);
        await UpdateIsFulltimeAttendance(router.shortName, twentyFourHours);
      }
      Analytics.Track(ANALYTICS.CLICK_SAVE_BUTTON, {
        status: 'sucesso',
        screenName: activeSubPage.title,
        service24h: twentyFourHours,
        warningModal: false,
      });
      createToastSuccess(TOAST_SUCCESS, TOAST_SUCCESS_MESSAGE);
    } catch (ex: any) {
      Analytics.Track(ANALYTICS.CLICK_SAVE_BUTTON, {
        status: 'falha',
        error: ex.message,
        screenName: activeSubPage.title,
        service24h: twentyFourHours,
        warningModal: false,
      });
      Logger.Error(formatLogMessage(ex, 'There was a failure to save changes.'), {
        methodName,
        className,
      });
      createToastError(TOAST_FAILURE, TOAST_FAILURE_MESSAGE);
    }
  };

  return {
    initHook,
    twentyFourHours,
    setTwentyFourHours,
    handleHolidaysChips,
    infoLoaded,
    getHours,
    hoursdetailed,
    setHolidays,
    holidays,
    setSchedule,
    schedule,
    updateWeekDay,
    handleToggleActive,
    handleUpdateShifts,
    saveDeskSchedule,
    chipsArray,
    chipsError,
    handleHolidayToggleActive,
    activeHolidaySwitch,
    setActiveHolidaySwitch,
  };
};
