import {
  BdsAvatar,
  BdsButton,
  BdsGrid,
  BdsIcon,
  BdsLoadingPage,
  BdsStep,
  BdsStepper,
  BdsTypo,
} from 'blip-ds/dist/blip-ds-react/components';
import {
  HOME_ROUTE,
  INSTAGRAM_ACTIVATION,
  INSTAGRAM_ACTIVATION_BIND_PAGES,
  INSTAGRAM_ACTIVATION_FACEBOOK_CONNECTION,
  INSTAGRAM_ACTIVATION_MESSAGES_ACCESS,
} from 'constants/Routes';
import { Paper } from 'globalStyles';
import { Analytics } from 'infra/adapters';
import { FacebookPage, InstagramActivationErrors, InstagramBusinessAccountDetails, User } from 'objects/types/Facebook';
import { useAuth } from 'oidc-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useInstallation } from 'redux/slices/InstallationSlice/installationSlice';
import { updateInstagramActive } from 'redux/slices/ProjectSlice/projectSlice';
import { ActivateInstagram } from 'services/PackService';
import { createToastError } from 'services/Toats';
import { PageContainer } from './style';

function InstagramAccount() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const locationState = useLocation().state as { page: FacebookPage; user: User };
  const { router } = useSelector(useInstallation);
  const auth = useAuth();
  const dispatch = useDispatch();

  const connectInstagram = async () => {
    try {
      if (
        !locationState.page ||
        !locationState.page.access_token ||
        !locationState.page.id ||
        !locationState.page.connected_instagram_account?.id ||
        !auth.userData?.access_token
      ) {
        Analytics.Track(Analytics.events.INSTAGRAM_ACTIVATION_CONNECTION_ERROR, {
          error: 'Missing parameters',
          errorData: {
            activationData: JSON.stringify(locationState.page),
            userData: JSON.stringify(auth.userData),
          },
        });
        await createToastError('Erro ao conectar Instagram', 'Por favor, faça o login novamente');
        return navigate(INSTAGRAM_ACTIVATION_FACEBOOK_CONNECTION);
      }
      const parameters = {
        instagramBusinessAccountId: locationState.page.connected_instagram_account.id,
        pageId: locationState.page.id,
        pageAccessToken: locationState.page.access_token,
        userName: locationState.page.connected_instagram_account.username,
      } as InstagramBusinessAccountDetails;
      setIsLoading(true);
      await ActivateInstagram(router.shortName, locationState.user.id, parameters, auth.userData.access_token);
      setIsLoading(false);
      Analytics.Track(Analytics.events.INSTAGRAM_ACTIVATION_CONNECTION_SUCCESS);
      dispatch(updateInstagramActive(true));
      navigate(HOME_ROUTE);
    } catch (error: any) {
      let errorMessage;
      let routeToNavigate = INSTAGRAM_ACTIVATION;
      switch (error) {
        case InstagramActivationErrors.NoMessagesAccess:
          errorMessage = 'O acesso às mensagens não está ativado';
          routeToNavigate = INSTAGRAM_ACTIVATION_MESSAGES_ACCESS;
          break;
        case InstagramActivationErrors.NoBindedPagesFound:
          errorMessage = 'Perfil do Instagram não vinculado à pagina do Facebook';
          routeToNavigate = INSTAGRAM_ACTIVATION_BIND_PAGES;
          break;
        case InstagramActivationErrors.BotConnected:
          errorMessage = 'Bot já conectado a página do Instagram';
          await createToastError(errorMessage, 'Por favor, tente novamente');
          break;
        default:
          errorMessage = error;
          await createToastError('Erro ao ativar o Instagram', errorMessage);
          break;
      }
      Analytics.Track(Analytics.events.INSTAGRAM_ACTIVATION_CONNECTION_ERROR, { error: errorMessage });
      navigate(routeToNavigate, { state: { user: locationState.user, page: locationState.page } });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    Analytics.Track(Analytics.events.INSTAGRAM_ACTIVATION_CONFIRM_INSTAGRAM_PAGE);
  }, []);

  return isLoading ? (
    <BdsLoadingPage />
  ) : (
    <BdsGrid justifyContent="center" alignItems="center">
      <Paper>
        <BdsGrid padding="3" gap="4" direction="column">
          <BdsStepper>
            <BdsStep>Selecionar página</BdsStep>
            <BdsStep>Vincular contas</BdsStep>
            <BdsStep active>Conectar Instagram</BdsStep>
          </BdsStepper>
          <BdsGrid justifyContent="center">
            <BdsGrid lg="8" direction="column" gap="2">
              <BdsGrid direction="row" gap="2">
                <BdsIcon type="logo" name="instagram" size="xxx-large" />
                <BdsTypo tag="span" variant="fs-24" bold="bold" lineHeight="plus">
                  Confirme a conta do Instagram para conectar
                </BdsTypo>
              </BdsGrid>
              <BdsTypo tag="p">
                Sua página já está vinculada a um perfil do <b>Instagram</b>. Agora basta conferir e conectar.
              </BdsTypo>
              <PageContainer>
                <BdsGrid padding="2" direction="row" justifyContent="space-between" alignItems="center">
                  <BdsGrid gap="3" alignItems="center">
                    <BdsAvatar
                      id="avatar"
                      color="success"
                      name={locationState.page?.connected_instagram_account?.username}
                      size="standard"
                    />
                    <BdsTypo variant="fs-16" bold="bold">
                      {locationState.page?.connected_instagram_account?.username}
                    </BdsTypo>
                  </BdsGrid>
                  <BdsButton
                    onBdsClick={() => {
                      Analytics.Track(Analytics.events.INSTAGRAM_ACTIVATION_CHOOSE_INSTAGRAM_BUTTON);
                      connectInstagram();
                    }}
                  >
                    Conectar
                  </BdsButton>
                </BdsGrid>
              </PageContainer>
            </BdsGrid>
          </BdsGrid>
        </BdsGrid>
      </Paper>
    </BdsGrid>
  );
}

export default InstagramAccount;
