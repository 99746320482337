export type User = {
  id: string;
  name: string;
};

export type Business = {
  id: string;
  name: string;
};

export type FacebookAppInformation = {
  appId: string;
  graphApiVersion: string;
  authApiVersion: string;
};

export type FbConnectionResult = {
  status: string;
  authResponse: {
    accessToken: string;
    expiresIn: number;
    signedRequest: string;
    userID: string;
  };
};

export type FacebookPagesResult = {
  data: FacebookPage[];
  paging: {
    cursors: {
      before: string;
      after: string;
    };
  };
};

export type FacebookPage = {
  id: string;
  name: string;
  access_token: string;
  connected_instagram_account?: {
    id: string;
    username: string;
  };
  instagram_business_account?: {
    id: string;
  };
};

export type FbConnectedData = {
  userID: string;
  name: string;
  accessToken: string;
  scope: string;
};

export enum InstagramActivationErrors {
  Unknown,
  InvalidInstagramAccount,
  NoBindedPagesFound,
  NoMessagesAccess,
  BotConnected,
}

export type InstagramBusinessAccountDetails = {
  pageId: string;
  userName: string;
  instagramBusinessAccountId: string;
  isValid: boolean;
  pageAccessToken: string;
};
