import Switch from 'components/Switch';
import { MainContainer } from './styles';

interface OffsProps {
  active: boolean;
  onToggleActive: (active: boolean) => void;
}

export const Offs = ({ onToggleActive, active }: OffsProps) => {
  const handleSwitchToggle = async (event: Event) => {
    const input = event.currentTarget as HTMLBdsSwitchElement;
    const newActive = await input?.getValue();
    onToggleActive(newActive);
  };

  return (
    <MainContainer $lastInput={false}>
      <Switch
        checked={active}
        onChange={handleSwitchToggle}
        name={'Feriado'}
        text={'Feriado'}
        isEmojiSwitch={false}
        dataTestId={'switchDay feriado'}
      />
    </MainContainer>
  );
};
