/* eslint-disable max-len */
import {
  ATTENDANCE_QUEUES_CONFIGURATIONS_ROUTE,
  ATTENDANTS_CONFIGURATIONS_ROUTE,
  CHANNELS_ROUTE,
  MENU_AUTOMATIC_RESPONSES_CONFIGURATIONS_ROUTE,
} from './Routes';

export const APPCUES_CHANNELS_ROUTE = `${CHANNELS_ROUTE}?appcue=9ba8df3e-815d-40d8-870b-29cfbca0f69d`;
export const APPCUES_ATTENDANTS_CONFIGURATIONS_ROUTE = `${ATTENDANTS_CONFIGURATIONS_ROUTE}?appcue=6c9961dc-96c3-404a-8480-95ad2e29788e`;
export const APPCUES_ATTENDANCE_QUEUES_CONFIGURATIONS_ROUTE = `${ATTENDANCE_QUEUES_CONFIGURATIONS_ROUTE}?appcue=3130e195-8fad-4dea-8987-ce942e09c889`;
export const APPCUES_MENU_AUTOMATIC_RESPONSES_CONFIGURATIONS_ROUTE = `${MENU_AUTOMATIC_RESPONSES_CONFIGURATIONS_ROUTE}?appcue=69125963-8188-45a3-8723-09b721f6022c`;
