import { Off, Shift, WeekDay } from 'objects/types/humamService/AttendanceHour';

export const defaultShift: Shift = {
  startTime: '09:00',
  endTime: '18:00',
};

export const defaultWeekDay: WeekDay = {
  dayOfWeek: 'Monday',
  active: true,
  shifts: [defaultShift],
};

export const defaultWeekDays: WeekDay[] = [
  { ...defaultWeekDay, dayOfWeek: 'Monday' },
  { ...defaultWeekDay, dayOfWeek: 'Tuesday' },
  { ...defaultWeekDay, dayOfWeek: 'Wednesday' },
  { ...defaultWeekDay, dayOfWeek: 'Thursday' },
  { ...defaultWeekDay, dayOfWeek: 'Friday' },
  { ...defaultWeekDay, dayOfWeek: 'Saturday', active: false },
  { ...defaultWeekDay, dayOfWeek: 'Sunday', active: false },
];

export const defaultOff: Off = {
  reason: 'Feriado',
  startDate: '2024-12-25',
  endDate: '2025-12-25',
  startTime: '00:00',
  endTime: '23:59',
  allDay: true,
};
