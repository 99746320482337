import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    body {
        height: 100%;
        width: 100%;
        margin: 0;
        font-family: 'Nunito Sans';
        max-width: 100%;
        background-color: #F6F6F6;
      }

      * {
        margin: 0;
        padding: 0;
        border: 0;
        box-sizing: border-box;
  }

  #container-mfe {
    z-index: -1;
    width: 100%;
  }
`;

export const Paper = styled.div`
  background: #fff;
  border-radius: 1rem;
  width: 100%;
  box-shadow: 0px 2px 8px 0px #00000026;
`;
