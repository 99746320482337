import DeskQrCodeAppStore from 'assets/desk-qrcode-appstore.svg';
import DeskQrCodePlayStore from 'assets/desk-qrcode-playstore.svg';
import { BdsButton, BdsChipTag, BdsGrid, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { EXTENSIONS_ROUTE } from 'constants/Routes';
import { useNavigate } from 'react-router-dom';

function DeskMobileApp() {
  const navigate = useNavigate();

  return (
    <BdsGrid direction="column" padding="6" gap="4">
      <BdsButton variant="secondary" icon="arrow-left" onBdsClick={() => navigate(EXTENSIONS_ROUTE)}>
        Voltar
      </BdsButton>
      <BdsGrid justifyContent="space-between" padding="4">
        <BdsGrid direction="column" lg="6">
          <BdsGrid direction="column" margin="b-2">
            <BdsTypo variant="fs-24" bold="bold">
              Blip desk para smartphone
            </BdsTypo>
            <BdsChipTag color="outline">Aplicativo</BdsChipTag>
          </BdsGrid>
          <BdsTypo variant="fs-14">
            O Blip Desk é o aplicativo móvel que possibilita o atendimento das conversas de sua empresa pelo celular.
            Trazendo mais mobilidade e agilidade para seus atendentes. Visualize, converse, edite contatos e chame seus
            clientes a hora que quiser.
          </BdsTypo>
          <br />
          <BdsTypo bold="bold">Como funciona:</BdsTypo>
          <br />
          <ul>
            <li>
              <BdsTypo variant="fs-14">Leia o QR code ao lado e baixe o aplicativo.</BdsTypo>
            </li>
            <li>
              <BdsTypo variant="fs-14">
                Realize o login na sua conta pelo celular e comece a atender suas conversas.
              </BdsTypo>
            </li>
            <li>
              <BdsTypo variant="fs-14">O aplicativo funciona como as Conversas do seu Blip Go!</BdsTypo>
            </li>
          </ul>
        </BdsGrid>
        <BdsGrid gap="4">
          <BdsGrid direction="column" gap="1" alignItems="center">
            <img src={DeskQrCodePlayStore} alt="QR Code Play Store" />
            <BdsTypo variant="fs-14" bold="bold">
              Disponível para Android na Play Store
            </BdsTypo>
            <BdsTypo variant="fs-14">Escaneie o QR code ou visite:</BdsTypo>
            <BdsTypo variant="fs-14">
              <a href="https://bit.ly/BlipDeskAndroid" target="_blank" rel="noreferrer">
                https://bit.ly/BlipDeskAndroid
              </a>
            </BdsTypo>
          </BdsGrid>
          <BdsGrid direction="column" gap="1" alignItems="center">
            <img src={DeskQrCodeAppStore} alt="QR Code Play Store" />
            <BdsTypo variant="fs-14" bold="bold">
              Disponível para iOS na App Store
            </BdsTypo>
            <BdsTypo variant="fs-14">Escaneie o QR code ou visite:</BdsTypo>
            <BdsTypo variant="fs-14">
              <a href="https://apple.co/3WedBYg" target="_blank" rel="noreferrer">
                https://apple.co/3WedBYg
              </a>
            </BdsTypo>
          </BdsGrid>
        </BdsGrid>
      </BdsGrid>
      <div>
        <BdsTypo bold="bold">Dados extras:</BdsTypo>
        <br />
        <BdsTypo variant="fs-14">
          <b>Suporte: </b>
          <a href="mailto:mobile.routines@blip.ai" target="_blank" rel="noreferrer">
            mobile.routines@blip.ai
          </a>
        </BdsTypo>
        <BdsTypo variant="fs-14">
          <b>Versão:</b> 0.7.5
        </BdsTypo>
      </div>
    </BdsGrid>
  );
}

export default DeskMobileApp;
