import { BdsButton, BdsGrid, BdsIcon, BdsIllustration, BdsImage, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import CardChannel from 'components/CardChannel/CardChannel';
import { ProPlanModal } from 'components/ProPlanModal/ProPlanModal';
import { GetAccountSettings } from 'configurations/Account';
import { ANALYTICS } from 'constants/Analytics';
import ChannelsName from 'constants/ChannelsName';
import { NUMBER_PREPARATION, WPP_ACTIVATION } from 'constants/Routes';
import { Simulator } from 'constants/Simulator';
import useChannelRedirects from 'hooks/useChannelRedirects';
import { Analytics } from 'infra/adapters';
import { PlanTypeEnum } from 'objects/types/BlipGoApi';
import { useAuth } from 'oidc-react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useChannelsModule } from 'redux/slices/ChannelsModuleSlice/channelsModuleSlice';
import { useInstallation } from 'redux/slices/InstallationSlice/installationSlice';
import { useProject } from 'redux/slices/ProjectSlice/projectSlice';
import { DestroyBlipChatTest, mountSimulateBlipChat } from 'services/BlipChatService';
import { LocalStorageService } from 'services/LocalStorageService';
import { getBlipChatConfigKey } from 'services/PackService';
import { GetBotAuthorization } from 'utils/Installation';
import defaultLogoSimulator from '../../../../assets/DefaultLogoSimulator.png';
import { GrayContainer } from './styles';

const ActivateChannel: React.FC = () => {
  const auth = useAuth();
  const { channels } = useSelector(useChannelsModule);
  const { redirectToConnect, redirectToTest } = useChannelRedirects();
  const { whatsAppActive, instagramActive, messengerActive, activeModule, loggedUser, planType } =
    useSelector(useProject);
  const [surveyView, setSurveyView] = useState<boolean>();
  const [thankReply, setThankReply] = useState<boolean>(false);
  const [startSimulator, setStartSimulator] = useState<boolean>(false);
  const { tenant, router, companyName } = useSelector(useInstallation);
  const token = GetAccountSettings(auth).token;
  const navigate = useNavigate();
  const modalRef = useRef<null | HTMLBdsModalElement>(null);
  let satisfactionSurvey = LocalStorageService.Get('satisfaction_survey')?.seeSurvey;

  useEffect(() => {
    handleSurvey();
  }, []);

  const handleSurvey = () => {
    if (LocalStorageService.Get('satisfaction_survey')?.seeSurvey) {
      setSurveyView(false);
    } else {
      setSurveyView(true);
    }
  };

  const goToWppActivation = () => {
    if (planType === PlanTypeEnum.Pro) {
      navigate(WPP_ACTIVATION);
    } else if (planType == PlanTypeEnum.Basic) {
      navigate(NUMBER_PREPARATION);
    } else {
      if (modalRef?.current) {
        Analytics.Track(Analytics.events.CLICK_CONNECTED_CHANNEL_BUTTON, {
          channel: 'WhatsApp',
          screen: activeModule.title,
        });
        modalRef.current.toggle?.();
      }
    }
  };

  const saveSurvey = (answer: string) => {
    const surveyProperty = { surveyResponse: answer };
    Analytics.Track(ANALYTICS.SIMULATOR_SATISFACTION_SURVEY, surveyProperty);
    LocalStorageService.Add('satisfaction_survey', { surveyResponse: answer, seeSurvey: true });
    satisfactionSurvey = LocalStorageService.Get('satisfaction_survey')?.seeSurvey;
    setThankReply(true);
    setSurveyView(!satisfactionSurvey);
  };

  return (
    <BdsGrid direction="column" style={{ padding: '2.5rem 2rem' }} flexWrap="wrap">
      <BdsGrid justifyContent="center" gap="6">
        <BdsGrid justifyContent="center">
          <ProPlanModal modalRef={modalRef} />
          <div>
            <GrayContainer>
              <BdsGrid padding="3" direction="column" gap="2" flexWrap="wrap">
                <BdsTypo variant="fs-32" bold="bold" margin={false}>
                  Ative um canal para começar.
                </BdsTypo>
                <BdsTypo>
                  Seu chatbot já está <b>pré-configurado</b> e <b>pronto</b> para atender. Basta conectá-lo ao
                  Messenger, Instagram ou WhatsApp.
                </BdsTypo>
                <BdsGrid direction="row" gap="3" flexWrap="wrap" justifyContent="center">
                  {channels.map(item => {
                    if (item.title != ChannelsName.Blipchat) {
                      return (
                        <div key={item.title}>
                          {item.title.toLocaleLowerCase() == ChannelsName.Whatsapp.toLocaleLowerCase() && (
                            <CardChannel
                              item={item}
                              activeChannel={whatsAppActive}
                              onConnect={goToWppActivation}
                              onTest={redirectToTest}
                            />
                          )}
                          {item.title.toLocaleLowerCase() == ChannelsName.Instagram.toLocaleLowerCase() && (
                            <CardChannel
                              item={item}
                              activeChannel={instagramActive}
                              onConnect={redirectToConnect}
                              onTest={redirectToTest}
                            />
                          )}
                          {item.title.toLocaleLowerCase() == ChannelsName.Messenger.toLocaleLowerCase() && (
                            <CardChannel
                              item={item}
                              activeChannel={messengerActive}
                              onConnect={redirectToConnect}
                              onTest={redirectToTest}
                            />
                          )}
                        </div>
                      );
                    }
                  })}
                </BdsGrid>
                <BdsGrid alignItems="center" direction="row" gap="1" padding="b-1">
                  <BdsIcon size="medium" name="message-active" />
                  <BdsTypo variant="fs-12">Após conectar seu canal, você poderá customizar seu chatbot.</BdsTypo>
                </BdsGrid>
              </BdsGrid>
            </GrayContainer>
            <BdsGrid margin="t-4">
              {surveyView && (
                <BdsGrid>
                  <BdsGrid flexWrap="wrap">
                    <GrayContainer>
                      <BdsGrid padding="1" gap="2" direction="row" justifyContent="flex-start" margin="2">
                        <BdsTypo variant="fs-24" bold="bold" margin={false}>
                          Essa simulação ajudou a entender como o bot funcionará?
                        </BdsTypo>
                        <BdsGrid gap="1" alignItems="center">
                          <BdsButton
                            icon="checkball"
                            data-testid="click-survey-yes-button"
                            onClick={() => saveSurvey('Sim')}
                          >
                            Sim
                          </BdsButton>
                          <BdsButton
                            icon="error"
                            variant="ghost"
                            data-testid="click-survey-no-button"
                            onClick={() => saveSurvey('Nao')}
                          >
                            Não
                          </BdsButton>
                        </BdsGrid>
                      </BdsGrid>
                    </GrayContainer>
                  </BdsGrid>
                </BdsGrid>
              )}
              {thankReply && (
                <BdsGrid justifyContent="flex-start" margin="r-5">
                  <GrayContainer>
                    <BdsGrid padding="1" gap="1" flexWrap="wrap" margin="2">
                      <BdsTypo variant="fs-20" bold="bold">
                        Obrigado pela resposta!
                      </BdsTypo>
                      <BdsIcon theme="outline" name="checkball" />
                    </BdsGrid>
                  </GrayContainer>
                </BdsGrid>
              )}
            </BdsGrid>
          </div>
        </BdsGrid>
        <BdsGrid>
          {!startSimulator ? (
            <BdsGrid
              alignItems="center"
              direction="column"
              style={{ width: '330px', height: 'auto', borderRadius: '15px' }}
            >
              <BdsGrid
                direction="column"
                style={{
                  width: '100%',
                  height: '560px',
                  borderRadius: '12px',
                  boxShadow: '0 1px 10px rgba(10, 15, 26, .3)',
                }}
              >
                <BdsGrid
                  align-items="center"
                  gap="1"
                  style={{
                    width: '100%',
                    height: '52px',
                    backgroundColor: '#0096FA',
                    borderRadius: '12px 12px 0px 0px',
                    padding: '8px 16px 8px 16px',
                  }}
                >
                  <BdsImage
                    src={defaultLogoSimulator}
                    data-testid="ilustrator-simulator"
                    style={{ marginTop: '5px' }}
                  />
                  <BdsGrid direction="column">
                    <BdsTypo variant="fs-14" bold="semi-bold" style={{ color: 'white' }}>
                      {companyName}
                    </BdsTypo>
                    <BdsTypo variant="fs-10" bold="regular" style={{ color: 'white' }}>
                      Online
                    </BdsTypo>
                  </BdsGrid>
                </BdsGrid>
                <BdsGrid
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                  gap="3"
                  style={{ width: '100%', height: '100%', backgroundColor: 'white', borderRadius: '12px' }}
                >
                  <BdsGrid
                    direction="column"
                    gap="3"
                    alignItems="center"
                    justifyContent="center"
                    style={{ width: '330px', height: '368px', padding: '0px 32px 40px 32px', textAlign: 'center' }}
                  >
                    <BdsIllustration type="default" name="attendance-3" style={{ width: '180px', height: '180px' }} />
                    <BdsTypo variant="fs-16" bold="semi-bold">
                      Inicie agora uma conversa e teste seu chatbot na prática!
                    </BdsTypo>
                  </BdsGrid>

                  <BdsButton
                    data-testid="start-simulator"
                    onBdsClick={async () => {
                      Analytics.Track(ANALYTICS.BLIPCHAT_SIMULATOR_CLICK_HOME, { simulatorVersion: 'iframe' });
                      setStartSimulator(true);
                      const response = await getBlipChatConfigKey(router.shortName, token);
                      mountSimulateBlipChat(
                        tenant.id,
                        response.appKey,
                        loggedUser,
                        router.shortName,
                        GetBotAuthorization(router.shortName, router.accessKey),
                      );
                    }}
                  >
                    Começar uma conversa
                  </BdsButton>
                </BdsGrid>
              </BdsGrid>
            </BdsGrid>
          ) : (
            <BdsGrid
              direction="column"
              alignItems="center"
              style={{
                width: '330px',
                height: 'auto',
              }}
            >
              <div
                id={Simulator.DIV_NAME_SIMULATOR}
                style={{
                  width: '330px',
                  borderRadius: '15px',
                  overflow: 'hidden',
                  height: '560px',
                  boxShadow: '0 1px 10px rgba(10, 15, 26, .3)',
                  minWidth: '20rem',
                }}
              ></div>
              <BdsButton
                variant="tertiary"
                icon="refresh"
                style={{ marginTop: '16px' }}
                data-testid="restart-simulator"
                onBdsClick={async () => {
                  Analytics.Track(ANALYTICS.BLIPCHAT_RESTART_SIMULATOR_CLICK_HOME, { simulatorVersion: 'iframe' });
                  DestroyBlipChatTest();
                  const response = await getBlipChatConfigKey(router.shortName, token);
                  mountSimulateBlipChat(
                    tenant.id,
                    response.appKey,
                    loggedUser,
                    router.shortName,
                    GetBotAuthorization(router.shortName, router.accessKey),
                  );
                }}
              >
                Reiniciar conversa
              </BdsButton>
            </BdsGrid>
          )}
        </BdsGrid>
      </BdsGrid>
    </BdsGrid>
  );
};

export default ActivateChannel;
