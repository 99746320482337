import { BdsButton, BdsGrid, BdsIcon, BdsInput, BdsSwitch, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import SubPageHeader from 'components/SubPageHeader/SubPageHeader';
import { ANALYTICS } from 'constants/Analytics';
import { RESOURCES } from 'constants/ResourcesNames';
import SubPages from 'constants/SubPages';
import {
  TOAST_FAILURE_MESSAGE,
  TOAST_FAILURE_QUEUE_INVALID_MESSAGE,
  TOAST_SUCCESS,
  TOAST_SUCCESS_MESSAGE,
} from 'constants/ToastMessages';
import { Analytics, Logger } from 'infra/adapters';
import { TrackingProperties } from 'objects/types/TrackingProperties';
import { Resource } from 'objects/types/commands/Resource';
import { ResourceAutomaticResponse } from 'objects/types/resources/ResourceAutomaticResponse';
import { useAuth } from 'oidc-react';
import ConfigurationsContainer from 'pages/Configurations/components/ConfigurationsContainer/ConfigurationsContainer';
import { error } from 'pages/Configurations/constants';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInstallation } from 'redux/slices/InstallationSlice/installationSlice';
import { useProject } from 'redux/slices/ProjectSlice/projectSlice';
import { updateAutomaticResponses, updateFaqDisabled, useResource } from 'redux/slices/ResourceSlices/resourceSlice';
import { AppDispatch } from 'redux/store';
import { deleteSpecificResource } from 'services/CommandsService';
import { setInstallationResources } from 'services/PackService';
import { createToastError, createToastSuccess } from 'services/Toats';
import { IsEmptyString } from 'utils/Input';
import { GetBotAuthorization } from 'utils/Installation';
import { formatLogMessage } from 'utils/LogMessage';

const AutomaticResponsesMenu: React.FC = () => {
  const [isContentModified, setIsContentModified] = useState(false);
  const className = 'AutomaticResponsesMenu';
  const auth = useAuth();
  const dispatch = useDispatch<AppDispatch>();
  const { router } = useSelector(useInstallation);
  const { activeSubPage } = useSelector(useProject);
  const { automaticResponses, semFaq } = useSelector(useResource);
  const [automaticResponsesBuffer, setAutomaticResponsesBuffer] = useState<ResourceAutomaticResponse[]>([]);
  const [faqDisabled, setFaqDisabled] = useState(semFaq);
  const key = GetBotAuthorization(router.shortName, router.accessKey);
  const titleRefs = useRef<HTMLBdsInputElement[]>([]);
  const responseRefs = useRef<HTMLBdsInputElement[]>([]);

  const handleClickSaveButton = async () => {
    await saveAutomaticResponses();
    setIsContentModified(false);
  };

  const getAutomaticResponses = () => {
    const titles = titleRefs.current.filter(ref => ref !== null).map(ref => ref.value);
    const responses = responseRefs.current.filter(ref => ref !== null).map(ref => ref.value);
    const automaticResponses = titles.map((title, index) => {
      return {
        title,
        response: responses[index],
      };
    });

    return automaticResponses;
  };

  const deleteOldAutomaticResponses = async () => {
    const promisesDelete = automaticResponses.map(automaticResponse => {
      return deleteSpecificResource(key, automaticResponse.title.trim());
    });

    await Promise.all(promisesDelete);
  };

  const validateAutomaticResponsesForEmptyFields = (
    list: {
      title: string | null | undefined;
      response: string | null | undefined;
    }[],
  ): ResourceAutomaticResponse[] => {
    const isValid = list.every(item => {
      return (
        typeof item.title === 'string' &&
        typeof item.response === 'string' &&
        !IsEmptyString(item.title) &&
        !IsEmptyString(item.response)
      );
    });

    if (!isValid) {
      throw new Error(TOAST_FAILURE_QUEUE_INVALID_MESSAGE);
    }

    return list.map(item => ({
      title: item.title as string,
      response: item.response as string,
    }));
  };

  const saveAutomaticResponses = async () => {
    if (!auth.userData?.access_token) {
      return location.reload();
    }

    const methodName = 'saveAutomaticResponses';
    const trackingProperties = {} as TrackingProperties;
    trackingProperties.screenName = activeSubPage.title;
    trackingProperties.warningModal = false;

    try {
      const updatedAutomaticResponses = getAutomaticResponses();
      const validatedAutomaticResponses = validateAutomaticResponsesForEmptyFields(updatedAutomaticResponses);

      await deleteOldAutomaticResponses();
      const resources = validatedAutomaticResponses.map((automaticResponse, index) => {
        return {
          name: `${index + 1}. ${automaticResponse.title.trim()}`,
          value: automaticResponse.response,
          type: 'text/plain',
        } as Resource;
      });
      resources.push({
        name: RESOURCES.WITHOUT_FAQ,
        value: faqDisabled.toString(),
        type: 'text/plain',
      });
      await setInstallationResources(router.shortName, resources, auth.userData.access_token);

      const updatedResourcesReference = validatedAutomaticResponses.map((automaticResponse, index) => {
        return {
          title: `${index + 1}. ${automaticResponse.title.trim()}`,
          response: automaticResponse.response,
        };
      });

      dispatch(updateAutomaticResponses(updatedResourcesReference));
      dispatch(updateFaqDisabled(faqDisabled));
      setAutomaticResponsesBuffer(validatedAutomaticResponses);

      createToastSuccess(TOAST_SUCCESS, TOAST_SUCCESS_MESSAGE);

      trackingProperties.status = 'sucesso';
      trackingProperties.numQuestions = validatedAutomaticResponses.length;
      Analytics.Track(ANALYTICS.CLICK_SAVE_BUTTON, trackingProperties);
    } catch (ex: any) {
      createToastError(TOAST_FAILURE_MESSAGE, ex?.message);
      Logger.Error(formatLogMessage(ex, 'There was a failure to save changes.'), {
        methodName,
        className,
      });
      trackingProperties.status = 'falha';
      trackingProperties.error = ex.message;
      trackingProperties.numQuestions = automaticResponses.length;
      Analytics.Track(ANALYTICS.CLICK_SAVE_BUTTON, trackingProperties);
    }
  };

  const removeAutomaticResponse = (indexInAutomaticResponsesArray: number) => {
    const propertyTrack = { screenName: SubPages.configurations[1].title, type: 'Resposta automática' };
    Analytics.Track(ANALYTICS.CLICK_DELETE_ITEM_ICON, propertyTrack);

    const newAutomaticResponsesArray = automaticResponsesBuffer.filter(
      (_, index) => index !== indexInAutomaticResponsesArray,
    );
    setAutomaticResponsesBuffer(newAutomaticResponsesArray);
  };

  const handleAddAutomaticResponse = () => {
    try {
      const updatedAutomaticResponses = getAutomaticResponses();
      const validatedAutomaticResponses = validateAutomaticResponsesForEmptyFields(updatedAutomaticResponses);
      setAutomaticResponsesBuffer([
        ...validatedAutomaticResponses,
        {
          title: '',
          response: '',
        },
      ]);
      const propertyTrack = { screenName: SubPages.configurations[1].title, type: 'Resposta automática' };
      Analytics.Track(ANALYTICS.AUTO_REPLY_ADD_OPTION, propertyTrack);
    } catch (error: any) {
      const trackingProperties = {} as TrackingProperties;
      const methodName = 'handleAddAutomaticResponse';
      createToastError(TOAST_FAILURE_MESSAGE, error?.message);
      Logger.Error(formatLogMessage(error, 'There was a failure to save changes.'), {
        methodName,
        className,
      });
      trackingProperties.status = 'falha';
      trackingProperties.error = error.message;
      trackingProperties.numQuestions = automaticResponses.length;
      Analytics.Track(ANALYTICS.CLICK_SAVE_BUTTON, trackingProperties);
    }
  };

  const toggleFAQ = (ev: CustomEvent) => {
    const value = ev.detail.checked as boolean;
    const isDisabled = !value;
    if (value) {
      setAutomaticResponsesBuffer([
        {
          title: '',
          response: '',
        },
      ]);
    }
    setFaqDisabled(isDisabled);
  };

  useEffect(() => {
    const automaticResponsesWithoutIndex = automaticResponses.map(automaticResponse => {
      return {
        title: automaticResponse.title.replace(/^\d+\.\s/, ''),
        response: automaticResponse.response,
      };
    });
    setAutomaticResponsesBuffer(automaticResponsesWithoutIndex);
  }, []);

  return (
    <>
      <SubPageHeader showTestButton onSave={handleClickSaveButton} buttonSaveDisabled={!isContentModified} />

      <ConfigurationsContainer>
        <BdsGrid direction="column" justifyContent="center" padding="y-1" gap="3" lg="5" md="7" xg="5">
          <BdsGrid gap="2" direction="column">
            <BdsTypo variant="fs-16" tag="p" line-height="plus">
              Personalize o menu principal com as ações ou perguntas frequentes da sua empresa que podem ser respondidas
              pelo bot automaticamente sem a necessidade de atendimento humano. O seu bot responderá por você.
            </BdsTypo>
            <BdsGrid gap="2">
              <BdsSwitch
                data-testid="hide-faq-toggle"
                name=""
                refer=""
                checked={!faqDisabled}
                onBdsChange={ev => {
                  Analytics.Track(Analytics.events.TOGGLE_FAQ, { disableMenu: !ev.detail.checked });
                  setIsContentModified(true);
                  toggleFAQ(ev);
                }}
              />
              <BdsTypo>Habilitar respostas automáticas</BdsTypo>
            </BdsGrid>
            {!faqDisabled && (
              <BdsGrid direction="column" gap="2">
                {automaticResponsesBuffer.map((element, index) => (
                  <BdsGrid direction="column" key={element.title} gap="1">
                    <BdsGrid justifyContent="space-between">
                      <BdsTypo variant="fs-16" bold="bold">
                        Opção do menu {index + 1}
                      </BdsTypo>
                      {automaticResponsesBuffer.length > 1 && (
                        <BdsIcon
                          style={{ cursor: 'pointer' }}
                          name="trash"
                          title={`Remover opção ${index + 1}`}
                          onClick={() => {
                            removeAutomaticResponse(index);
                            setIsContentModified(true);
                          }}
                        />
                      )}
                    </BdsGrid>
                    <BdsInput
                      ref={ref => (titleRefs.current[index] = ref as HTMLBdsInputElement)}
                      title={`Título do menu ${index + 1}`}
                      onBdsFocus={() => setIsContentModified(true)}
                      rows={1}
                      isTextarea
                      required
                      value={element.title}
                      requiredErrorMessage={error.requiredField}
                      errorMessage={element.title == '' ? error.requiredField : ''}
                    />
                    <BdsInput
                      ref={ref => (responseRefs.current[index] = ref as HTMLBdsInputElement)}
                      title={`Resposta ${index + 1}`}
                      onBdsFocus={() => setIsContentModified(true)}
                      rows={4}
                      isTextarea
                      required
                      value={element.response}
                      requiredErrorMessage={error.requiredField}
                      errorMessage={element.response == '' ? error.requiredField : ''}
                    />
                  </BdsGrid>
                ))}
                <BdsGrid justifyContent="flex-end">
                  <BdsButton
                    icon="add"
                    onBdsClick={handleAddAutomaticResponse}
                    variant="ghost"
                    disabled={automaticResponsesBuffer.length === 5}
                  >
                    Adicionar opção
                  </BdsButton>
                </BdsGrid>
              </BdsGrid>
            )}
          </BdsGrid>
        </BdsGrid>
      </ConfigurationsContainer>
    </>
  );
};

export default AutomaticResponsesMenu;
