import styled from 'styled-components';

export const SupportMenuContainer = styled.div`
  & {
    background: #ffffff;
    border-radius: 8px;
    height: 108px;
    width: 187px;
    position: relative;
  }

  &:before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: -4px;
    top: 50px;
    background: #ffffff;
    -webkit-transform: rotate(45deg);
  }
  .balloonFontColor {
    color: #454545;
  }
`;

export const SupportMenuContent = styled.div`
  margin-left: 8px;
  margin-top: 12px;
  margin-right: 12px;
  margin-bottom: 12px;
`;
