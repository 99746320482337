import React from 'react';
import { MessageItemDto } from 'objects/types/MessagesResponse';
import { isSameDay } from 'date-fns';
import MessageItem from '../../MessageItem';

interface MessagesListProps {
  messages: MessageItemDto[];
}

const MessagesList: React.FC<MessagesListProps> = ({ messages }) => {
  let lastMessageDate: string | Date | null = null;

  const sortedMessages = messages.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  return (
    <>
      {sortedMessages.map((message, index) => {
        const currentMessageDate = new Date(message.date);
        const showDateSeparator = lastMessageDate === null || !isSameDay(new Date(lastMessageDate), currentMessageDate);

        lastMessageDate = message.date;

        return <MessageItem key={index} message={message} showDateSeparator={showDateSeparator} />;
      })}
    </>
  );
};

export default MessagesList;
