import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IHubspotState } from './interface';
import {
  fetchIsInstagramActive,
  fetchIsMessengerActive,
  fetchIsProPlanRequested,
  updateContactPropertiesHubspot,
} from './thunkMiddleware';

const InitialState: IHubspotState = {
  isInstagramActive: false,
  isMessengerActive: false,
  isProPlanRequested: false,
};

const hubspotSlice = createSlice({
  name: 'hubspot',
  initialState: InitialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(updateContactPropertiesHubspot.fulfilled, (state, { payload }: PayloadAction<boolean>) => {
        state.isProPlanRequested = payload;
      })
      .addCase(fetchIsProPlanRequested.fulfilled, (state, { payload }: PayloadAction<string>) => {
        state.isProPlanRequested = payload === 'true';
      })
      .addCase(fetchIsInstagramActive.fulfilled, (state, { payload }: PayloadAction<boolean>) => {
        state.isInstagramActive = payload;
      })
      .addCase(fetchIsMessengerActive.fulfilled, (state, { payload }: PayloadAction<boolean>) => {
        state.isMessengerActive = payload;
      });
  },
});

export default hubspotSlice.reducer;

export const {} = hubspotSlice.actions;

export const useHubspot = (state: { hubspot: IHubspotState }) => state.hubspot;
