import SubPages from 'constants/SubPages';
import { Analytics } from 'infra/adapters';
import { SubPage } from 'objects/types/SubPage';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useProject } from 'redux/slices/ProjectSlice/projectSlice';
import { Item, ItemsSection, SubMenuContainer } from './style';

interface IDesktopSubMenu {
  subPages: SubPage[];
}

const DesktopSubMenu: React.FC<IDesktopSubMenu> = ({ subPages }: IDesktopSubMenu) => {
  const { activeSubPage } = useSelector(useProject);

  const navigate = useNavigate();

  const sendSubPageTrack = (subPage: string) => {
    switch (subPage) {
      case SubPages.configurations[0].title:
        return Analytics.Track(Analytics.events.PG_OPEN_GREETINGS);
      case SubPages.configurations[1].title:
        return Analytics.Track(Analytics.events.PG_OPEN_AUTOMATIC_RESPONSES);
      case SubPages.configurations[2].title:
        return Analytics.Track(Analytics.events.PG_OPEN_ATTENDANTS);
      case SubPages.configurations[3].title:
        return Analytics.Track(Analytics.events.PG_OPEN_HUMAN_ATTENDANCE_QUEUE);
      case SubPages.configurations[4].title:
        return Analytics.Track(Analytics.events.PG_OPEN_HUMAN_ATTENDANCE_OPENING_HOURS);
    }
  };

  const handleActiveSubPage = (subPage: SubPage) => {
    sendSubPageTrack(subPage.title);
    navigate(subPage.route);
  };

  return (
    <SubMenuContainer>
      <ItemsSection>
        {subPages.map(subPage => {
          return (
            <Item
              key={subPage.title}
              data-testid={`click-${subPage.title}`}
              $isSubPageActive={activeSubPage.title === subPage.title}
              onClick={() => handleActiveSubPage(subPage)}
            >
              {subPage.title}
            </Item>
          );
        })}
      </ItemsSection>
    </SubMenuContainer>
  );
};

export default DesktopSubMenu;
