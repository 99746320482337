import { createAsyncThunk } from '@reduxjs/toolkit';
import { ANALYTICS } from 'constants/Analytics';
import { LocalStorageService } from 'services/LocalStorageService';
import { getInstallationDetailsById } from 'services/PackService';
import { IFetchInstallationDetailsById } from './interface';

export const fetchInstallationDetailsById = createAsyncThunk(
  'installation/fetchInstallationDetailsById',
  async ({ id, token }: IFetchInstallationDetailsById, { rejectWithValue }) => {
    try {
      const response = await getInstallationDetailsById(id, token);

      LocalStorageService.Add(ANALYTICS.USER_TRAITS, response);

      return response;
    } catch (ex) {
      const error = ex as Error;
      return rejectWithValue(error.message);
    }
  },
);
