import { BdsButton, BdsCheckbox } from 'blip-ds/dist/blip-ds-react';
import InputText from 'components/InputText';
import { CONTRACT_ERROR, WPP_POLICY_TERMS } from 'constants/MetaActivation';
import { Analytics, Logger } from 'infra/adapters';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateStateBillingEmail,
  updateStateCnpj,
  updateStateCompanyName,
  updateStateCompanyWebsite,
  updateStatePolicyAgreement,
  useContractInfo,
} from 'redux/slices/ContractInfoSlice/contractInfoSlice';
import { AppDispatch } from 'redux/store';
import { CompanyInfoContainer, NavigationBtnContainer, PolicyTermsContainer, PolicyTermsHyperlink } from './styles';

interface IProps {
  nextPageEvent: () => void;
}

export const CompanyInfo = ({ nextPageEvent }: IProps) => {
  const className = 'CompanyInfo';
  const dispatch = useDispatch<AppDispatch>();

  const { cnpj, billingEmail, companyName, companyWebsite, policyAgreement } = useSelector(useContractInfo);

  const handleCnpjChange = (value: string) => {
    dispatch(updateStateCnpj({ ...cnpj, value }));
  };

  const handleEmailChange = (value: string) => {
    dispatch(updateStateBillingEmail({ ...billingEmail, value }));
  };

  const handleWebsiteChange = (value: string) => {
    dispatch(updateStateCompanyWebsite({ ...companyWebsite, value }));
  };

  const handleCompanyNameChange = (value: string) => {
    if (value) {
      dispatch(updateStateCompanyName({ ...companyName, value, isValid: true }));
    }
  };

  const handlePolicyAgreementToggle = () => {
    dispatch(updateStatePolicyAgreement(!policyAgreement));
  };

  const openPolicyTerms = () => {
    Analytics.Track(Analytics.events.META_POLICY_LINK_CLICK);
    window.open(WPP_POLICY_TERMS, '_blank');
  };

  const isFormValid = () => {
    const isFormEmpty = cnpj.isEmpty || billingEmail.isEmpty || companyName.isEmpty || companyWebsite.isEmpty;
    const isFormValid =
      cnpj.isValid && billingEmail.isValid && companyName.isValid && companyWebsite.isValid && policyAgreement;
    return !isFormEmpty && isFormValid;
  };

  const handleNextBtnClick = () => {
    const methodName = 'handleNextBtnClick';

    if (isFormValid()) {
      Logger.Info('user successfully fullfiled contract info form', { methodName, className });
      Analytics.Track(Analytics.events.CLICK_NEXT_COMPANY_CONTRACT_PAGE, {
        cnpj: cnpj.value,
        companyName: companyName.value,
        billingEmail: billingEmail.value,
        website: companyWebsite.value,
      });
      nextPageEvent();
    } else {
      if (billingEmail.isEmpty) {
        dispatch(updateStateBillingEmail({ ...billingEmail, isValid: false }));
      }
      if (companyWebsite.isEmpty) {
        dispatch(updateStateCompanyWebsite({ ...companyWebsite, isValid: false }));
      }
      if (cnpj.isEmpty) {
        dispatch(updateStateCnpj({ ...cnpj, isValid: false }));
      }
      if (companyName.isEmpty) {
        dispatch(updateStateCompanyName({ ...companyName, isValid: false }));
      }
      Logger.Info('user contract info form fields are invalid', { methodName, className });
    }
  };

  return (
    <CompanyInfoContainer>
      <InputText
        required={true}
        requiredErrorMessage={CONTRACT_ERROR.emptyField}
        label="Razão social"
        value={companyName?.value}
        errorMessage={companyName?.errorMessage}
        danger={!companyName?.isValid}
        dataTestId="company-name-input"
        onInputChange={(ev: any) => handleCompanyNameChange(ev?.target?.value)}
      ></InputText>
      <InputText
        required={true}
        requiredErrorMessage={CONTRACT_ERROR.emptyField}
        label="CNPJ"
        value={cnpj?.value}
        errorMessage={cnpj?.errorMessage}
        danger={!cnpj?.isValid}
        dataTestId="cnpj-input"
        onInputChange={(ev: any) => handleCnpjChange(ev?.target?.value)}
      ></InputText>
      <InputText
        required={true}
        requiredErrorMessage={CONTRACT_ERROR.emptyField}
        label="E-mail de cobrança"
        value={billingEmail?.value}
        errorMessage={billingEmail?.errorMessage}
        danger={!billingEmail?.isValid}
        dataTestId="email-input"
        onInputChange={(ev: any) => handleEmailChange(ev?.target?.value)}
        helperMessage="E-mail que receberá o boleto mensalmente"
      ></InputText>
      <InputText
        required={true}
        requiredErrorMessage={CONTRACT_ERROR.emptyField}
        label="Site"
        value={companyWebsite?.value}
        errorMessage={companyWebsite?.errorMessage}
        danger={!companyWebsite?.isValid}
        dataTestId="website-input"
        onInputChange={(ev: any) => handleWebsiteChange(ev?.target?.value)}
      ></InputText>
      <PolicyTermsContainer>
        <BdsCheckbox
          onBdsChange={handlePolicyAgreementToggle}
          checked={policyAgreement}
          label="Minha empresa está de acordo com as"
          name=""
          refer=""
        ></BdsCheckbox>
        <PolicyTermsHyperlink onClick={openPolicyTerms} data-testid="company-info-policy-hyperlink">
          Políticas do Meta.
        </PolicyTermsHyperlink>
      </PolicyTermsContainer>
      <NavigationBtnContainer>
        <BdsButton arrow={true} onClick={handleNextBtnClick} data-testid="company-info-next-btn">
          Próximo
        </BdsButton>
      </NavigationBtnContainer>
    </CompanyInfoContainer>
  );
};
