import { Analytics } from '@segment/analytics-node';
import { Logger } from '../adapters';

/**
 * Connects event data container service over Segment-analytics client
 * @constructor Requires an write key that allows connector to write to the service
 *
 * @param writeKey a service Key as `string` for clients
 */
export class AnalyticsConnector {
  protected analyticsClient = {} as Analytics;

  constructor(writeKey: string) {
    this.connect(writeKey);
  }

  private connect(writeKey: string) {
    try {
      this.analyticsClient = new Analytics({ writeKey, flushAt: 1 });
    } catch (err: any) {
      const { field, message } = err;
      const logArgs = { className: 'AnalyticsConnector', methodName: 'connect' };

      Logger.Error(message, logArgs, { field });
    }
  }
}
