import styled from 'styled-components';

export const MainContainer = styled.div`
  width: 980px;
  height: 784px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 32px;
  gap: 40px;
`;

export const BottomContainer = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
