import { BdsSwitchCustomEvent } from 'blip-ds';
import { BdsGrid, BdsPaper, BdsSwitch, BdsTypo } from 'blip-ds/dist/blip-ds-react';

type PermissionItemsProps = {
  permissionTitle: string;
  permissionDescription: string;
  isActive: boolean;
  onChange: (status: boolean) => void;
};

export const PermissionItem = ({
  permissionTitle,
  permissionDescription,
  isActive,
  onChange,
}: PermissionItemsProps) => {
  const handleChange = (ev: BdsSwitchCustomEvent<any>) => {
    onChange(ev?.detail?.checked);
  };

  return (
    <BdsPaper width="100%" style={{ background: '#fff' }}>
      <BdsGrid padding="2" gap="2" direction="row" alignItems="center">
        <BdsSwitch
          refer=""
          name=""
          checked={isActive}
          data-testid="permission-toggle"
          onBdsChange={ev => handleChange(ev)}
        />
        <BdsGrid gap="1" direction="column">
          <BdsTypo variant="fs-14" bold="bold">
            {permissionTitle}
          </BdsTypo>
          <BdsTypo variant="fs-12">{permissionDescription}</BdsTypo>
        </BdsGrid>
      </BdsGrid>
    </BdsPaper>
  );
};
