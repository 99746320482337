const MESSAGE_DEFAULT = 'Failed to execute method';

const formatLogMessage = (exception: any, customMessage = MESSAGE_DEFAULT) => {
  let exceptions = exception;
  if (typeof exception == 'object') {
    exceptions = JSON.stringify(exception);
  }
  return `${customMessage}
    | message: ${exception?.message || exceptions}
    | data: ${exceptions.data}
    | status: ${exception?.status}
    | responseURL: ${exception?.request?.responseURL}`;
};

export { formatLogMessage };
