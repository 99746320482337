import { ANALYTICS } from 'constants/Analytics';
import { MSGING_NET } from 'constants/CommandsApiRoutes';
import {
  TOAST_FAILURE,
  TOAST_FAILURE_IDENTICAL_QUEUE_NAMES,
  TOAST_FAILURE_MESSAGE,
  TOAST_FAILURE_QUEUE_INVALID,
  TOAST_FAILURE_QUEUE_INVALID_MESSAGE,
  TOAST_FAILURE_REMOVE_REQUIRED_QUEUE,
  TOAST_SUCCESS,
  TOAST_SUCCESS_MESSAGE,
} from 'constants/ToastMessages';
import { Analytics, Logger } from 'infra/adapters';
import Attendant from 'objects/types/Attendant';
import AttendantQueue from 'objects/types/AttendantQueue';
import { PlanTypeEnum, QueueItemRegistry } from 'objects/types/BlipGoApi';
import IdAndValue from 'objects/types/IdAndValue';
import PreServiceQuestion from 'objects/types/PreServiceQuestion';
import Queue from 'objects/types/Queue';
import QueueQuestion from 'objects/types/QueueQuestion';
import Rule from 'objects/types/Rule';
import RuleWithOldQueue from 'objects/types/RuleWithOldQueue';
import { TrackingProperties } from 'objects/types/TrackingProperties';
import { error } from 'pages/Configurations/constants';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateQueuesAnRules,
  updateStateAttendants,
  updateStateQueue,
  updateStateRule,
  useCustomerService,
} from 'redux/slices/CustomerServiceSlice/customerServiceSlice';
import { updateAttendant } from 'redux/slices/CustomerServiceSlice/thunkMiddleware';
import { useInstallation } from 'redux/slices/InstallationSlice/installationSlice';
import { useProject } from 'redux/slices/ProjectSlice/projectSlice';
import { useResource } from 'redux/slices/ResourceSlices/resourceSlice';
import { setPreQueueMessage, setPreServiceQuestion } from 'redux/slices/ResourceSlices/thunkMiddleware';
import { AppDispatch } from 'redux/store';
import { CreateQueueRegistry, GetQueueRegistry, UpdateQueueItems } from 'services/BlipGoApiService';
import { addNewAttendant, deleteQueue, setQueue, setRule } from 'services/CommandsService';
import { createToastError, createToastSuccess } from 'services/Toats';
import { hasElementByIndex } from 'utils/Array';
import { encodeAttendantBlipEmail } from 'utils/BlipEncodeDecode';
import { HasAnyIdenticalStrings, HasAnySpecialCharacters, IsEmptyString } from 'utils/Input';
import { GetBotAuthorization } from 'utils/Installation';
import { formatLogMessage } from 'utils/LogMessage';
import { v4 as uuidv4 } from 'uuid';

const DEFAULT_REDIRECT_MESSAGE = 'Vamos verificar com um de nossos especialistas! Por favor, aguarde um instante.';

const useAttendanceQueue = () => {
  const MAX_QUEUE_BASIC = 1;
  const MAX_QUEUE_PRO = 5;
  const { planType, activeSubPage } = useSelector(useProject);
  const { desk, router } = useSelector(useInstallation);
  const { queues, attendants, rules } = useSelector(useCustomerService);
  const { messages, preServiceQuestionList } = useSelector(useResource);
  const deserializedPreServiceQuestions = JSON.parse(preServiceQuestionList) as Record<string, string>[];

  const keyDesk = GetBotAuthorization(desk.shortName, desk.accessKey);
  const keyRouter = GetBotAuthorization(router.shortName, router.accessKey);

  const [serviceQuestion, setServiceQuestion] = useState<PreServiceQuestion>({
    description: messages.preQueueMessage,
    isServiceQuestionModified: false,
  } as PreServiceQuestion);
  const [attendantsQueue, setAttendantsQueue] = useState<AttendantQueue[]>([]);
  const [attendanceQueues, setAttendanceQueues] = useState<QueueQuestion[]>([]);
  const [deleteAttendanceQueues, setDeleteAttendanceQueues] = useState<QueueQuestion[]>([]);
  const [isDeletionAlertOpen, setIsDeletionAlertOpen] = useState(false);
  const [showOfferBanner, setShowOfferBanner] = useState(attendanceQueues.length >= MAX_QUEUE_BASIC);

  const dispatch = useDispatch<AppDispatch>();

  const className = 'useAttendanceQueue';

  const formatQueues = async () => {
    const queueRegistry = await GetQueueRegistry(router.shortName);
    const queueWithQuestion: QueueQuestion[] = [];
    const queueItems: QueueItemRegistry[] = [];
    if (queueRegistry) {
      queues.forEach(queue => {
        const redirectMessageFallback =
          queueRegistry.queues.find(q => q.id === queue.id)?.redirectMessage ??
          deserializedPreServiceQuestions.find(question => question[queue.name])?.[queue.name] ??
          DEFAULT_REDIRECT_MESSAGE;

        queueWithQuestion.push({
          ...queue,
          isNameModified: false,
          isQuestionModified: false,
          isQueueValid: true,
          isQuestionValid: true,
          oldName: queue.name,
          question: redirectMessageFallback,
        });

        queueItems.push({
          id: queue.id,
          name: queue.name,
          redirectMessage: redirectMessageFallback,
        });
      });
      queueRegistry.listQueuesMessage &&
        setServiceQuestion(prev => ({ ...prev, description: queueRegistry.listQueuesMessage }));
      await UpdateQueueItems(router.shortName, messages.preQueueMessage, queueItems);
    } else {
      queues.forEach(queue => {
        deserializedPreServiceQuestions.forEach(question => {
          if (question[queue.name]) {
            queueWithQuestion.push({
              ...queue,
              isNameModified: false,
              isQuestionModified: false,
              isQueueValid: true,
              isQuestionValid: true,
              oldName: queue.name,
              question: question[queue.name],
            });
          }
        });
      });

      const queueItems: QueueItemRegistry[] = queues.map(queue => {
        return {
          id: queue.id,
          name: queue.name,
          redirectMessage: queueWithQuestion.find(q => q.id === queue.id)?.question ?? DEFAULT_REDIRECT_MESSAGE,
        };
      });
      await CreateQueueRegistry(router.shortName, messages.preQueueMessage, queueItems);
    }

    const updateAttendants = attendants.map(attendant => {
      return {
        ...attendant,
        isTeamModified: false,
      } as AttendantQueue;
    });

    setAttendantsQueue(updateAttendants);
    setAttendanceQueues(queueWithQuestion);
    setShowOfferBanner(updateAttendants.length >= MAX_QUEUE_BASIC);
  };

  const handleNewQueue = () => {
    const newQueue = {
      name: '',
      question: '',
      isActive: true,
      isNameModified: false,
      isQuestionModified: false,
      isQueueValid: true,
      isQuestionValid: true,
      ownerIdentity: `${desk.shortName}@${MSGING_NET}`,
    } as QueueQuestion;
    setAttendanceQueues(prev => [...prev, newQueue]);
    Analytics.Track(ANALYTICS.ATTENDANCE_ADD_TEAM, { screenName: activeSubPage.title });
  };

  const handleChangePreQueueMessage = (event: Event) => {
    const { value } = event.target as HTMLBdsInputElement;
    setServiceQuestion({
      description: value ?? '',
      isServiceQuestionModified: true,
    });
  };

  const handleChangeQueueName = (event: Event, index: number) => {
    const { value } = event.target as HTMLBdsInputElement;

    const newQueueName = value?.trim() ?? '';
    const attendanceQueue = attendanceQueues[index];
    const currentQueueName = attendanceQueue.name ?? attendanceQueue.oldName;

    const newAttendantsQueue = attendantsQueue.map(attendant => {
      if (attendant.teams.includes(currentQueueName)) {
        const filteredTeams = attendant.teams.filter(team => team !== currentQueueName);

        return {
          ...attendant,
          teams: [...filteredTeams, newQueueName],
          isTeamModified: true,
        };
      }

      return attendant;
    });

    setAttendantsQueue(newAttendantsQueue);
    setShowOfferBanner(newAttendantsQueue.length >= MAX_QUEUE_BASIC);

    setAttendanceQueues(
      attendanceQueues.map((queue, queueIndex) => {
        if (queueIndex === index) {
          return {
            ...queue,
            name: newQueueName,
            isNameModified: true,
            isQueueValid: !HasAnySpecialCharacters(newQueueName) && !IsEmptyString(newQueueName),
          };
        }

        return queue;
      }),
    );
  };

  const handleChangeQueueQuestion = (event: Event, index: number) => {
    const { value } = event.target as HTMLBdsInputElement;
    const newQuestionMessage = value ?? '';

    setAttendanceQueues(
      attendanceQueues.map((queue, queueIndex) => {
        if (queueIndex === index) {
          return {
            ...queue,
            question: newQuestionMessage,
            isQuestionValid: !IsEmptyString(newQuestionMessage),
            isQuestionModified: true,
          };
        }

        return queue;
      }),
    );
  };

  const handleDeleteQueue = (index: number) => {
    if (attendanceQueues.length === 1) {
      createToastError(TOAST_FAILURE_REMOVE_REQUIRED_QUEUE);
      return;
    }
    const attendanceQueueToDeleteIndex = attendanceQueues.findIndex((_, queueIndex) => queueIndex === index);

    if (!hasElementByIndex(attendanceQueueToDeleteIndex)) return;
    const queueToDelete = attendanceQueues[attendanceQueueToDeleteIndex];

    const queueReferenceName = queueToDelete.oldName;
    const attendantIndex = attendants.findIndex(attendant => attendant.teams.includes(queueReferenceName));

    if (hasElementByIndex(attendantIndex)) {
      setIsDeletionAlertOpen(true);
      return;
    }

    setDeleteAttendanceQueues(prev => [...prev, queueToDelete]);

    const updateAttendanceQueues = attendanceQueues.filter(
      (_, attendanceQueueIndex) => attendanceQueueIndex !== attendanceQueueToDeleteIndex,
    );

    setAttendanceQueues(updateAttendanceQueues);
    Analytics.Track(ANALYTICS.ATTENDANCE_DELETE_TEAM, { screenName: activeSubPage.title });
  };

  const validateQueueNameNotIdentical = (attendanceQueues: QueueQuestion[]) => {
    const queueNames = attendanceQueues.map(attendanceQueue => attendanceQueue.name);
    if (HasAnyIdenticalStrings(queueNames)) {
      throw new Error(TOAST_FAILURE_IDENTICAL_QUEUE_NAMES);
    }
  };

  const handleSaveChanges = async () => {
    const methodName = 'handleSaveChanges';
    const trackingProperties = {} as TrackingProperties;
    try {
      validateQueueNameNotIdentical(attendanceQueues);
      handleValidQueueQuestions();
      const queueQuestionInvalidIndex = attendanceQueues.findIndex(
        attendanceQueue =>
          IsEmptyString(serviceQuestion.description) ||
          IsEmptyString(attendanceQueue.name) ||
          IsEmptyString(attendanceQueue.question) ||
          HasAnySpecialCharacters(attendanceQueue.name),
      );
      if (hasElementByIndex(queueQuestionInvalidIndex)) {
        createToastError(TOAST_FAILURE_QUEUE_INVALID, TOAST_FAILURE_QUEUE_INVALID_MESSAGE);
        Logger.Error(
          formatLogMessage(
            {},
            `One or more fields are blank or have not been filled out correctly. Index: ${queueQuestionInvalidIndex}
                  from: ${attendanceQueues}`,
          ),
          {
            methodName,
            className,
          },
        );
        return;
      }

      await handleSaveAttendants();
      await handleSaveQuestions();
      const queueQuestionsModified = await handleSaveQueuesAndReturn();
      await handleSaveDeletes();
      await handleSavePreServiceQuestion();
      await handleSavePreQueueMessage();

      const queueItemsRegistry = queueQuestionsModified.map(queue => {
        return {
          id: queue.id,
          name: queue.name,
          redirectMessage: queue.question,
        };
      });
      await UpdateQueueItems(router.shortName, serviceQuestion.description, queueItemsRegistry);

      createToastSuccess(TOAST_SUCCESS, TOAST_SUCCESS_MESSAGE);

      trackingProperties.status = 'sucesso';
      trackingProperties.numQueue = attendanceQueues.length;
      trackingProperties.numAgents = attendants.length;
      trackingProperties.screenName = activeSubPage.title;
      trackingProperties.warningModal = false;
      Analytics.Track(ANALYTICS.CLICK_SAVE_BUTTON, trackingProperties);
    } catch (ex: any) {
      handleExpection(ex, methodName);
    }
  };

  const handleExpection = (error: any, methodName: string) => {
    const exception = error as Error;
    const trackingProperties = {} as TrackingProperties;

    createToastError(TOAST_FAILURE, exception?.message);

    trackingProperties.status = 'falha';
    trackingProperties.error = exception.message;
    trackingProperties.numQueue = attendanceQueues.length;
    trackingProperties.numAgents = attendants.length;
    trackingProperties.screenName = activeSubPage.title;
    trackingProperties.warningModal = false;
    Analytics.Track(ANALYTICS.CLICK_SAVE_BUTTON, trackingProperties);

    Logger.Error(formatLogMessage(error, 'There was a failure to save changes.'), {
      methodName,
      className,
    });
  };

  const handleValidQueueQuestions = () => {
    setAttendanceQueues(
      attendanceQueues.map(attendanceQuestion => {
        return {
          ...attendanceQuestion,
          isQueueValid: !HasAnySpecialCharacters(attendanceQuestion.name) && !IsEmptyString(attendanceQuestion.name),
          isQuestionValid: !IsEmptyString(attendanceQuestion.question),
        };
      }),
    );
  };

  const handleSavePreQueueMessage = async () => {
    if (!serviceQuestion.isServiceQuestionModified) return;

    await dispatch(
      setPreQueueMessage({
        key: keyRouter,
        value: serviceQuestion.description,
      }),
    ).unwrap();

    setServiceQuestion(prev => {
      return {
        ...prev,
        isServiceQuestionModified: false,
      };
    });
  };

  const isMaxNumber = () => {
    if (planType == PlanTypeEnum.Basic || planType == PlanTypeEnum.Free) {
      return attendanceQueues.length >= MAX_QUEUE_BASIC;
    } else {
      return attendanceQueues.length === MAX_QUEUE_PRO;
    }
  };

  const handleSaveQueuesAndReturn = async (): Promise<QueueQuestion[]> => {
    const queuesModified = attendanceQueues.filter(queue => queue.isNameModified);

    if (queuesModified.length === 0) return attendanceQueues;

    const queueIdAndValues: IdAndValue[] = [];

    for (const queueModified of queuesModified) {
      const responseSaveQueue = await handleSaveQueue(keyDesk, queueModified);
      await handleSaveRule(keyDesk, responseSaveQueue.resource, queueModified.oldName);
      attendanceQueues.forEach(attendanceQueue => {
        const attendanceQueueName = attendanceQueue.oldName ?? attendanceQueue.name;
        if (attendanceQueueName === queueModified.name || queueModified.isNameModified) {
          queueIdAndValues.push({
            id: responseSaveQueue.resource.id,
            value: queueModified.name.trim(),
          });
        }
      });
    }
    const queues = attendanceQueues.map(attendanceQueue => {
      const index = queueIdAndValues.findIndex(queue => queue.value === attendanceQueue.name);

      if (hasElementByIndex(index)) {
        return {
          ...attendanceQueue,
          isNameModified: false,
          isQuestionModified: false,
          id: queueIdAndValues[index].id,
          oldName: attendanceQueue.name,
        } as QueueQuestion;
      }

      return attendanceQueue;
    });
    setAttendanceQueues(queues);
    return queues;
  };

  const handleSaveQuestions = async () => {
    const questionsModified = attendanceQueues.filter(queue => queue.isQuestionModified);

    if (questionsModified.length === 0) return;

    questionsModified.forEach(questionModified => {
      setAttendanceQueues(
        attendanceQueues.map(attendanceQueue => {
          if (attendanceQueue.oldName === questionModified.oldName) {
            return {
              ...attendanceQueue,
              isQuestionModified: false,
            };
          }

          return attendanceQueue;
        }),
      );
    });
  };

  const handleSaveDeletes = async () => {
    if (deleteAttendanceQueues.length === 0) return;

    handleRemoveTeamFromAttendantWhenIsNewAttendanceQueue();
    await handleRemoveTeamFromAttendantWhenExistAttendanceQueue();

    setDeleteAttendanceQueues([]);
  };

  const handleSaveQueue = async (token: string, queueQuestion: QueueQuestion) => {
    const queueIndex = queues.findIndex(queue => queue.name === queueQuestion.oldName);

    const payload = {
      ownerIdentity: queueQuestion.ownerIdentity,
      name: queueQuestion.name,
      isActive: queueQuestion.isActive,
    } as Queue;

    if (queueQuestion.id) {
      payload.id = queueQuestion.id;
    }

    if (hasElementByIndex(queueIndex)) {
      payload.id = queues[queueIndex].id;
    }

    const responseQueue = await setQueue(token, payload);

    dispatch(updateStateQueue(responseQueue.resource));

    return responseQueue;
  };

  const handleSaveRule = async (token: string, queue: Queue, oldQueueName: string) => {
    const FIRST_POSITION = 0;
    let payload: Rule;

    const ruleQueue = rules.find(rule => rule.team === oldQueueName);

    if (!ruleQueue) {
      payload = {
        id: uuidv4(),
        ownerIdentity: `${desk.shortName}@${MSGING_NET}`,
        relation: 'Contains',
        isActive: true,
        conditions: [
          {
            property: 'Contact.Extras.teams',
            relation: 'Contains',
            values: [queue.name],
          },
        ],
        operator: 'Or',
      } as Rule;
    } else {
      payload = {
        ...ruleQueue,
        conditions: [
          {
            ...ruleQueue.conditions[FIRST_POSITION],
            values: [queue.name],
          },
        ],
      };
    }

    payload.title = `${queue.name} Rule`;
    payload.team = queue.name;
    payload.queueId = queue.uniqueId;

    await setRule(token, payload);

    handleUpdateRule(payload, oldQueueName);
  };

  const handleSavePreServiceQuestion = async () => {
    const methodName = 'handleSavePreServiceQuestion';

    try {
      const preServiceQuestionString = attendanceQueues.map(question => {
        return { [question.name]: question.question };
      });
      await dispatch(
        setPreServiceQuestion({
          key: keyRouter,
          value: JSON.stringify(preServiceQuestionString),
        }),
      ).unwrap();
    } catch (ex: any) {
      createToastError(TOAST_FAILURE, TOAST_FAILURE_MESSAGE);
      Logger.Error(formatLogMessage(ex, 'There was a failure to save changes.'), {
        methodName,
        className,
      });
    }
  };

  const handleSaveAttendants = async () => {
    try {
      const attendantsWithoutNewAttendanceQueue = handleRemoveTeamFromAttendantWhenIsNewAttendanceQueue();

      const attendantsModified = attendantsWithoutNewAttendanceQueue.filter(attendant => {
        return attendant.isTeamModified;
      });

      attendantsModified.forEach(async attendant => {
        await dispatch(
          updateAttendant({
            token: keyDesk,
            email: encodeAttendantBlipEmail(attendant.email),
            teams: attendant.teams,
          }),
        ).unwrap();
      });

      const updateAttendants = attendantsQueue.map(attendantQueue => {
        return { ...attendantQueue, isTeamModified: false };
      });

      setAttendantsQueue(updateAttendants);
      setShowOfferBanner(updateAttendants.length >= MAX_QUEUE_BASIC);
    } catch (ex: any) {
      createToastError(TOAST_FAILURE, TOAST_FAILURE_MESSAGE);
      throw new Error(ex?.message);
    }
  };

  const handleRemoveTeamFromAttendantWhenIsNewAttendanceQueue = () => {
    const teamsToReset: string[] = [];
    const attendanceQueuesIndexNotExist: number[] = [];
    let updateListDeleteAttendanceQueues: QueueQuestion[] = [];

    for (const deleteAttendanceQueue of deleteAttendanceQueues) {
      const queueName = deleteAttendanceQueue.oldName ?? deleteAttendanceQueue.name;
      const queueIndex = queues.findIndex(queue => queue.name === queueName);

      if (!hasElementByIndex(queueIndex)) {
        teamsToReset.push(queueName);

        const attendanceQueueIndex = deleteAttendanceQueues.indexOf(deleteAttendanceQueue);

        attendanceQueuesIndexNotExist.push(attendanceQueueIndex);
      }
    }

    const updateLocalAttendants = attendantsQueue.map(attendantQueue => {
      const attendantQueueIndex = attendantQueue.teams.findIndex(team => teamsToReset.includes(team));

      if (hasElementByIndex(attendantQueueIndex)) {
        const filteredTeams = attendantQueue.teams.filter(team => !teamsToReset.includes(team));

        return {
          ...attendantQueue,
          teams: filteredTeams,
        };
      }

      return attendantQueue;
    });

    setAttendantsQueue(updateLocalAttendants);
    setShowOfferBanner(updateLocalAttendants.length >= MAX_QUEUE_BASIC);

    attendanceQueuesIndexNotExist.forEach(attendanceQueueIndexNotExist => {
      deleteAttendanceQueues.splice(attendanceQueueIndexNotExist, 1);
    });

    updateListDeleteAttendanceQueues = deleteAttendanceQueues;
    setDeleteAttendanceQueues(updateListDeleteAttendanceQueues);

    return updateLocalAttendants;
  };

  const handleRemoveTeamFromAttendantWhenExistAttendanceQueue = async () => {
    if (deleteAttendanceQueues.length === 0) return;

    const attendantsToDelete: Attendant[] = [];

    handleMountAttendantsToDelete(attendantsToDelete);

    const promiseAttendantsToDelete = attendantsToDelete.map(attendantToDelete => {
      return addNewAttendant(keyDesk, encodeAttendantBlipEmail(attendantToDelete.email), attendantToDelete.teams);
    });

    await Promise.all(promiseAttendantsToDelete);

    const promiseAttendanceQueuesToDelete = deleteAttendanceQueues.map(deleteAttendanceQueue => {
      return deleteQueue(keyDesk, deleteAttendanceQueue.id);
    });

    await Promise.allSettled(promiseAttendanceQueuesToDelete).then((results: any) => {
      results.forEach((result: any) => {
        if (result.value.status === 'failure') {
          throw new Error(result.value.reason.description);
        }
      });
    });

    const updateAttendants = attendantsQueue.map(attendant => {
      const attendantsToDeleteIndex = attendantsToDelete.findIndex(
        attendantToDelete => attendantToDelete.email === attendant.email,
      );

      if (hasElementByIndex(attendantsToDeleteIndex)) {
        return {
          ...attendant,
          teams: attendantsToDelete[attendantsToDeleteIndex].teams,
        };
      }

      return attendant;
    });

    dispatch(updateStateAttendants(updateAttendants));
    setAttendantsQueue(updateAttendants);
    setShowOfferBanner(updateAttendants.length >= MAX_QUEUE_BASIC);
    dispatch(updateQueuesAnRules(attendanceQueues));
  };

  const handleMountAttendantsToDelete = (attendantsToDelete: Attendant[]) => {
    for (const deleteAttendanceQueue of deleteAttendanceQueues) {
      const queueName = deleteAttendanceQueue.oldName ?? deleteAttendanceQueue.name;

      attendantsQueue.forEach(attendantQueue => {
        if (attendantQueue.teams.includes(queueName)) {
          const attendantDeleteIndex = attendantsToDelete.findIndex(
            attendant => attendant.email === attendantQueue.email,
          );

          if (hasElementByIndex(attendantDeleteIndex)) {
            const filteredTeams = attendantsToDelete[attendantDeleteIndex].teams.filter(team => team !== queueName);

            attendantsToDelete[attendantDeleteIndex].teams = filteredTeams;
          } else {
            const filteredTeams = attendantQueue.teams.filter(team => team !== queueName);

            attendantsToDelete.push({
              email: attendantQueue.email,
              teams: filteredTeams,
            } as Attendant);
          }
        }
      });
    }
  };

  const handleUpdateRule = (rule: Rule, oldQueueName: string) => {
    const ruleWithOldQueue = {
      rule: rule,
      oldQueueName: oldQueueName,
    } as RuleWithOldQueue;

    dispatch(updateStateRule(ruleWithOldQueue));
  };

  const isAttendantTeam = (team: string, attendant: Attendant) => {
    return attendant.teams.includes(team);
  };

  const handleChangeAttendantTeam = (
    e: React.MouseEvent<HTMLBdsCheckboxElement, MouseEvent>,
    queueName: string,
    attendant: Attendant,
  ) => {
    e.preventDefault();

    const isAttendantTeamByQueueName = isAttendantTeam(queueName, attendant);

    const updateAttendants = attendantsQueue.map(attendantQueue => {
      if (attendantQueue.email === attendant.email) {
        if (isAttendantTeamByQueueName) {
          const filteredTeams = attendantQueue.teams.filter(team => team !== queueName);

          return {
            ...attendantQueue,
            teams: filteredTeams,
            isTeamModified: true,
          };
        } else {
          return {
            ...attendantQueue,
            teams: [...attendantQueue.teams, queueName],
            isTeamModified: true,
          };
        }
      }

      return attendantQueue;
    });

    setAttendantsQueue(updateAttendants);
    setShowOfferBanner(updateAttendants.length >= MAX_QUEUE_BASIC);
  };

  const handleCheckboxIndex = (email: string, index: number) => {
    return `${index}_${email}`;
  };

  const handleQueueMessageError = (queueName: string) => {
    if (HasAnySpecialCharacters(queueName)) {
      return error.queueWithoutSpecialCharacter;
    }

    if (IsEmptyString(queueName)) {
      return error.requiredField;
    }

    return '';
  };

  return {
    attendantsQueue,
    serviceQuestion,
    attendanceQueues,
    formatQueues,
    isMaxNumber,
    handleNewQueue,
    isAttendantTeam,
    handleDeleteQueue,
    handleSaveChanges,
    handleCheckboxIndex,
    handleChangeQueueName,
    handleQueueMessageError,
    handleChangeAttendantTeam,
    handleChangeQueueQuestion,
    handleChangePreQueueMessage,
    isDeletionAlertOpen,
    setIsDeletionAlertOpen,
    showOfferBanner,
    planType,
  };
};

export default useAttendanceQueue;
