import { ANALYTICS } from './Analytics';

export const HOW_DELETE_NUMBER = 'https://faq.whatsapp.com/2138577903196467/?locale=pt_BR&cms_platform=android';
export const REPORT_PROBLEM =
  'https://api.whatsapp.com/send/?phone=553192670115&text=Gostaria de reportar um *problema* no *preparo do número*.';
export const WHATSAPP_FAQ = 'https://faq.whatsapp.com/';

export const CONTRACT_FIELDS = [
  {
    title: 'Razão Social*',
    fieldName: 'corporateName',
    error: '',
  },
  {
    title: 'Nome Fantasia*',
    fieldName: 'companyName',
    error: '',
  },
  {
    title: 'Endereço da empresa*',
    fieldName: 'companyAddress',
    error: '',
  },
];

export const PROCURATOR = [
  {
    title: 'Nome completo do representante legal*',
    fieldName: 'procuratorName',
    error: '',
  },
  {
    title: 'CPF do representante legal*',
    fieldName: 'procuratorCPF',
    error: '',
  },
  {
    title: 'E-mail do representante legal*',
    fieldName: 'procuratorEmail',
    error: '',
  },
];

export const PROCURATOR_POSITION = [
  { value: 'Analista', label: 'Analista' },
  { value: 'Assistente', label: 'Assistente' },
  { value: 'Coordenador(a)', label: 'Coordenador(a)' },
  { value: 'Desenvolvedor(a)', label: 'Desenvolvedor(a)' },
  { value: 'Diretor(a)', label: 'Diretor(a)' },
  { value: 'Estagiário(a)', label: 'Estagiário(a)' },
  { value: 'Estudante', label: 'Estudante' },
  { value: 'Fundador(a)', label: 'Fundador(a)' },
  { value: 'Gerente', label: 'Gerente' },
];

export const INFORMATION_REVIEW_PLAN = {
  title: 'Resumo do meu novo plano: Blip Go! PRO',
  subitems: [
    '✅ Canais inclusos: WhatsApp, Instagram, Messenger e Chat para site',
    '✅ 450 conversas mensais',
    '✅ 1 número WhatsApp Business API oficial para todos os atendentes',
    '✅ Campanha de marketing via WhatsApp (envio de mensagens em massa)',
    '✅ Auxílio para solicitar selo de verificação do WhatsApp',
    '✅ 7 celulares conectados aos WhatsApp Bip Go!',
    '✅ 5 equipes de atendimento',
    '✅ 7 atendentes',
    '✅ Indicadores de atendimento',
  ],
  cardColor: '#C5D9FB',
};

export const INFORMATION_REVIEW_PAY = {
  title: 'Forma de pagamento',
  subitems: [
    'R$399/mês em boleto (enviado ao e-mail de cobrança) *',
    'Conversas extras por apenas R$1,00 (cada) **',
    'Atendentes extras por R$150,00 (cada)',
  ],
  observations: [
    '* Faturamento até o 5º dia útil do mês, com início da cobrança após a contratação',
    '** Os extras só serão cobrados se usados durante o mês.',
  ],
  cardColor: '#FFFFFF',
};

export const INFORMATION_REVIEW_CONTRACT_INFO = {
  title: 'Dados da empresa e representante',
  cnpjLabel: 'CNPJ',
  companyLabel: 'Razão Social',
  siteLabel: 'Site',
  agentName: 'Nome completo',
  agentEmail: 'E-mail',
  agentPos: 'Cargo atual',
  cardColor: '#FFFFFF',
};

export const CONTRACT_STEPPER = {
  companyData: 0,
  companyAgent: 1,
  brief: 2,
  finishPage: 3,
};

export const CONTRACT_ERROR = {
  invalidCnpj: 'O CNPJ inserido é inválido. Certifique-se de que todos os dígitos foram inseridos corretamente.',
  invalidEmail: 'O e-mail inserido não está em um formato válido. Por favor, verifique e tente novamente.',
  invalidWebsite: 'O site inserido não parece ser um domínio válido. Verifique e insira novamente',
  emptyField: 'O preenchimento desse campo é obrigatório.',
  agentNameMinLength: 'O nome precisa ser maior que 05 caracteres.',
};

export const WPP_POLICY_TERMS = 'https://www.whatsapp.com/legal/commerce-policy/?lang=pt_br';

export const CONNECTION_CHOICE = {
  self: {
    key: 'manual',
    helpText:
      'Recebemos a sua solicitação, em algumas horas o sistema estará liberado para conectar o WhatsApp Blip Go!',
    icon: 'user-default',
    title: 'Quero conectar sozinho',
    description: 'Realizar o processo de conexão e ativação do WhatsApp de forma independente.',
    buttonLabel: 'Conectar sozinho',
    tracking: ANALYTICS.INDEPENDENT_CONNECTION,
    successToast: 'Em breve será possível realizar a ativação!',
    buttonId: 'self-choice-button',
  },
  assisted: {
    key: 'assisted',
    helpText:
      'Recebemos a sua solicitação, em algumas horas nosso time entrará em contato para lhe auxiliar a conectar o WhatsApp Blip Go!',
    icon: 'agent',
    title: 'Quero conectar com especialista',
    description: 'Realizar o processo de conexão e ativação do WhatsApp junto a um especialista',
    buttonLabel: 'Conectar com especialista',
    tracking: ANALYTICS.ASSISTED_CONNECTION,
    successToast: 'Em breve nosso time entrará em contato!',
    buttonId: 'assisted-choice-button',
  },
  pending: {
    key: 'pending',
  },
};
