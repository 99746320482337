import { format, isToday, isYesterday } from 'date-fns';
import { MessageItemDto } from 'objects/types/MessagesResponse';

export function getMessageContent(message: MessageItemDto): JSX.Element | string | undefined {
  const { type, content } = message;

  switch (type) {
    case 'application/vnd.lime.reply+json':
      // Retorna o valor de resposta para tipos de mensagem 'reply'
      return content.replied.value;

    case 'application/vnd.lime.select+json':
      // Retorna o texto para tipos de mensagem 'reply'
      return content.text;

    case 'application/vnd.lime.media-link+json':
      if (content.type.startsWith('audio/')) {
        // Retorna o componente de áudio para tipos de mídia 'audio'
        return (
          <audio controls>
            <source src={content.uri} type={content.type} />
            Your browser does not support the audio element.
          </audio>
        );
      } else if (content.type.startsWith('image/')) {
        // Retorna o componente de imagem para tipos de mídia 'image'
        return <img src={content.uri} alt="Media content" />;
      }
      break;

    case 'application/json':
      if (content.type === 'template') {
        // Formata e retorna a mensagem do template
        return formatTemplateMessage(content);
      }
      break;

    case 'application/vnd.iris.ticket+json':
      // Retorna a mensagem do ticket
      return `O chatbot encaminhou a conversa para o atendimento,<br/><b>Ticket #${content.sequentialId}</b>`;

    default:
      // Retorna o conteúdo como string ou JSON stringificado
      return typeof content === 'string' ? content : JSON.stringify(content);
  }
}

function formatTemplateMessage(content: any): string {
  const { templateContent, template } = content;

  if (!templateContent || !templateContent.components) {
    return 'Conteúdo do template inválido.';
  }

  const bodyComponent = templateContent.components.find((comp: any) => comp.type === 'BODY');
  let formattedText = bodyComponent ? bodyComponent.text : '';

  if (template && template.components) {
    let index = 1; // Inicia com 1 para os placeholders {{1}}, {{2}}, etc.

    template.components.forEach((comp: any) => {
      if (comp.type === 'body' && comp.parameters) {
        comp.parameters.forEach((param: any) => {
          if (param.type === 'text') {
            formattedText = formattedText.replace(`{{${index}}}`, param.text);
            index++;
          }
        });
      }
    });
  }

  const format = formattedText.replace(/\*(.*?)\*/g, '<b>$1</b>').replace(/\\n/g, '<br/>');

  const response = `<b>Modelo de mensagem: ${template.name}</b><br/><br/>${format}`;

  return response;
}

export const formatTimestamp = (timestamp: string | Date): string => {
  const date = new Date(timestamp);
  if (isToday(date)) {
    return `Hoje, ${format(date, 'HH:mm')}`;
  } else if (isYesterday(date)) {
    return `Ontem, ${format(date, 'HH:mm')}`;
  } else {
    return format(date, 'dd/MM, HH:mm');
  }
};

export const formatLastMessageDate = (date: Date | string): string => {
  if (isToday(date)) {
    return 'Hoje';
  } else if (isYesterday(date)) {
    return 'Ontem';
  } else {
    return format(date, 'dd/MM');
  }
};

export const formatLastMessageTime = (date: Date): string => {
  return format(date, 'HH:mm');
};

export const selectSourceChannelIcon = (source: string | undefined): string => {
  switch (source) {
    case 'WhatsApp':
      return 'whatsapp';
    case 'messenger':
      return 'messenger';
    case 'telegram':
      return 'telegram';
    case 'instagram':
      return 'instagram';
    case 'Instagram':
      return 'instagram';
    case '0mn.io':
      return 'blip-chat';
    default:
      return 'blip-chat';
  }
};
