import { AxiosInstance, AxiosResponse } from 'axios';
import IgnoreCommandsRequest from 'constants/IgnoreCommandsRequests';
import { Logger } from 'infra/adapters';
import { HttpConnector } from 'infra/connectors/HttpConnector';
import { ApiResponse, AxiosRequestConfig, IRestService } from 'objects/interfaces/IRestService';
import { CommandRequest } from 'objects/types/CommandRequest';
import { LogArgs } from 'objects/types/Log';

export abstract class BaseRestService<T> extends HttpConnector implements IRestService<T> {
  private api: AxiosInstance;

  private readonly serviceOrigin: string;
  private readonly methodOrigin: string;

  public constructor(baseUrl: string, serviceOrigin: string, methodOrigin: string) {
    super(baseUrl);

    this.setInstanceDefaultTimeOut();
    this.setInstanceDefaultHeaders();
    this.setInstanceInterceptors();
    this.api = this.httpClient;
    this.methodOrigin = methodOrigin;
    this.serviceOrigin = serviceOrigin;
  }

  /**
   * Used to define defaults header for each request to the Rest service
   * - ex.: every request must include a 'Content-Type'
   * - use `this.getDefaults()` to set `headers.common['Content-Type']`
   *
   * @returns void
   */
  protected abstract setInstanceDefaultHeaders(): void;

  /**
   * Used to define defaults timeout for each request to the Rest service
   * @example const numToMs = (num: number) => num * 1000;
   * this.getDefaults().timeout = 6000 // 6 seconds
   * this.getDefaults().timeout = 30000 // 30 seconds
   * this.getDefaults().timeout = 60000 // 1 minute
   * this.getDefaults().timeout = numToMs(15) // 15 minutes
   *
   * @property timeout milliseconds number to define an timeout
   *
   * @returns void
   */
  protected abstract setInstanceDefaultTimeOut(): void;

  /**
   * Used to define interceptor for each request to the Rest service
   *
   * @example this.getInterceptors().response.use(this.handleResponse, this.handleError)
   *  this.getInterceptors().request.use(
   * (config: any) => {
   *        // Api structure required
   *        config.data = { 'data': config.data };
   *        return config;
   *    })
   *
   *
   * @returns void
   */
  protected abstract setInstanceInterceptors(): void;

  protected getDefaults = () => this.httpClient.defaults;
  protected getInterceptors = () => this.httpClient.interceptors;
  protected handleResponse = (res: AxiosResponse) => res;
  protected handleError = (err: any) => err.response || err;

  /**
   * Validate request response and format it to a `ApiResponse`
   * container
   *
   * @param T the type of response from client Rest API
   *
   * @param response An http client response to be formatted
   *
   * @returns Promise<ApiResponse<T>>
   */
  private transformResponse = <T>(response: any) => {
    const { status, message, request, config } = response || {};

    const succeeded: boolean = [200, 201, 202, 204].includes(status);
    const data = succeeded ? response.data : undefined;
    const errors = succeeded ? undefined : response;
    const info = succeeded ? undefined : message ?? request?.responseText;

    const result: ApiResponse<T> = { data, errors, info, status, succeeded };
    const configString = JSON.stringify(config);
    const resultDataString = JSON.stringify(result.data);

    const logArgs: LogArgs = {
      className: this.serviceOrigin,
      methodName: this.methodOrigin,
    };

    if (succeeded) {
      if (response.data.status === 'failure') {
        const commandRequestTyped = JSON.parse(config.data) as CommandRequest;

        if (!IgnoreCommandsRequest.includes(commandRequestTyped.uri)) {
          const resultErrorsString = response.data.reason.description;
          const messageLog = `The service request failed
          | service: '${this.serviceOrigin}'
          | config: '${configString}'
          | data: '${resultDataString}'
          | error: '${resultErrorsString}`;

          Logger.Error(messageLog, logArgs);
        }
      }

      const messageLog = `The service request succeeded
      | service: '${this.serviceOrigin}'
      | config: '${configString}'
      | data: '${resultDataString}'`;

      Logger.Info(messageLog, logArgs);
    } else {
      const resultErrorsString = JSON.stringify(result.errors);
      const messageLog = `The service request failed
      | service: '${this.serviceOrigin}'
      | config: '${configString}'
      | data: '${resultDataString}'
      | error: '${resultErrorsString}`;

      if (result.status && result.status >= 500) {
        Logger.Fatal(messageLog, logArgs);
      } else {
        Logger.Error(messageLog, logArgs);
      }
    }

    return new Promise<ApiResponse<T>>((resolve, reject) => {
      if (succeeded) {
        resolve(result);
      } else {
        reject(result?.errors);
      }
    });
  };

  Get = async <T>(path: string, config?: AxiosRequestConfig) => {
    return this.api
      .get(path, config)
      .then(this.transformResponse<T>)
      .catch(this.transformResponse<T>);
  };

  Put = async <T>(path: string, data: any, config?: AxiosRequestConfig<any>) => {
    return this.api
      .put(path, data, config)
      .then(this.transformResponse<T>)
      .catch(this.transformResponse<T>);
  };

  Post = async <T>(path: string, data: any, config?: AxiosRequestConfig<any>) => {
    return await this.api
      .post(path, data, config)
      .then(this.transformResponse<T>)
      .catch(this.transformResponse<T>);
  };

  Patch = async <T>(path: string, data: any, config?: AxiosRequestConfig<any>) => {
    return this.api
      .patch(path, data, config)
      .then(this.transformResponse<T>)
      .catch(this.transformResponse<T>);
  };

  Delete = async <T>(path: string, config?: AxiosRequestConfig<any>) => {
    return this.api
      .delete(path, config)
      .then(this.transformResponse<T>)
      .catch(this.transformResponse<T>);
  };
}
