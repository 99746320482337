import { BdsSwitch, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { MainContainer } from './styles';

type SwitchProps = {
  name: string;
  text: string;
  onChange: (event: any) => void;
  checked?: boolean;
  isEmojiSwitch: boolean;
  dataTestId?: string;
};

const dayOfWeekMap: { [key: string]: string } = {
  Sunday: 'Domingo',
  Monday: 'Segunda',
  Tuesday: 'Terça',
  Wednesday: 'Quarta',
  Thursday: 'Quinta',
  Friday: 'Sexta',
  Saturday: 'Sábado',
};

export const Switch = ({ name, text, onChange, checked, isEmojiSwitch, dataTestId }: SwitchProps) => {
  const textInPortuguese = dayOfWeekMap[text] || text;
  return (
    <MainContainer $isEmojiSwitch={isEmojiSwitch}>
      <BdsSwitch name={name} checked={checked} refer="" onBdsChange={onChange} data-testid={dataTestId} />

      <BdsTypo className="typo" variant="fs-14">
        {textInPortuguese}
      </BdsTypo>
    </MainContainer>
  );
};
