import styled from 'styled-components';

export const CardChannelContent = styled.div`
  width: 217.5px;
  border-radius: 7px;
  background: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

export const Connected = styled.div`
  width: 75px;
  border-radius: 150px;
  background: #84ebbc;
`;

export const MessageConnected = styled.div`
  width: 169.5px;
  text-align: center;
`;

export const TitlePlan = styled.div`
  width: 84px;
  border-radius: 10px;
  border-color: #a5a5a5;
  background: #f6f6f6;
  border: 0.1px solid;
  font-size: 0.75rem;
`;

export const BlipChatContent = styled.div`
  text-decoration: underline;
  cursor: pointer;
  font-style: italic;
`;
