import { Modules } from 'constants/Modules';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { addActiveModule } from 'redux/slices/ProjectSlice/projectSlice';

function useActiveModuleManager() {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const moduleHandler = () => {
      const moduleNames = Object.keys(Modules);
      const formatedModules = Modules as Record<string, any>;
      const currentModule = moduleNames.find(module => location.pathname.includes(formatedModules[module].route));
      if (currentModule) {
        dispatch(addActiveModule(formatedModules[currentModule]));
      }
    };

    moduleHandler();
  }, [location.pathname]);
}

export default useActiveModuleManager;
