import { FacebookBusinessSystemUserToken } from 'configurations/Environment';
import { GRAPHAPI_WABAS_ID_ROUTE } from 'constants/GraphApiRoutes';
import { GraphApi } from 'infra/adapters';
import { PhoneNumber, PhoneNumberResponse, WabaIdResponse } from 'objects/types/WhatsappActivation';

export const getWabaPhoneNumbersById = async (wabaId: string): Promise<PhoneNumber[]> => {
  const url = `/${wabaId}/${GRAPHAPI_WABAS_ID_ROUTE}`;
  try {
    const graphApi = new GraphApi('getWabaPhoneNumbersById');

    const response = await graphApi.Get<PhoneNumberResponse>(url);
    const { data } = response.data;
    const phoneNumbers: PhoneNumber[] = data.map(({ display_phone_number, id }: any) => ({
      displayPhoneNumber: display_phone_number,
      id,
    }));
    return phoneNumbers;
  } catch (error) {
    return [];
  }
};

export const getWabaIdsAndAppName = async (
  inputToken: string,
): Promise<{ wabaIds: string[]; applicationName: string }> => {
  const graphApi = new GraphApi('getWabaIdsAndAppName');

  const url = `/debug_token?input_token=${inputToken}
  &access_token=${FacebookBusinessSystemUserToken}`;
  const response = await graphApi.Get<WabaIdResponse>(url);
  const { data } = response.data;

  try {
    let targetIds: string[];
    const waScope = data.granular_scopes.find(scope => scope.scope === 'whatsapp_business_management');
    if (waScope?.target_ids && waScope?.target_ids?.length > 0) {
      targetIds = waScope.target_ids;
    } else {
      targetIds = [];
    }
    return Promise.resolve({ wabaIds: targetIds, applicationName: data.application });
  } catch (error) {
    return Promise.reject(new Error(error as string));
  }
};
