import { BdsButton, BdsGrid, BdsPaper, BdsTooltip, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { useState } from 'react';
import { emailWithEmailSeparator } from 'utils/BlipEncodeDecode';
import { MemberEmail } from './styles';

interface IMemberItem {
  buttonDeleteEnabled: boolean;
  email: string;
  profileName: string;
  onDeleteEvent: () => void;
}
export const MemberItem = ({ email, profileName, onDeleteEvent, buttonDeleteEnabled }: IMemberItem) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = () => {
    setIsLoading(true);
    onDeleteEvent();
  };

  return (
    <BdsPaper style={{ padding: '1rem', width: '100%' }}>
      <BdsGrid justifyContent="space-between" alignItems="center">
        <BdsTooltip tooltip-text={email} position="bottom-center">
          <MemberEmail>{email}</MemberEmail>
        </BdsTooltip>
        <BdsGrid direction="column">
          <BdsTypo bold="bold">Permissão:</BdsTypo>
          <BdsTypo>{profileName}</BdsTypo>
        </BdsGrid>
        <BdsGrid style={{ width: '7.5rem' }}>
          {buttonDeleteEnabled && (
            <BdsButton
              variant="secondary"
              icon="trash"
              onBdsClick={handleDelete}
              bdsLoading={isLoading}
              disabled={isLoading}
              data-testid={`delete-${emailWithEmailSeparator(email)}`}
            >
              Remover
            </BdsButton>
          )}
        </BdsGrid>
      </BdsGrid>
    </BdsPaper>
  );
};
