import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ResourceAutomaticResponse } from 'objects/types/resources/ResourceAutomaticResponse';
import { ResourceDays } from 'objects/types/resources/ResourceDays';
import { ResourceMessage } from 'objects/types/resources/ResourceMessage';
import { Resources } from 'objects/types/resources/Resources';
import { IResourceState } from './interface';
import { setPreQueueMessage, setPreServiceQuestion } from './thunkMiddleware';

const InitialState: IResourceState = {
  days: {
    holiday: {
      end: '',
      start: '',
    },
    saturday: {
      end: '',
      start: '',
    },
    sunday: {
      end: '',
      start: '',
    },
  } as ResourceDays,
  emoji: '',
  holidayAttendance: '',
  holidays: '',
  messages: {
    firstContactMessage: '',
    preQueueMessage: '',
    returnMessage: '',
    titleCallUS: '',
    workdayWithSaturday: '',
  } as ResourceMessage,
  preServiceQuestionList: '',
  semFaq: false,
  serviceNote: '',
  workDays: '',
  workSchedule: '',
  automaticResponses: [] as ResourceAutomaticResponse[],
  openHour: '',
};

const resourceSlice = createSlice({
  name: 'resources',
  initialState: InitialState,
  reducers: {
    updateResources(state, { payload }: PayloadAction<Resources>) {
      state.days = payload.days;
      state.emoji = payload.emoji;
      state.holidayAttendance = payload.holidayAttendance;
      state.holidays = payload.holidays;
      state.messages = payload.messages;
      state.preServiceQuestionList = payload.preServiceQuestionList;
      state.semFaq = payload.semFaq;
      state.serviceNote = payload.serviceNote;
      state.workDays = payload.workDays;
      state.workSchedule = payload.workSchedule;
      state.automaticResponses = payload.automaticResponses;
      state.openHour = payload.openHour;
    },
    updateMessages(state, { payload }: PayloadAction<ResourceMessage>) {
      return { ...state, messages: payload };
    },
    updateAutomaticResponses(state, { payload }: PayloadAction<ResourceAutomaticResponse[]>) {
      return { ...state, automaticResponses: payload };
    },
    updateWorkSchedule(state, { payload }: PayloadAction<string>) {
      return { ...state, workSchedule: payload };
    },
    updateOpenHour(state, { payload }: PayloadAction<string>) {
      return { ...state, openHour: payload };
    },
    updateWorkDays(state, { payload }: PayloadAction<string>) {
      return { ...state, workDays: payload };
    },
    updateDays(state, { payload }: PayloadAction<ResourceDays>) {
      return { ...state, days: payload };
    },
    updateHolidays(state, { payload }: PayloadAction<string>) {
      return { ...state, holidays: payload };
    },
    updateHolidayAttendance(state, { payload }: PayloadAction<string>) {
      return { ...state, holidayAttendance: payload };
    },
    updateWorkdayWithSaturday(state, { payload }: PayloadAction<string>) {
      return { ...state, workdayWithSaturday: payload };
    },
    updateFaqDisabled(state, { payload }: PayloadAction<boolean>) {
      return { ...state, semFaq: payload };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(setPreServiceQuestion.fulfilled, (state, { payload }: PayloadAction<string>) => {
        state.preServiceQuestionList = payload;
      })
      .addCase(setPreQueueMessage.fulfilled, (state, { payload }: PayloadAction<string>) => {
        state.messages.preQueueMessage = payload;
      });
  },
});

export default resourceSlice.reducer;

export const {
  updateResources,
  updateMessages,
  updateWorkSchedule,
  updateOpenHour,
  updateWorkDays,
  updateDays,
  updateHolidays,
  updateHolidayAttendance,
  updateWorkdayWithSaturday,
  updateAutomaticResponses,
  updateFaqDisabled,
} = resourceSlice.actions;

export const useResource = (state: { resources: IResourceState }) => state.resources;
