import AllowMessages from 'assets/facebook-activate-allow-message-access.svg';
import { BdsButton, BdsGrid, BdsIcon, BdsIllustration, BdsStep, BdsStepper, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import {
  HOME_ROUTE,
  INSTAGRAM_ACTIVATION_BIND_PAGES,
  INSTAGRAM_ACTIVATION_FACEBOOK_CONNECTION,
} from 'constants/Routes';
import { Paper } from 'globalStyles';
import useGraphApi from 'hooks/useGraphApi';
import { Analytics } from 'infra/adapters';
import { FacebookPage, InstagramActivationErrors, User } from 'objects/types/Facebook';
import { useAuth } from 'oidc-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useInstallation } from 'redux/slices/InstallationSlice/installationSlice';
import { updateInstagramActive } from 'redux/slices/ProjectSlice/projectSlice';
import { ActivateInstagram } from 'services/PackService';
import { createToastError, createToastSuccess } from 'services/Toats';

function MessagesAccessChecklist() {
  const { validateInstagramPageAndReturnDetails } = useGraphApi();
  const locationState = useLocation()?.state as { page: FacebookPage; user: User };
  const [isLoading, setIsLoading] = useState(false);
  const { router } = useSelector(useInstallation);
  const auth = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRedirectToFacebookClick = async () => {
    const url = locationState?.page?.id
      ? `https://www.facebook.com/${locationState.page.id}`
      : 'https://www.facebook.com';
    window.open(url, '_blank');
  };

  const handleActivate = async () => {
    if (!auth.userData) {
      return location.reload();
    }

    try {
      setIsLoading(true);
      const accountDetails = await validateInstagramPageAndReturnDetails(
        locationState.user.id,
        locationState.page.id,
        locationState.page.access_token,
      );
      await ActivateInstagram(router.shortName, locationState.user.id, accountDetails, auth.userData.access_token);
      Analytics.Track(Analytics.events.INSTAGRAM_ACTIVATION_CONNECTION_SUCCESS, { accountDetails: accountDetails });
      await createToastSuccess('Instagram ativado com sucesso!');
      dispatch(updateInstagramActive(true));
      navigate(HOME_ROUTE);
    } catch (error) {
      switch (error) {
        case InstagramActivationErrors.NoBindedPagesFound:
          Analytics.Track(Analytics.events.INSTAGRAM_ACTIVATION_ERROR, {
            error: 'No binded pages found',
          });
          navigate(INSTAGRAM_ACTIVATION_BIND_PAGES, { state: { user: locationState.user, page: locationState.page } });
          return;
        case InstagramActivationErrors.InvalidInstagramAccount:
          Analytics.Track(Analytics.events.INSTAGRAM_ACTIVATION_ERROR, {
            error: 'Invalid Instagram account',
          });
          await createToastError('Conta do Instagram inválida', 'Por favor, faça o login novamente');
          navigate(INSTAGRAM_ACTIVATION_FACEBOOK_CONNECTION);
          break;
        case InstagramActivationErrors.NoMessagesAccess:
          await createToastError('O acesso às mensagens não está ativado.', 'Favor seguir os passos corretamente.');
          break;
        default:
          Analytics.Track(Analytics.events.INSTAGRAM_ACTIVATION_ERROR, {
            error: error,
          });
          await createToastError('Erro ao ativar o Instagram', 'Por favor, tente novamente mais tarde');
          break;
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    Analytics.Track(Analytics.events.INSTAGRAM_ACTIVATION_ERROR, { error: 'Enable messages access page' });
  }, []);

  return (
    <Paper>
      <BdsGrid direction="column" padding="3" gap="4">
        <BdsStepper>
          <BdsStep>Selecionar página</BdsStep>
          <BdsStep>Vincular contas</BdsStep>
          <BdsStep active>Conectar Instagram</BdsStep>
        </BdsStepper>
        <BdsGrid justifyContent="center" gap="2">
          <BdsGrid height="10rem" padding="b-6">
            <BdsIllustration type="spots" name="letter-cancel-error" />
          </BdsGrid>
          <BdsGrid direction="column">
            <BdsGrid margin="t-1">
              <BdsTypo tag="span" variant="fs-24" bold="bold">
                O acesso às mensagens não está ativado.
              </BdsTypo>
            </BdsGrid>
            <BdsGrid direction="column" gap="2" margin="t-4">
              <BdsGrid direction="row">
                <BdsIcon type="icon" name="blip-ideas" />
                <BdsTypo tag="p" variant="fs-16" bold="bold" lineHeight="plus">
                  Como solucionar o problema:
                </BdsTypo>
              </BdsGrid>
              <BdsGrid>
                <ul style={{ paddingLeft: '2rem' }}>
                  <li>
                    <BdsTypo tag="span" variant="fs-14" lineHeight="plus">
                      Acesse a sua página do facebook.
                    </BdsTypo>
                  </li>
                </ul>
              </BdsGrid>
              <BdsGrid>
                <BdsButton
                  onBdsClick={() => {
                    Analytics.Track(Analytics.events.INSTAGRAM_ACTIVATION_OPEN_FACEBOOK_PAGE_NEW_TAB);
                    handleRedirectToFacebookClick();
                  }}
                  variant="tertiary"
                  arrow
                >
                  Acessar página do Facebook
                </BdsButton>
              </BdsGrid>
              <BdsGrid>
                <ul style={{ paddingLeft: '2rem' }}>
                  <li>
                    <BdsTypo tag="span" variant="fs-14" lineHeight="plus">
                      No menu esquerdo acesse: <b>Configurações &gt; Contas vinculadas.</b>
                    </BdsTypo>
                  </li>
                  <li>
                    <BdsTypo tag="span" variant="fs-14" lineHeight="plus">
                      Ative o campo “Permitir acesso a mensagens do Instagram na caixa de entrada” e tente a conexão
                      novamente.
                    </BdsTypo>
                  </li>
                </ul>
              </BdsGrid>
              <BdsGrid>
                <img className="w-100" src={AllowMessages} alt="Facebook Allow Messages" />
              </BdsGrid>
            </BdsGrid>
            <BdsGrid justifyContent="flex-end" margin="t-4">
              <BdsButton
                bdsLoading={isLoading}
                onBdsClick={() => {
                  Analytics.Track(Analytics.events.INSTAGRAM_ACTIVATION_ERROR_BACK);
                  handleActivate();
                }}
                icon="refresh"
                arrow
              >
                Tentar novamente
              </BdsButton>
            </BdsGrid>
          </BdsGrid>
        </BdsGrid>
      </BdsGrid>
    </Paper>
  );
}

export default MessagesAccessChecklist;
