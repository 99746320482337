import { BdsGrid } from 'blip-ds/dist/blip-ds-react';
import { Container } from './style';

interface IConfigurationsContainer {
  children: React.ReactNode;
}

const ConfigurationsContainer = ({ children }: IConfigurationsContainer) => (
  <Container>
    <BdsGrid padding="4" alignItems="flex-start" justifyContent="center">
      {children}
    </BdsGrid>
  </Container>
);

export default ConfigurationsContainer;
