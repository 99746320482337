import LayoutContainer from 'components/LayoutContainer/LayoutContainer';
import MainLayout from 'components/MainLayout/MainLayout';
import useConfigurationPagesManager from 'hooks/useConfigurationPagesManager';
import { Outlet } from 'react-router-dom';

const Configurations: React.FC = () => {
  useConfigurationPagesManager();

  return (
    <MainLayout>
      <LayoutContainer>
        <Outlet />
      </LayoutContainer>
    </MainLayout>
  );
};

export default Configurations;
