import { createAsyncThunk } from '@reduxjs/toolkit';
import Attendant from 'objects/types/Attendant';
import Queue from 'objects/types/Queue';
import Rule from 'objects/types/Rule';
import {
  addNewAttendant,
  deleteAttendant,
  getAllAttendants,
  getAllQueues,
  getAllRules,
} from 'services/CommandsService';
import { decodeAfterToDeleteAttendantBlipEmail, decodeAttendantBlipEmail } from 'utils/BlipEncodeDecode';
import { IAttendant } from './interface';

export const fetchAllAttendants = createAsyncThunk(
  'customerService/fetchAllAttendants',
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await getAllAttendants(token);
      const attendants = response.resource.items.map(attendant => {
        return { email: decodeAttendantBlipEmail(attendant.identity), teams: attendant.teams } as Attendant;
      });

      return attendants;
    } catch (ex) {
      const error = ex as Error;
      return rejectWithValue(error.message);
    }
  },
);

export const addAttendant = createAsyncThunk(
  'customerService/addNewAttendant',
  async ({ token, email }: IAttendant, { rejectWithValue }) => {
    try {
      const DEFAULT_TEAM = ['Default'];

      await addNewAttendant(token, email, DEFAULT_TEAM);

      return { email: decodeAttendantBlipEmail(email), teams: DEFAULT_TEAM } as Attendant;
    } catch (ex) {
      const error = ex as Error;
      return rejectWithValue(error.message);
    }
  },
);

export const updateAttendant = createAsyncThunk(
  'customerService/updateAttendant',
  async ({ token, email, teams }: IAttendant, { rejectWithValue }) => {
    try {
      const response = await addNewAttendant(token, email, teams);

      if (!response) return {} as Attendant;

      return { email: decodeAttendantBlipEmail(email), teams } as Attendant;
    } catch (ex) {
      const error = ex as Error;
      return rejectWithValue(error.message);
    }
  },
);

export const removeAttendant = createAsyncThunk(
  'customerService/removeAttendant',
  async ({ token, email }: IAttendant, { rejectWithValue }) => {
    try {
      await deleteAttendant(token, email);

      return { email: decodeAfterToDeleteAttendantBlipEmail(email) } as Attendant;
    } catch (ex) {
      const error = ex as Error;
      return rejectWithValue(error.message);
    }
  },
);

export const fetchAllQueues = createAsyncThunk(
  'customerService/fetchAllQueues',
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await getAllQueues(token);

      if (!response) return [] as Queue[];
      const queuesWithoutDefault = response.resource.items.filter(queue => queue.name !== 'Default');
      const formattedQueues = queuesWithoutDefault
        .map(queue => {
          return {
            id: queue.id,
            name: queue.name,
            isActive: queue.isActive,
            ownerIdentity: queue.ownerIdentity,
          } as Queue;
        })
        .reverse();
      return formattedQueues;
    } catch (ex) {
      const error = ex as Error;
      return rejectWithValue(error.message);
    }
  },
);

export const fetchAllRules = createAsyncThunk(
  'customerService/fetchAllRules',
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await getAllRules(token);

      if (!response) return [] as Rule[];

      const rules = response.resource.items.map(rule => {
        return {
          id: rule.id,
          team: rule.team,
          title: rule.title,
          values: rule.values,
          queueId: rule.queueId,
          relation: rule.relation,
          isActive: rule.isActive,
          operator: rule.operator,
          conditions: rule.conditions,
          ownerIdentity: rule.ownerIdentity,
        } as Rule;
      });

      return rules;
    } catch (ex) {
      const error = ex as Error;
      return rejectWithValue(error.message);
    }
  },
);
