import { BlipGoId, PACK_MANAGER_URL } from 'configurations/Environment';
import {
  ACTIVATE_INSTAGRAM,
  ACTIVATE_NUMBER_WHATSAPP,
  ADD_USERS_TENTANT,
  BLIP_CHAT_CONFIGURATION_ROUTE,
  GET_FACEBOOK_APP_INFORMATION,
  GET_INSTALLATIONS_BY_MEMBER_ROUTE,
  GET_INSTALLATION_METRICS,
  HEALTH_CHECK,
  INSTALLATION_ROUTE,
  INSTALLATION_SHORTNAME_ROUTE,
  LAST_INSTALLATION_ROUTE,
  MEMBER_ASSOCIATE_ROUTE,
  MEMBER_DISASSOCIATE_ROUTE,
  MEMBER_ROUTE,
  RESOURCES_ROUTE,
  UPDATE_CONTACT_STATUS_HUBSPOT,
} from 'constants/PackApiRoutes';
import { PackApi } from 'infra/adapters';
import { BlipChatConfiguration } from 'objects/types/BlipChatConfiguration';
import { BotChannels } from 'objects/types/BotChannels';
import ContactPropertyHubspot from 'objects/types/ContactPropertyHubspot';
import {
  FacebookAppInformation,
  InstagramActivationErrors,
  InstagramBusinessAccountDetails,
} from 'objects/types/Facebook';
import { HealthCheckStatus } from 'objects/types/HealthCheckStatus';
import { Installation, InstallationMetrics, PackWithInstallation } from 'objects/types/Installation';
import { InstallationDetails } from 'objects/types/InstallationDetails';
import { MemberInfo, MemberInfosResponse } from 'objects/types/Profile';
import { UserTenant } from 'objects/types/UserTenant';
import { WppActivationResponse } from 'objects/types/WhatsappActivation';
import { Resource } from 'objects/types/commands/Resource';
import { DataLayer } from './GoogleTagManager/DataLayerEvents';
import { LocalStorageService } from './LocalStorageService';

export const getHealthCheck = async () => {
  try {
    const packApi = new PackApi('getHealthCheck');

    const response = await packApi.Get<HealthCheckStatus>(HEALTH_CHECK);

    return response.data;
  } catch (ex) {
    return Promise.reject(ex);
  }
};

export const getLastInstallationByPackId = async (packId: string, email: string, token: string) => {
  try {
    const packApi = new PackApi('getLastInstallationByPackId');

    const response = await packApi.Get<string>(
      `${INSTALLATION_SHORTNAME_ROUTE}?packId=${packId}&identity=${encodeURIComponent(email)}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const routerShortName = response.data;
    const res = await packApi.Get<Installation>(`${LAST_INSTALLATION_ROUTE}?shortName=${routerShortName}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (ex: any) {
    if (ex.status === 404) {
      return null;
    }
    return Promise.reject(ex);
  }
};

export const getInstallationId = async (token: string) => {
  try {
    const packApi = new PackApi('getInstallationId');

    const response = await packApi.Get<PackWithInstallation[]>(
      `${GET_INSTALLATIONS_BY_MEMBER_ROUTE}?includeInstallations=true`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (response?.data?.length === 0) {
      return null;
    } else {
      const blipGoPackInfo = response.data.find((pack: PackWithInstallation) => pack.id.toString() === BlipGoId);
      return blipGoPackInfo?.installations.pop()?.installationId ?? null;
    }
  } catch (ex: any) {
    if (ex.status === 404) {
      return null;
    }
    return Promise.reject(ex);
  }
};

export const createMember = async (email: string, installationId: number, profileId: string, token: string) => {
  try {
    const packApi = new PackApi('createMember');

    const response = await packApi.Post<MemberInfosResponse>(
      `${MEMBER_ROUTE}/${BlipGoId}`,
      {
        members: [
          {
            name: email,
            email: email,
            profileId,
          },
        ],
        installationIdsToAssociate: [Number(installationId)],
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const memberId = response.data.createdMembers[0]?.id;
    await associateMember(memberId, installationId, token);
    return response.data.createdMembers;
  } catch (ex: any) {
    if (ex.status === 404) {
      return null;
    }
    return Promise.reject(ex);
  }
};

export const associateMember = async (memberId: number, installationId: number, token: string) => {
  try {
    const packApi = new PackApi('associateMember');

    const response = await packApi.Post<null>(
      `${MEMBER_ROUTE}/${BlipGoId}/${MEMBER_ASSOCIATE_ROUTE}`,
      {
        installationIds: [installationId],
        memberIds: [memberId],
        returnUrl: PACK_MANAGER_URL,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  } catch (ex: any) {
    if (ex.status === 404) {
      return null;
    }
    return Promise.reject(ex);
  }
};

export const disassociateMember = async (memberId: number, installationId: number, token: string) => {
  try {
    const packApi = new PackApi('associateMember');

    const response = await packApi.Post<null>(
      `${MEMBER_ROUTE}/${BlipGoId}/${MEMBER_DISASSOCIATE_ROUTE}`,
      {
        installationIds: [installationId],
        memberIds: [memberId],
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  } catch (ex: any) {
    if (ex.status === 404) {
      return null;
    }
    return Promise.reject(ex);
  }
};

export const getProfilesByInstallation = async (installationId: string, token: string) => {
  try {
    const packApi = new PackApi('getProfilesByInstallation');

    const response = await packApi.Get<MemberInfo[]>(
      `${MEMBER_ROUTE}/${BlipGoId}/installations?installationIds=${installationId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  } catch (ex: any) {
    if (ex.status === 404) {
      return null;
    }
    return Promise.reject(ex);
  }
};

export const getInstallationByInstallationId = async (packId: string, installationId: string, token: string) => {
  try {
    const packApi = new PackApi('getInstallationByInstallationId');
    const response = await packApi.Get<Installation>(`${INSTALLATION_ROUTE}/${installationId}?packId=${packId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (ex) {
    return Promise.reject(ex);
  }
};

export const getInstallationDetailsById = async (id: string, token: string) => {
  try {
    const packApi = new PackApi('getInstallationDetailsById');

    const response = await packApi.Get<InstallationDetails>(`${INSTALLATION_ROUTE}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (ex) {
    return Promise.reject(ex);
  }
};

export const getBlipChatConfigKey = async (botShortName: string, token: string) => {
  try {
    const packApi = new PackApi('getBlipChatConfigKey');

    const response = await packApi.Get<BlipChatConfiguration>(`${BLIP_CHAT_CONFIGURATION_ROUTE}/${botShortName}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (ex) {
    return Promise.reject(ex);
  }
};

export const updateContactStatus = async (properties: ContactPropertyHubspot[], token: string) => {
  try {
    const packApi = new PackApi('updateContactStatus');

    await packApi.Post(
      UPDATE_CONTACT_STATUS_HUBSPOT,
      {
        properties: properties,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  } catch (ex) {
    return Promise.reject(ex);
  }
};

export const activateNumberWhatsapp = async (
  shortName: string,
  phoneNumberId: string,
  wabaId: string,
  pin: string,
  token: string,
) => {
  try {
    const packApi = new PackApi('activateNumberWhatsapp');

    const res = await packApi.Post<WppActivationResponse>(
      ACTIVATE_NUMBER_WHATSAPP,
      {
        shortName: shortName,
        phoneNumberId: phoneNumberId,
        wabaId: wabaId,
        pin: pin,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    DataLayer().TriggerWhatsAppConversion();
    return res.data;
  } catch (ex) {
    return Promise.reject(ex);
  }
};

export const getInstallationResources = async (shortName: string, token: string) => {
  try {
    const packApi = new PackApi('getInstallationResources');

    const res = await packApi.Get<Resource[]>(`${RESOURCES_ROUTE}/${shortName}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (ex) {
    return Promise.reject('Não foi possível encontrar os recursos da instalação');
  }
};

export const setInstallationResources = async (shortName: string, resources: Resource[], token: string) => {
  try {
    const packApi = new PackApi('setInstallationResources');

    await packApi.Post<Resource[]>(`${RESOURCES_ROUTE}/${shortName}`, resources, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (ex) {
    return Promise.reject(new Error('Não foi possível configurar os recursos da instalação'));
  }
};

export const AddUsersTenant = async (usersTenant: UserTenant, token: string) => {
  try {
    const packApi = new PackApi('AddUsersTenant');

    const res = await packApi.Post(`${ADD_USERS_TENTANT}`, usersTenant, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.succeeded) {
      throw new Error(res.errors);
    }
  } catch (ex) {
    return Promise.reject(ex);
  }
};

export const GetChannelsActivate = async (shortName: string, token: string) => {
  try {
    const packApi = new PackApi('GetChannelsActivate');

    const res = await packApi.Get<BotChannels>(`${INSTALLATION_ROUTE}/${shortName}/channels`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (ex) {
    return Promise.reject(ex);
  }
};

export const getFacebookAppInfo = async (botId: string, token: string) => {
  try {
    const tenant = LocalStorageService.Get('userTraits')?.tenant?.id;
    const api = new PackApi('getFacebookAppInfo');
    const res = await api.Get<FacebookAppInformation>(
      `${GET_FACEBOOK_APP_INFORMATION}?shortName=${botId}&tenantId=${tenant}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res.data;
  } catch (error) {
    const defaultAppId = process.env.REACT_APP_FACEBOOK_APP_ID;
    if (!defaultAppId) {
      return Promise.reject('Default App Id not found!');
    }
    const defaultAppInfo: FacebookAppInformation = {
      appId: defaultAppId,
      graphApiVersion: 'v16.0',
      authApiVersion: 'v5.0',
    };
    return Promise.resolve(defaultAppInfo);
  }
};

export const ActivateInstagram = async (
  botId: string,
  userId: string,
  instagramBusinessAccountDetails: InstagramBusinessAccountDetails,
  token: string,
) => {
  try {
    const body = {
      shortName: botId,
      isChannelActive: 'true',
      instagramBusinessAccountId: instagramBusinessAccountDetails.instagramBusinessAccountId,
      pageId: instagramBusinessAccountDetails.pageId,
      pageAccessToken: instagramBusinessAccountDetails.pageAccessToken,
      userId: userId,
    };

    const api = new PackApi('ActivateInstagram');
    await api.Post(ACTIVATE_INSTAGRAM, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    DataLayer().TriggerInstagramConversion();
  } catch (error: any) {
    const innerMessage = error.data.Error.InnerMessage;
    if (innerMessage.includes('DisabledAccessInstagramMessages'))
      return Promise.reject(InstagramActivationErrors.NoMessagesAccess);

    if (innerMessage.includes('vincul') || innerMessage.includes('#100'))
      return Promise.reject(InstagramActivationErrors.NoBindedPagesFound);

    if (innerMessage.includes('ConnectedBot:')) return Promise.reject(InstagramActivationErrors.BotConnected);

    return Promise.reject(InstagramActivationErrors.Unknown);
  }
};

export const getInstallationMetrics = async (installationId: number, token: string) => {
  try {
    const api = new PackApi('getInstallationMetrics');
    const res = await api.Get<InstallationMetrics>(`${GET_INSTALLATION_METRICS}/${installationId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
