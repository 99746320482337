import { HealthCheckStatus } from 'objects/types/HealthCheckStatus';
import { FC, useEffect, useState } from 'react';
import { getHealthCheck } from 'services/PackService';

export const HealthChecker: FC = () => {
  const [status, setStatus] = useState<string>('');

  useEffect( () => {
    collectHeathyStatus();
  },[]);

  const collectHeathyStatus = async () => {

    let packApiStatus: HealthCheckStatus;

    try {
      packApiStatus = await getHealthCheck();
    } catch (error) {
      packApiStatus = {
        status: 'err',
        totalDuration:'',
        data: error,
        entries:{}
      }
    }
    
    const appsDependency = [
      {name:'pack-api', data: packApiStatus}
    ];
    const okStatus =  ['healthy', 'ok', 'ready'];
    const unHealthy = appsDependency.filter( ({data}) => !okStatus.includes(data.status.toLowerCase()));
    const AppStatus: HealthCheckStatus = {
      /* 
      Calculate unHealthy percent:  (nonOk/total)*100 :
       ex.: 3 non Ok apps from 100 total: (3/100)*100 = 3% ... => 100-3 = 97%
      */
      status: (100-((unHealthy.length/appsDependency.length)*100)).toString().concat('%'),
      totalDuration: '0',
      entries: {}
    }

    appsDependency.forEach( item => {
      AppStatus.entries[item.name] = {
        duration: item.data.totalDuration,
        status: item.data.status,
        data: item.data.data
      }
    })
  
    setStatus(JSON.stringify(AppStatus))
  }
 
  return(<div data-testid="health-id">{status}</div>);
};

export default HealthChecker