export type CommandRequest = {
  id: string;
  uri: string;
  method: string;
};

export type CommandErrorReason = {
  code: number;
  description: string;
};

export enum CommandErrorCodes {
  NotFound = 67,
}
