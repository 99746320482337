import styled from 'styled-components';

export const Container = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: row;
`;

export const ContainerCardChannel = styled.div`
  width: 217.5px;
  height: 292px;
  border-radius: 7px;
  padding: 24px;
  background: #ffffff;
`;

export const ContainerChannelNotActivate = styled.div`
  width: 169.5px;
  height: 116px;
  gap: 2px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerChannelActivate = styled.div`
  height: 88px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-top: 20px;
`;

export const Connected = styled.div`
  width: 101px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: #84ebbc;
  padding: 0px 0.5px 0px 0.5px;
  gap: 5px;
`;

export const MessageConnected = styled.div`
  width: 169.5px;
  height: 44px;
  text-align: center;
  margin-top: 15px;
`;

export const TopCards = styled.div`
  width: 169.5px;
  gap: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleChannel = styled.div`
  width: 169.5px;
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
export const TitlePlan = styled.div`
  width: 84px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border-color: #a5a5a5;
  background: #f6f6f6;
  border: 0.1px solid;
  padding: 0px 0.5px 0px 0.5px;
`;
