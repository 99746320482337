import { BdsButton } from 'blip-ds/dist/blip-ds-react';
import { ButtonVariant } from 'blip-ds/dist/types/components/button/button';

type ButtonProps = {
  onClick?: () => void;
  text: string;
  variant: ButtonVariant;
  disabled?: boolean;
  icon?: string;
  dataTestId?: string;
};

export const Button = ({ text, variant, onClick, disabled, icon = '', dataTestId }: ButtonProps): JSX.Element => {
  return (
    <BdsButton
      variant={variant}
      icon={icon}
      onClick={() => !disabled && onClick?.()}
      disabled={disabled}
      data-testid={dataTestId}
    >
      {text}
    </BdsButton>
  );
};
