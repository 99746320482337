export const HasAnySpecialCharacters = (field: string) => {
  const STRING_WITHOUT_SPECIAL_CHARACTER_REGEX = /[\*\(\)\+\[\]\;\:\?\\\|\<\>]/;
  return STRING_WITHOUT_SPECIAL_CHARACTER_REGEX.test(field);
};

export const IsEmptyString = (input: string) => {
  if (!input) {
    return true;
  }
  const inputWithTrimMethod = input.trim();
  return inputWithTrimMethod.length === 0;
};

export const HasAnyIdenticalStrings = (strings: string[]) => {
  const uniqueStrings = new Set(strings);
  return strings.length !== uniqueStrings.size;
};
