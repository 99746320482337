import { BdsButton, BdsGrid, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { PaywallContainer, PaywallTableDivider, PlanCancelInfo, ScrollableTableContainer } from './styles';
import { PLAN_UPGRADE_INFO } from 'constants/WppModalInfo';
import { PaywallTableLine } from './Components/PaywallTableLine/PaywallTableLine';
import { useNavigate } from 'react-router-dom';
import { SIGNATURE_ROUTE } from 'constants/Routes';
import { Analytics, Logger } from 'infra/adapters';
import ExternalUrls from 'constants/ExternalUrls';

const className = 'PaywallV2';

export const PaywallV2 = () => {
  const navigate = useNavigate();

  const openContractInfo = () => {
    const methodName = 'openContractInfo';
    navigate(SIGNATURE_ROUTE);
    Analytics.Track(Analytics.events.PAYWALL_UPGRADE_PLAN);
    Logger.Info('redirecting to contract page...', { methodName, className });
  };

  const openLearnMore = () => {
    const methodName = 'OpenLearmore';
    window.open(
      `${ExternalUrls.whatsAppExperienceCluster}Tenho dúvidas sobre o plano PRO Blip Go e gostaria de falar com um especialista para saber mais.`,
      '_blank',
    );
    Analytics.Track(Analytics.events.PAYWALL_LEARN_MORE);
    Logger.Info('redirecting to experience cluster chat...', { methodName, className });
  };

  const TableHeader = (
    <BdsGrid justifyContent="space-between">
      <BdsTypo variant="fs-32" bold="bold" class="paywall-title">
        Assine o plano PRO e atenda seus clientes no WhatsApp Blip Go!
      </BdsTypo>
      <BdsGrid class="paywall-header-container">
        <BdsGrid direction="column" justifyContent="center" alignItems="center" class="paywall-free-header">
          <BdsTypo variant="fs-40" bold="bold" margin={false} class="paywall-plan-name">
            Free
          </BdsTypo>
          <BdsTypo>Seu plano atual</BdsTypo>
        </BdsGrid>
        <BdsGrid direction="column" justifyContent="center" alignItems="center" class="paywall-pro-header">
          <BdsTypo variant="fs-40" bold="bold" class="white-color paywall-plan-name" margin={false}>
            Pro
          </BdsTypo>
          <BdsTypo class="white-color">R$ 399,00 /mês</BdsTypo>
        </BdsGrid>
      </BdsGrid>
    </BdsGrid>
  );

  return (
    <PaywallContainer>
      <BdsGrid direction="column" gap="2" class="paywall-main-grid">
        <BdsGrid gap="2" justifyContent="flex-end">
          <BdsButton variant="tertiary" onClick={openLearnMore} data-testid="paywall-learn-more-btn">
            Saiba mais
          </BdsButton>
          <BdsButton arrow={true} onClick={openContractInfo} data-testid="paywall-contract-plan-btn">
            Assinar o Plano PRO
          </BdsButton>
        </BdsGrid>
        <ScrollableTableContainer>
          <BdsGrid direction="column">
            {TableHeader}
            {PLAN_UPGRADE_INFO.map((feature, index) => {
              return (
                <div key={feature?.label}>
                  <PaywallTableLine
                    proIncluded={feature?.proIncluded}
                    freeIncluded={feature?.freeIncluded}
                    featureLabel={feature?.label}
                    additionalInfoFree={feature?.additionalInfoFree}
                    additionalInfoPro={feature?.additionalInfoPro}
                    showWhatsappLogo={feature?.showWhatsappLogo}
                    tooltipText={feature?.tooltipText}
                  />
                  {index !== PLAN_UPGRADE_INFO.length - 1 && <PaywallTableDivider />}
                </div>
              );
            })}
            <PlanCancelInfo>
              <BdsTypo variant="fs-10" bold="bold">
                Cancele a qualquer momento, sem multas ou tempo minímo de contrato.
              </BdsTypo>
              <BdsTypo variant="fs-10">Use o Blip Go! porque gostou. 🤟</BdsTypo>
            </PlanCancelInfo>
          </BdsGrid>
        </ScrollableTableContainer>
      </BdsGrid>
    </PaywallContainer>
  );
};
