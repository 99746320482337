import { FloatingButtonContainer, FloatingButtonContent, FloatingButtonImage } from './styles';
import { BdsTooltip } from 'blip-ds/dist/blip-ds-react';

type FloatingButtonProps = {
  onClick?: () => void;
  fBtnImage?: string;
  dataTestId?: string;
  tooltip?: string;
};

export const FloatingButton = ({ onClick, fBtnImage = '', dataTestId, tooltip }: FloatingButtonProps): JSX.Element => {
  return (
    <FloatingButtonContainer onClick={onClick} data-testid={dataTestId}>
      <BdsTooltip tooltipText={tooltip} position="left-center">
        <FloatingButtonContent>
          <FloatingButtonImage src={fBtnImage} />
        </FloatingButtonContent>
      </BdsTooltip>
    </FloatingButtonContainer>
  );
};
