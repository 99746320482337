import ExternalUrls from 'constants/ExternalUrls';
import useGraphApi from 'hooks/useGraphApi';
import { Analytics, Logger } from 'infra/adapters';
import { useState } from 'react';

export const useMetaConnection = () => {
  const className = 'useMetaConnection';

  const [metaUserToken, setMetaUserToken] = useState({});
  const [showRetryPage, setShowRetryPage] = useState(false);
  const [readyToConnectToWaba, setReadyToConnectToWaba] = useState(false);

  const { launchWhatsAppSignup, initFacebookSdk } = useGraphApi();

  const initHook = () => {
    Analytics.Track(Analytics.events.META_OPEN);
    initFacebookSdk();
  };

  const handleRegisterBtn = () => {
    const methodName = 'handleRegisterBtn';
    Analytics.Track(Analytics.events.META_REGISTER_NOW);
    setShowRetryPage(true);
    Analytics.Track(Analytics.events.META_EMBEDDED_REDIRECT);
    openEmbeddedSignUp();
    Logger.Info('user successfully fullfiled open embedded signup', { methodName, className });
  };

  const handleReportProblemBtn = () => {
    const methodName = 'handleReportProblemBtn';
    Analytics.Track(Analytics.events.META_REPORT_PROBLEM, { screenName: 'Como conectar?' });
    window.open(
      `${ExternalUrls.whatsappActivationChat}Gostaria de reportar um *problema* no *cadastramento na Meta*`,
      '_blank',
    );
    Logger.Info('open report problem', { methodName, className });
  };

  const handleLeanMoreLink = () => {
    const methodName = 'handleLeanMoreLink';
    Analytics.Track(Analytics.events.META_LEARN_MORE);
    window.open(ExternalUrls.learnMoreYouTubeVideo, '_blank');
    Logger.Info('open learn more You Tube video', { methodName, className });
  };

  const retryOpenEmbedded = () => {
    const methodName = 'retryOpenEmbedded';
    Analytics.Track(Analytics.events.META_RETRY_OPEN_EMBEDDED);
    openEmbeddedSignUp();
    Logger.Info('redirecting to retry open meta embedded sign up', { methodName, className });
  };

  const openEmbeddedSignUp = () => {
    const methodName = 'openEmbeddedSignUp';
    launchWhatsAppSignup(userToken => {
      setMetaUserToken(userToken);
      setReadyToConnectToWaba(true);
      Logger.Info(`get  meta userToken  embedded sign up: ${userToken}`, { methodName, className });
    });
    Logger.Info('redirecting to  open meta embedded sign up', { methodName, className });
  };

  return {
    openEmbeddedSignUp,
    handleLeanMoreLink,
    handleRegisterBtn,
    retryOpenEmbedded,
    handleReportProblemBtn,
    metaUserToken,
    readyToConnectToWaba,
    showRetryPage,
    initHook,
  };
};
