import {
  BdsAlert,
  BdsAlertActions,
  BdsAlertBody,
  BdsAlertHeader,
  BdsButton,
  BdsCheckbox,
  BdsGrid,
  BdsIcon,
  BdsTooltip,
  BdsTypo,
} from 'blip-ds/dist/blip-ds-react';
import InputText from 'components/InputText';
import { OfferBanner } from 'components/OfferBanner/OfferBanner';
import SubPageHeader from 'components/SubPageHeader/SubPageHeader';
import { SIGNATURE_ROUTE } from 'constants/Routes';
import { Analytics } from 'infra/adapters';
import { PlanTypeEnum } from 'objects/types/BlipGoApi';
import { error } from 'pages/Configurations/constants';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ConfigurationsContainer from '../components/ConfigurationsContainer/ConfigurationsContainer';
import useAttendanceQueue from './hooks/useAttendanceQueue';

const AttendanceQueue: React.FC = () => {
  const {
    attendantsQueue,
    serviceQuestion,
    attendanceQueues,
    formatQueues,
    isMaxNumber,
    handleNewQueue,
    isAttendantTeam,
    handleDeleteQueue,
    handleSaveChanges,
    handleCheckboxIndex,
    handleChangeQueueName,
    handleQueueMessageError,
    handleChangeAttendantTeam,
    handleChangeQueueQuestion,
    handleChangePreQueueMessage,
    isDeletionAlertOpen,
    setIsDeletionAlertOpen,
    showOfferBanner,
    planType,
  } = useAttendanceQueue();

  const navigate = useNavigate();

  const [isContentModified, setIsContentModified] = useState(false);
  const effectAlreadyRan = useRef(false);

  const handleClickSaveButton = async () => {
    await handleSaveChanges();
    setIsContentModified(false);
  };

  useEffect(() => {
    if (effectAlreadyRan.current || process.env.NODE_ENV !== 'development') {
      formatQueues();
    }

    return () => {
      effectAlreadyRan.current = true;
    };
  }, []);

  const redirectToSignaturePlan = () => {
    Analytics.Track(Analytics.events.OFFER_BANNER_TEAM_ATTENDANTS);
    navigate(SIGNATURE_ROUTE);
  };

  return (
    <>
      <SubPageHeader showTestButton onSave={handleClickSaveButton} buttonSaveDisabled={!isContentModified} />
      <ConfigurationsContainer>
        <BdsGrid direction="column" justifyContent="center" padding="y-1" lg="5" md="7" xg="5">
          <BdsGrid direction="column" gap="3">
            <BdsGrid direction="column" gap="2">
              <BdsGrid direction="column" gap="1">
                <BdsTypo data-testid="preAttendanceMessageExplanatoryText">
                  Crie equipes e personalize os assuntos, tópicos ou setores da sua empresa que seu cliente poderá
                  escolher ao solicitar atendimento humano.
                </BdsTypo>
              </BdsGrid>
              <InputText
                rows={4}
                isTextArea={true}
                value={serviceQuestion.description}
                label="Mensagem antes do menu de atendimento"
                dataTestId="preAttendanceMessageInput"
                required
                requiredErrorMessage={error.requiredField}
                onInputChange={ev => {
                  handleChangePreQueueMessage(ev);
                  setIsContentModified(true);
                }}
              />
            </BdsGrid>

            {attendanceQueues.map((queue, index) => (
              <BdsGrid direction="column" gap="1" key={queue.name}>
                <BdsGrid gap="1" justifyContent="space-between">
                  <BdsTypo bold="bold">Equipe {index + 1}</BdsTypo>
                  <BdsIcon
                    style={{ cursor: 'pointer' }}
                    name="trash"
                    data-testid={`removeQueue-${queue.name}`}
                    onClick={() => {
                      handleDeleteQueue(index);
                      setIsContentModified(true);
                    }}
                  />
                </BdsGrid>
                <InputText
                  required
                  value={queue.name}
                  label="Nome da equipe"
                  dataTestId={`queueName-${queue.name}`}
                  requiredErrorMessage={error.requiredField}
                  errorMessage={handleQueueMessageError(queue.name)}
                  danger={!queue.isQueueValid}
                  onFocus={() => setIsContentModified(true)}
                  onBlur={event => handleChangeQueueName(event, index)}
                />
                <InputText
                  required
                  rows={4}
                  isTextArea={true}
                  value={queue.question}
                  label="Mensagem de pré atendimento"
                  dataTestId={`queueQuestion-${queue.name}`}
                  requiredErrorMessage={error.requiredField}
                  errorMessage={!queue.isQuestionValid ? error.requiredField : ''}
                  danger={!queue.isQuestionValid}
                  onFocus={() => setIsContentModified(true)}
                  onInputChange={(event: Event) => {
                    handleChangeQueueQuestion(event, index);
                  }}
                />
                <BdsGrid direction="column">
                  <BdsTypo variant="fs-14" bold="regular">
                    Selecione os atendentes desta equipe:
                  </BdsTypo>
                  <BdsGrid
                    style={{ borderRadius: '0.5rem', border: '1px solid #a5a5a5' }}
                    direction="column"
                    padding="1"
                    alignItems="flex-start"
                    gap="1"
                  >
                    <BdsGrid direction="column" alignItems="flex-start" gap="1">
                      {attendantsQueue.map((attendant, attendantIndex) => (
                        <BdsCheckbox
                          key={handleCheckboxIndex(attendant.email, attendantIndex)}
                          name={attendant.email}
                          label={attendant.email}
                          data-testid={`checkbox-queue${index}-${attendant.email}`}
                          refer={handleCheckboxIndex(attendant.email, attendantIndex)}
                          checked={isAttendantTeam(queue.name, attendant)}
                          onClick={e => {
                            handleChangeAttendantTeam(e, queue.name, attendant);
                            setIsContentModified(true);
                          }}
                        />
                      ))}
                    </BdsGrid>
                  </BdsGrid>
                </BdsGrid>
              </BdsGrid>
            ))}
            <BdsGrid justifyContent="flex-end">
              <BdsTooltip
                position="top-center"
                tooltip-text="Limite de equipes atingido para o plano atual."
                disabled={!showOfferBanner}
              >
                <BdsButton
                  icon="add"
                  variant="ghost"
                  data-testid="addQueue"
                  disabled={isMaxNumber()}
                  onBdsClick={handleNewQueue}
                >
                  Adicionar equipe
                </BdsButton>
              </BdsTooltip>
            </BdsGrid>
          </BdsGrid>

          {showOfferBanner && planType !== PlanTypeEnum.Pro && (
            <BdsGrid margin="t-3">
              <OfferBanner
                title="Tenha mais equipes com o plano Pro!"
                textContent="Desbloqueie recursos exclusivos, aumente o limite de atendentes, equipes e conversas mensais."
                textButton="Quero assinar o plano Pro"
                actionButton={redirectToSignaturePlan}
              />
            </BdsGrid>
          )}
        </BdsGrid>

        <BdsAlert data-testid="deletion-alert" open={isDeletionAlertOpen}>
          <BdsAlertHeader variant="warning" icon="warning">
            Remova os atendentes para deletar a fila
          </BdsAlertHeader>
          <BdsAlertBody>Não é possível deletar uma fila de atendimento com atendentes vinculados a ela.</BdsAlertBody>
          <BdsAlertActions>
            <BdsButton variant="secondary" onClick={() => setIsDeletionAlertOpen(false)}>
              Entendi
            </BdsButton>
          </BdsAlertActions>
        </BdsAlert>
      </ConfigurationsContainer>
    </>
  );
};

export default AttendanceQueue;
