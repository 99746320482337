import { BdsChipTag, BdsGrid, BdsIcon, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { Paper } from 'globalStyles';
import { ComponentType } from 'react';

type CardProps = {
  Logo: ComponentType;
  title: string;
  author: string;
  description: string;
  category: string;
  pricing: string;
  onClick: () => any;
};

function Card({ Logo, title, author, description, category, pricing, onClick }: CardProps) {
  return (
    <Paper onClick={onClick} style={{ cursor: 'pointer', width: '25rem' }}>
      <BdsGrid direction="column" padding="3" gap="2">
        <BdsGrid gap="1" alignItems="center">
          <Logo />
          <BdsGrid direction="column">
            <BdsTypo bold="bold">{title}</BdsTypo>
            <BdsTypo variant="fs-12">{`Por ${author}`}</BdsTypo>
          </BdsGrid>
        </BdsGrid>
        <BdsTypo variant="fs-14">{description}</BdsTypo>
        <BdsChipTag color="outline">{category}</BdsChipTag>
        <BdsGrid alignItems="center" gap="1">
          <BdsIcon color="#0096FA" name="checkball" size="x-small" />
          <BdsTypo variant="fs-14">{pricing}</BdsTypo>
        </BdsGrid>
      </BdsGrid>
    </Paper>
  );
}

export default Card;
