import { BdsButton, BdsGrid, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import { Container } from './style';
import { ANALYTICS } from 'constants/Analytics';
import ChannelsName from 'constants/ChannelsName';
import { Analytics } from 'infra/adapters';
import { useSelector } from 'react-redux';
import { useChannelsModule } from 'redux/slices/ChannelsModuleSlice/channelsModuleSlice';
import { useProject } from 'redux/slices/ProjectSlice/projectSlice';
import { SubPageHeaderTexts } from 'constants/SubPageHeaderTexts';
import { TrackingProperties } from 'objects/types/TrackingProperties';

interface ISubPageHeader {
  showTestButton?: boolean;
  onSave?: () => void;
  buttonSaveDisabled?: boolean;
}

const SubPageHeader: React.FC<ISubPageHeader> = ({
  showTestButton = false,
  onSave,
  buttonSaveDisabled = false,
}: ISubPageHeader) => {
  const { activeSubPage } = useSelector(useProject);
  const { channels } = useSelector(useChannelsModule);

  const handleButtonTest = () => {
    Analytics.Track(ANALYTICS.CLICK_CHATBOT_TEST_BUTTON, {
      screenName: activeSubPage.title,
    } as TrackingProperties);

    const blipChatChannel = channels.find(channel => channel.title === ChannelsName.Blipchat);

    window.open(blipChatChannel?.linkToTest, '_blank');
  };

  return (
    <Container>
      <BdsGrid justifyContent="space-around" alignItems="center">
        <div style={{ width: '100%' }}>
          <BdsGrid justifyContent="space-between" alignItems="center">
            <BdsTypo color="#454545" variant="fs-24" bold="bold" margin={false}>
              {activeSubPage.title}
            </BdsTypo>
            <BdsGrid justifyContent="flex-end" alignItems="center" gap="2">
              {showTestButton && (
                <BdsButton
                  variant="ghost"
                  type="button"
                  icon="builder-test-bot"
                  onBdsClick={handleButtonTest}
                  data-testid="testButtonTest"
                >
                  {SubPageHeaderTexts.Test}
                </BdsButton>
              )}
              {onSave && (
                <BdsButton
                  variant="primary"
                  type="button"
                  icon="checkball"
                  onBdsClick={onSave}
                  data-testid="testButtonSave"
                  disabled={buttonSaveDisabled}
                >
                  {SubPageHeaderTexts.Save}
                </BdsButton>
              )}
            </BdsGrid>
          </BdsGrid>
        </div>
      </BdsGrid>
    </Container>
  );
};

export default SubPageHeader;
