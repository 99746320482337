import moment from 'moment';
import { Shift } from 'objects/types/humamService/AttendanceHour';

export const ValidateHours = (shifts: Shift[]): boolean => {
  let isValid = true;

  shifts.forEach((shift, index) => {
    const startTime = moment(shift.startTime, 'HH:mm');
    const endTime = moment(shift.endTime, 'HH:mm');

    if (
      !ValidateHour(shift.startTime) ||
      !ValidateHour(shift.endTime) ||
      !startTime.isValid() ||
      !endTime.isValid() ||
      startTime.isSameOrAfter(endTime)
    ) {
      isValid = false;
    }

    if (index > 0) {
      const prevShiftEndTime = moment(shifts[index - 1].endTime, 'HH:mm');
      if (prevShiftEndTime.isAfter(startTime)) {
        isValid = false;
      }
    }

    if (index < shifts.length - 1) {
      const nextShiftStartTime = moment(shifts[index + 1].startTime, 'HH:mm');
      if (endTime.isAfter(nextShiftStartTime)) {
        isValid = false;
      }
    }
  });

  return isValid;
};

export const ValidateHour = (hour: string): boolean => {
  const hoursRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
  return hoursRegex.test(hour);
};
