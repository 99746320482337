import React from 'react';
import { Helmet } from 'react-helmet';

const GoogleTagManager: React.FC = () => {
  if (process.env.REACT_APP_ENV !== 'production') {
    return <></>;
  }

  const script = (
    <script>
      {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-NQGWZ373');
      `}
    </script>
  );

  const iframe = (
    <noscript>
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-NQGWZ373"
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      ></iframe>
    </noscript>
  );

  return (
    <>
      <Helmet>{script}</Helmet>
      {iframe}
    </>
  );
};

export default GoogleTagManager;
