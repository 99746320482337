import { TimeOutRequest, mailApiUrl } from 'configurations/Environment';
import { BaseRestService } from './BaseRestAdapter';

export class MailApiAdapter<T> extends BaseRestService<T> {
  constructor(methodOrigin: string) {
    super(mailApiUrl, 'MailService', methodOrigin);
  }

  protected setInstanceDefaultHeaders(): void {
    this.getDefaults().headers.common['Content-Type'] = 'application/json';
  }

  protected setInstanceDefaultTimeOut(): void {
    this.getDefaults().timeout = TimeOutRequest;
  }

  protected setInstanceInterceptors(): void {
    const token = localStorage.getItem('jwtToken');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    this.getInterceptors().response.use(this.handleResponse, this.handleError);
    this.getInterceptors().request.use((config: any) => {
      config.headers = headers;
      return config;
    });
  }
}

export default MailApiAdapter;
