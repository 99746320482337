import { BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { ReviewContractInfoObservations, ReviewContractInfoSubitems, ReviewInfoCardContainer } from './styles';
import { Bold } from 'blip-ds/dist/types/components/typo/typo';

interface IInfoCard {
  subitems: string[];
  title: string;
  subitemFontWeigth: Bold;
  observations?: string[];
  cardColor: string;
}

export const InfoCard = ({ subitems, title, subitemFontWeigth, observations, cardColor }: IInfoCard) => {
  return (
    <BdsPaper elevation="static" style={{ backgroundColor: cardColor }} key={title}>
      <ReviewInfoCardContainer>
        <BdsTypo variant="fs-20" bold="bold">
          {title}
        </BdsTypo>
        <ReviewContractInfoSubitems>
          {subitems.map(subitem => {
            return (
              <BdsTypo variant="fs-14" bold={subitemFontWeigth} key={subitem} data-testid="content-subitem">
                {subitem}
              </BdsTypo>
            );
          })}
        </ReviewContractInfoSubitems>
        {observations ? (
          <ReviewContractInfoObservations>
            {observations?.map(observation => {
              return (
                <BdsTypo variant="fs-12" italic key={observation} data-testid="observation-item">
                  {observation}
                </BdsTypo>
              );
            })}
          </ReviewContractInfoObservations>
        ) : null}
      </ReviewInfoCardContainer>
    </BdsPaper>
  );
};
