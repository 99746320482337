import { LARGE_MOBILE_SCREEN } from 'constants/MediaQueryResolutions';
import styled from 'styled-components';

export const CompanyInfoContainer = styled.form`
  display: grid;
  gap: 16px;
  width:400px;

  @media screen and (max-width: ${LARGE_MOBILE_SCREEN}) {
    width: 100%;
  }
`;

export const PolicyTermsContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
`;

export const PolicyCheckboxWrapper = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  cursor: pointer;
`;

export const PolicyTermsHyperlink = styled.span`
  font-size: 14px;
  color: var(--color-primary);
  text-decoration: underline;
  cursor: pointer;
`;

export const NavigationBtnContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
`;
