import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DEFAULT_INSTALLATION_ID } from 'constants/Installation';
import { Desk } from 'objects/types/Desk';
import { Installation } from 'objects/types/Installation';
import { InstallationDetails } from 'objects/types/InstallationDetails';
import { Router } from 'objects/types/Router';
import { Tenant } from 'objects/types/Tenant';
import { TenantPlan } from 'objects/types/TenantPlan';
import { IInstallationState } from './interface';
import { fetchInstallationDetailsById } from './thunkMiddleware';

const InitialState: IInstallationState = {
  applicationId: 0,
  installationId: DEFAULT_INSTALLATION_ID,
  companyName: '',
  router: {
    accessKey: '',
    shortName: '',
  } as Router,
  desk: {
    accessKey: '',
    shortName: '',
  } as Desk,
  isChannelActived: false,
  tenant: {
    id: '',
    name: '',
    plan: {
      id: '',
      name: '',
      cluster: '',
    } as TenantPlan,
  } as Tenant,
  ownerIdentity: '',
};

const installationSlice = createSlice({
  name: 'installation',
  initialState: InitialState,
  reducers: {
    updateInstallation(state, { payload }: PayloadAction<Installation>) {
      state.applicationId = payload.applicationId;
      state.installationId = payload.id;
      state.isChannelActived = payload.isChannelActived;
      state.router.shortName = payload.routerShortName;
      state.router.accessKey = payload.routerAccessKey;
      state.desk.shortName = payload.deskShortName;
      state.desk.accessKey = payload.deskAccessKey;
      state.tenant = payload.tenant;
      state.companyName = payload.clientIdentity;
      state.ownerIdentity = payload.ownerIdentity;
    },
  },
  extraReducers(builder) {
    builder.addCase(
      fetchInstallationDetailsById.fulfilled,
      (state, { payload }: PayloadAction<InstallationDetails>) => {
        state.tenant = payload.tenant;
        state.companyName = payload.clientIdentity;
        state.ownerIdentity = payload.ownerIdentity;
      },
    );
  },
});

export default installationSlice.reducer;

export const { updateInstallation } = installationSlice.actions;

export const useInstallation = (state: { installation: IInstallationState }) => state.installation;
