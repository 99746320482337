import styled from 'styled-components';

export const FloatingButtonContainer = styled.div`
  position: fixed;
  bottom: 32px;
  right: 32px;
`;

export const FloatingButtonImage = styled.img`
  padding: 9px;
`;

export const FloatingButtonContent = styled.div`
  background-color: #0096fa;
  border: none;
  border-radius: 100px;
  height: 58px;
  width: 58px;
  padding: 4px;
  cursor: pointer;
  box-shadow: 0px 0px 16px -4px rgba(0, 0, 0, 0.16);
`;
