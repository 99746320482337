import { BdsGrid, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { ContactItemDto } from '../../../../objects/types/ContactsResponse';
import React from 'react';
import './style.scss';

interface ContactDetailsProps {
  contact: ContactItemDto | null | undefined;
}

const ContactDetails: React.FC<ContactDetailsProps> = ({ contact }) => {
  if (!contact) {
    return <BdsPaper className="contact-details-empty" />;
  }

  return (
    <BdsPaper className="contact-details">
      <BdsGrid direction="column" gap="1">
        <BdsGrid padding="b-2">
          <BdsTypo variant="fs-16" bold="bold">
            Dados de contato
          </BdsTypo>
        </BdsGrid>
        <BdsGrid direction="column" gap="1">
          <BdsGrid direction="column">
            <p className="details-text">Nome:</p>
            <p className="details-text bold">{contact.name}</p>
          </BdsGrid>
          <BdsGrid direction="column">
            <p className="details-text">Telefone:</p>
            <p className="details-text bold">{contact.phoneNumber || 'Nenhum telefone cadastrado'}</p>
          </BdsGrid>
          <BdsGrid direction="column">
            <p className="details-text">E-mail:</p>
            <p className="details-text bold">{contact.email || 'Nenhum e-mail cadastrado'}</p>
          </BdsGrid>
          <BdsGrid direction="column">
            <p className="details-text">Canal:</p>
            <p className="details-text bold">{contact.source === '0mn.io' ? 'blipchat' : contact.source}</p>
          </BdsGrid>
        </BdsGrid>
      </BdsGrid>
    </BdsPaper>
  );
};

export default ContactDetails;
